const caIcon = (color = "#000000") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 433 433"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M216.491 0C276.273 0 330.416 24.2386 369.584 63.4067C408.752 102.575 433 156.709 433 216.491C433 276.273 408.752 330.416 369.584 369.584C330.416 408.752 276.273 433 216.491 433C156.782 433 102.639 408.734 63.4616 369.538C24.2478 330.416 0 276.273 0 216.491C0 156.709 24.2386 102.575 63.4067 63.4067C102.575 24.2386 156.709 0 216.491 0Z"
				fill="white"
			/>
			<path
				d="M80.34 384.52C31.37 344.85 0 284.17 0 216.26C0 148.31 31.34 87.67 80.34 48V384.52ZM352.52 48.03C401.48 87.72 432.82 148.36 432.82 216.26C432.82 284.2 401.51 344.84 352.52 384.51V48.03ZM216.4 110.1L198.59 143.32C196.56 146.93 192.95 146.6 189.32 144.59L176.42 137.91L186.03 188.95C188.06 198.27 181.56 198.27 178.37 194.25L155.86 169.04L152.2 181.84C151.78 183.51 149.92 185.29 147.15 184.86L118.68 178.87L126.15 206.07C127.75 212.11 129 214.62 124.54 216.22L114.39 220.98L163.4 260.8C165.33 262.3 166.31 265.01 165.62 267.46L161.34 281.53C178.21 279.59 193.33 276.66 210.21 274.86C211.7 274.7 214.2 277.15 214.19 278.88L211.95 330.44H220.16L218.86 278.99C218.86 277.26 221.12 274.7 222.61 274.86C239.49 276.66 254.6 279.58 271.48 281.53L267.19 267.45C266.49 265.01 267.48 262.3 269.42 260.8L318.42 220.98L308.29 216.22C303.82 214.62 305.07 212.11 306.67 206.07L314.15 178.87L285.67 184.86C282.9 185.29 281.03 183.51 280.62 181.84L276.96 169.04L254.44 194.25C251.24 198.27 244.77 198.27 246.78 188.95L256.39 137.91L243.5 144.59C239.87 146.6 236.25 146.93 234.24 143.32L216.4 110.1Z"
				fill="#FF0000"
			/>
		</svg>
	);
};
export default caIcon;
