const flag = (color = "#000000") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 200 200"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="mask0_1072_28"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="200"
				height="200"
			>
				<circle cx="100" cy="100" r="100" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_1072_28)">
				<path d="M251.515 0H-51.5151V200H251.515V0Z" fill="#ED2939" />
				<path d="M134 0H0V200H134V100V0Z" fill="white" />
				<path d="M67 0H0V200H67V0Z" fill="#002395" />
			</g>
		</svg>
	);
};

export default flag;
