import logger from "../../../../utils/logger";

export const isDataURI = (url: string) => {
	if (url.slice(0, 4) === "data") {
		return true;
	}
	return false;
};

export function cleanJSON(nbObj: any) {
	try {
		const recursiveWalk = (node: any) => {
			//AIM: Calculate global position for child and continue

			node.content?.forEach((childNode: any) => {
				// logger.debug("Processing", childNode.type)
				if (childNode.type === "image") {
					// Clipping type
					if (isDataURI(childNode.attrs.src))
						childNode.attrs.src = "";
				}

				recursiveWalk(childNode);
			});
		};

		recursiveWalk(nbObj);
		// logger.debug("SOURCE RENDER")
		return nbObj;
	} catch (e) {
		logger.error("SOURCE ERROR", e);

		return nbObj;
	}
}

export const refillImages = (oldDoc: any, newDoc: any) => {
	// Copy all image nodes from oldDoc into newDoc

	let imageNodes: any[] = [];
	try {
		// Process all image nodes
		const recursiveWalk = (node: any) => {
			node.content?.forEach((childNode: any) => {
				// logger.debug("Processing", childNode.type)
				if (childNode.type === "image") {
					// Clipping type
					imageNodes.push(refillImages);
				}

				recursiveWalk(childNode);
			});
		};

		recursiveWalk(oldDoc);
		let imgCount = 0;
		// Find and refill one by one
		const otherRecursiveWalk = (node: any) => {
			node.content?.forEach((childNode: any) => {
				// logger.debug("Processing", childNode.type)
				if (childNode.type === "image") {
					// Clipping type
					if (imgCount < imageNodes.length) {
						childNode = imageNodes[imgCount];
						imgCount += 1;
					}
				}

				recursiveWalk(childNode);
			});
		};
		otherRecursiveWalk(newDoc);
		return newDoc;
	} catch (e) {
		logger.error("SOURCE ERROR", e, oldDoc, newDoc);

		return oldDoc;
	}
};

export function insertImageMetadata(
	nbObj: any,
	images: any[],
	uploadCallback: (src: string, index: number) => void,
) {
	let start = 0;
	try {
		const recursiveWalk = (node: any) => {
			//AIM: Calculate global position for child and continue

			node.content?.forEach((childNode: any) => {
				// logger.debug("Processing", childNode.type)
				if (childNode.type === "image") {
					// No s3 metadata
					if (
						!childNode.attrs.metadata.s3ObjectId &&
						isDataURI(childNode.attrs.src)
					) {
						const presigned = images![start];
						const id = presigned.fields.key.split("/").at(-1);
						uploadCallback(childNode.attrs.src, start);
						start += 1;
						childNode.attrs.metadata.s3ObjectId = id;
					}
					if (isDataURI(childNode.attrs.src)) {
						childNode.attrs.src = "";
					}
				}

				recursiveWalk(childNode);
			});
		};

		recursiveWalk(nbObj);
		// logger.debug("SOURCE RENDER")
		return nbObj;
	} catch (e) {
		logger.error("SOURCE ERROR", e);

		return nbObj;
	}
}

export function findAllDataUriImages(
	nbObj: any,
	images: any[],
	uploadCallback: (src: string, index: number) => void,
) {
	let start = 0;
	try {
		const recursiveWalk = (node: any) => {
			//AIM: Calculate global position for child and continue

			node.content?.forEach((childNode: any) => {
				// logger.debug("Processing", childNode.type)
				if (childNode.type === "image") {
					if (childNode.attrs.metadata.zoomFactor) {
						const presigned = images![start];
						const id = presigned.fields.key.split("/").at(-1);
						uploadCallback(childNode.attrs.src, start);
						start += 1;
						childNode.attrs.metadata.s3ObjectId = id;
					}
				}

				recursiveWalk(childNode);
			});
		};

		recursiveWalk(nbObj);
		// logger.debug("SOURCE RENDER")
		return nbObj;
	} catch (e) {
		logger.error("SOURCE ERROR", e);

		return nbObj;
	}
}
