import React from "react";
import "./GridLoader.css";
import { getBrandingColor } from "../../../core/utils/styleUtils";

type GridLoaderProps = {
	center?: boolean;
	color?: string;
};

const GridLoader: React.FC<GridLoaderProps> = (props) => {
	var color = "#d43f8c";

	if (props.color) {
		color = props.color;
	} else if (getBrandingColor()) {
		color = getBrandingColor();
	}

	return (
		<div
			className="lds-grid"
			style={
				props.center
					? {
							zoom: 2,
							position: "fixed",
							top: "50%",
							left: "50%",
							WebkitTransform: "translate(-50%, -50%)",
							transform: "translate(-50%, -50%)",
						}
					: undefined
			}
		>
			<div style={{ background: color }}></div>
			<div style={{ background: color }}></div>
			<div style={{ background: color }}></div>
			<div style={{ background: color }}></div>
			<div style={{ background: color }}></div>
			<div style={{ background: color }}></div>
			<div style={{ background: color }}></div>
			<div style={{ background: color }}></div>
			<div style={{ background: color }}></div>
		</div>
	);
};
export default GridLoader;
