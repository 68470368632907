// modalSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReactNode } from "react";
import { OverlayComponent } from "../Overlay/overlaySlice";

interface ModalState {
	isOpen: boolean;
	content: OverlayComponent;
}

const initialState: ModalState = {
	isOpen: false,
	content: null,
};

const modalSlice = createSlice({
	name: "modal",
	initialState,
	reducers: {
		openModal: (state, action: PayloadAction<OverlayComponent>) => {
			state.isOpen = true;
			state.content = action.payload;
		},
		closeModal: (state) => {
			state.isOpen = false;
			state.content = null;
		},
	},
});

export const { openModal, closeModal } = modalSlice.actions;
export default modalSlice.reducer;
