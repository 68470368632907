export const replaceIcon = (color?: string) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="0 0 16 16"
			fill="none"
		>
			<path
				d="M2.01669 1.50003C2.01114 1.49985 2.00558 1.49976 2 1.49976C1.86739 1.49976 1.74021 1.55243 1.64645 1.6462C1.55268 1.73997 1.5 1.86715 1.5 1.99976V10.9998C1.5 11.1324 1.55268 11.2595 1.64645 11.3533C1.74021 11.4471 1.86739 11.4998 2 11.4998H12.7931L10.6462 13.646C10.5524 13.7398 10.4997 13.8671 10.4997 13.9998C10.4997 14.1324 10.5524 14.2597 10.6462 14.3535C10.7401 14.4473 10.8673 14.5 11 14.5C11.1327 14.5 11.2599 14.4473 11.3538 14.3535L14.3538 11.3535C14.4002 11.3071 14.4371 11.2519 14.4623 11.1912C14.4874 11.1305 14.5004 11.0655 14.5004 10.9998C14.5004 10.934 14.4874 10.869 14.4623 10.8083C14.4371 10.7476 14.4002 10.6924 14.3538 10.646L11.3538 7.64601C11.2599 7.55219 11.1327 7.49948 11 7.49948C10.8673 7.49948 10.7401 7.55219 10.6462 7.64601C10.5524 7.73983 10.4997 7.86707 10.4997 7.99976C10.4997 8.13244 10.5524 8.25969 10.6462 8.35351L12.7931 10.4998H2.5V2.50003H7.00003C7.13264 2.50003 7.25982 2.44736 7.35359 2.35359C7.44597 2.2612 7.49847 2.13639 7.5 2.0059V1.99417C7.49847 1.86368 7.44597 1.73887 7.35359 1.64648C7.25982 1.55271 7.13264 1.50003 7.00003 1.50003H2.01669Z"
				fill={color ?? "currentColor"}
			/>
		</svg>
	);
};
