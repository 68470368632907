// export const drawRoundedRect = (ctx: CanvasRenderingContext2D, x: number, y: number, width: number, height: number, radius: number) => {

import { GigaUserApi } from "@giga-user-fern/api";

//     //Draws a rounded rectangle

//     ctx.moveTo(x + radius, y);
//     ctx.lineTo(x + width - radius, y);
//     ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
//     ctx.lineTo(x + width, y + height - radius);
//     ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
//     ctx.lineTo(x + radius, y + height);
//     ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
//     ctx.lineTo(x, y + radius);
//     ctx.quadraticCurveTo(x, y, x + radius, y);
// };

export const drawRoundedRect = (
	ctx: CanvasRenderingContext2D,
	x: number,
	y: number,
	width: number,
	height: number,
	radius: number,
) => {
	// Ensure the radius does not exceed 1/2 of the smallest rectangle dimension
	radius = Math.min(radius, width / 2, height / 2);

	// Begins a new path (to avoid issues with previous paths)
	ctx.beginPath();

	// Moves to the start point of the top horizontal line
	ctx.moveTo(x + radius, y);

	// Draws lines and curves
	ctx.lineTo(x + width - radius, y);
	ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
	ctx.lineTo(x + width, y + height - radius);
	ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
	ctx.lineTo(x + radius, y + height);
	ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
	ctx.lineTo(x, y + radius);
	ctx.quadraticCurveTo(x, y, x + radius, y);

	// Closes the path and optionally you can fill or stroke the path here
	// ctx.closePath();
};

export const drawBoxShadow = (
	ctx: CanvasRenderingContext2D,
	x: number,
	y: number,
	width: number,
	height: number,
	shadowFactor: number,
	borderRadius?: number,
) => {
	// Draw shadow
	const shadowOffset = 0; // Adjust as needed
	ctx.save();
	ctx.beginPath();

	borderRadius = borderRadius || 0;

	drawRoundedRect(
		ctx,
		x - shadowOffset,
		y - shadowOffset,
		width + 2 * shadowOffset,
		height + 2 * shadowOffset,
		borderRadius + shadowOffset,
	);
	ctx.shadowColor = "rgba(0, 0, 0, 0.6)"; // Adjust as needed
	ctx.shadowBlur = shadowFactor; // Adjust as needed
	ctx.shadowOffsetX = 2; // Adjust as needed
	ctx.shadowOffsetY = 2; // Adjust as needed
	ctx.fillStyle = "#c7c7c7";
	ctx.fill();
	ctx.restore();
};

export const getScaleFactor = (width: number, height: number) => {
	const w_f = width / 1680;
	const h_f = height / 920;

	return { f: (w_f + h_f) / 2, wf: w_f, hf: h_f };
};

export const sourcesAreEqual = (
	source1: GigaUserApi.Id,
	source2?: GigaUserApi.Id,
) => {
	if (source1.includes("clip_") || source2?.includes("clip_")) {
		//either of the sources is a clip (the other could be the original)
		return source1 === source2;
	} else return true;
};
