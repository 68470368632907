/**
 * This file needs to be kept in sync with backend.
 * Any changes here need changes in the backend too!
 */

type Coordinate = { x: number; y: number };

/***
 * References: https://easings.net/
 * each core func should be a function that goes from (0,0) --> (1,1)
 */

const interpolations: { [key: string]: (x: number) => number } = {
	linear: (x: number) => x,
	easeInOutSin: (x: number) => -(Math.cos(Math.PI * x) - 1) / 2,
	easeOutSin: (x: number) => Math.sin((x * Math.PI) / 2),
	easeOutCirc: (x: number) => Math.sqrt(1 - Math.pow(x - 1, 2)),
	easeInOutCirc: (x: number) =>
		x < 0.5
			? (1 - Math.sqrt(1 - Math.pow(2 * x, 2))) / 2
			: (Math.sqrt(1 - Math.pow(-2 * x + 2, 2)) + 1) / 2,
	easeOutCubic: (x: number) => 1 - Math.pow(1 - x, 3),
	easeOutQuartic: (x: number) => 1 - Math.pow(1 - x, 4),
	easeOutQuintic: (x: number) => 1 - Math.pow(1 - x, 5),
};

const fitFunction = (
	start: Coordinate,
	end: Coordinate,
	currentX: number,
	interpolateFunction: (x: number) => number,
) => {
	const x_gap = end.x - start.x;
	const y_gap = end.y - start.y;

	const x_eff = (currentX - start.x) / x_gap;
	const y_eff = y_gap;

	return y_eff * interpolateFunction(x_eff) + start.y;
};

export const interpolate = (
	start: Coordinate,
	end: Coordinate,
	currentX: number,
	interpolation?: string,
) => {
	var func = interpolations[interpolation || "linear"];
	return fitFunction(start, end, currentX, func);
};
