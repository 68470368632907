import { drawFilledRoundedRect } from "./elements/canvasTextbox";

type TextStyle = {
	x: number | "center";
	y: number | "center";
	color: string;
	fontSize: number;
	fontWeight: number;
	maxWidth: number;
	lineHeight?: number;
	font?: string;
	maxChars?: { portrait: number; landscape: number };
	splitChars?: number;
};

export const printText: (
	ctx: CanvasRenderingContext2D,
	text: string,
	canvasDims: { width: number; height: number },
	textStyle: TextStyle,
) => string[] = (ctx, text, canvasDims, textStyle) => {
	var { fontSize } = textStyle;
	const { x, y, color, fontWeight, font, maxChars, splitChars } = textStyle;

	var max_chars = maxChars?.landscape || 30;
	if (splitChars) max_chars = splitChars;

	fontSize = textStyle.fontSize * 1.6;

	if (canvasDims.height > canvasDims.width) {
		//mobilevideo
		max_chars = maxChars?.portrait || 12;
		fontSize = textStyle.fontSize * 1.4;
	}

	const lineHeight = textStyle.lineHeight || fontSize * 1.43;

	const lines = splitTextEqually(text, max_chars);

	let weight = fontWeight;
	let finalFont = font || "League Spartan";

	if (font === "League Spartan Light") {
		weight = 300;
		finalFont = "League Spartan";
	}

	ctx.fillStyle = color;
	const n_m = (lines.length + 1) / 2;

	for (let i = 0; i < lines.length; i++) {
		ctx.font = `${weight} ${fontSize}px ${finalFont}`;

		var y_pos: number = 0;
		var x_pos: number = 0;

		if (y === "center") {
			y_pos = 0.5 * canvasDims.height + (i + 1 - n_m) * lineHeight;
			if (x === "center") y_pos -= 0.25 * lineHeight;
		} else {
			y_pos = y + i * lineHeight;
		}

		y_pos += fontSize / 2;

		if (x === "center") {
			const textWidth = ctx.measureText(lines[i]).width;
			x_pos = 0.5 * (canvasDims.width - textWidth);
		} else {
			x_pos = x;
		}

		ctx.textAlign = "start";
		ctx.fillText(lines[i], x_pos, y_pos);
	}

	return lines;
};

function splitTextEqually(text: string, maxLineLength: number = 40): string[] {
	const lines: string[] = [];

	let currentLine: string[] = [];
	const words: string[] = text.split(" ");

	for (const word of words) {
		if (currentLine.join(" ").length + word.length + 1 <= maxLineLength) {
			currentLine.push(word);
		} else {
			lines.push(currentLine.join(" "));
			currentLine = [word];
		}
	}

	if (currentLine.length) lines.push(currentLine.join(" "));

	return lines;
}
