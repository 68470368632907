const f = (color = "#000000") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 800 800"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clip-path="url(#clip0_1856_2)">
				<path
					d="M469.794 110.526H675.882C604.105 42.1006 507.001 0 399.996 0C451.58 0 442.296 43.6408 469.794 110.526Z"
					fill="#E73B36"
				/>
				<path
					d="M399.996 799.998C506.997 799.998 604.101 757.898 675.886 689.473H450.847C423.358 756.358 451.587 799.998 399.996 799.998Z"
					fill="#E73B36"
				/>
				<path
					d="M752.865 588.423H505.573C498.115 625.491 488.318 659.784 476.11 689.474H675.882C706.583 660.201 732.679 626.146 752.865 588.423Z"
					fill="#F3F4F5"
				/>
				<path
					d="M495.056 211.577H752.863C732.678 173.854 706.581 139.799 675.88 110.526H465.594C477.796 140.203 487.592 174.509 495.056 211.577Z"
					fill="#F3F4F5"
				/>
				<path
					d="M444.531 588.423H752.864C782.908 532.269 799.993 468.141 799.993 400.001C799.993 331.861 782.908 267.731 752.864 211.579H444.531C467.956 328.012 467.956 471.979 444.531 588.423Z"
					fill="#14477D"
				/>
				<path
					d="M518.61 689.474H124.116C195.894 757.899 292.998 800 399.992 800C451.586 799.998 491.123 756.357 518.61 689.474Z"
					fill="#E73B36"
				/>
				<path
					d="M518.61 110.526C491.109 43.6408 451.579 0 399.996 0C293.008 0 195.901 42.1006 124.116 110.526H518.61Z"
					fill="#E73B36"
				/>
				<path
					d="M518.61 110.526H124.117C93.416 139.799 67.3194 173.854 47.1338 211.577H548.071C540.613 174.509 530.817 140.203 518.61 110.526Z"
					fill="#F3F4F5"
				/>
				<path
					d="M124.116 689.474H518.608C530.817 659.784 540.612 625.489 548.07 588.423H47.1367C67.3206 626.146 93.4173 660.201 124.116 689.474Z"
					fill="#F3F4F5"
				/>
				<path
					d="M548.07 211.577H47.1366C17.0913 267.731 0.00488281 331.859 0.00488281 399.999C0.00488281 468.139 17.0897 532.269 47.1333 588.421H548.07C571.497 471.979 571.497 328.012 548.07 211.577Z"
					fill="#14477D"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1856_2">
					<rect width="800" height="800" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
export default f;
