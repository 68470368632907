const olLight = (color = "#000000") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 268 267"
			version="1.1"
			fillRule="evenodd"
			clipRule={"evenodd"}
			strokeMiterlimit={10}
		>
			<g transform="matrix(1,0,0,1,-7927,-3239)">
				<g id="Page-9" transform="matrix(1,0,0,1,6750,2250)">
					<rect x="0" y="0" width="100%" height="100%" fill="none" />
					<g id="Layer-2">
						<g>
							<g transform="matrix(1,0,0,1,964.106,1032.54)">
								<path
									d="M0,0L181.441,0"
									fill="none"
									fillRule="nonzero"
									stroke={color} //rgb(49,57,65)
									strokeWidth={"24px"}
								/>
							</g>
							<g transform="matrix(1,0,0,1,963.88,1119.99)">
								<path
									d="M0,0L181.441,0"
									fill="none"
									fillRule="nonzero"
									stroke={color} //rgb(49,57,65)
									strokeWidth={"24px"}
								/>
							</g>
							<g transform="matrix(1,0,0,1,964.633,1212.72)">
								<path
									d="M0,0L181.441,0"
									fill="none"
									fillRule="nonzero"
									stroke={color} //rgb(49,57,65)
									strokeWidth={"24px"}
								/>
							</g>
						</g>
						<g transform="matrix(1,0,0,1,1250.46,1032.54)">
							<path
								d="M0,0L181.441,0"
								fill="none"
								fillRule="nonzero"
								stroke={color} //rgb(49,57,65)
								strokeWidth={"24px"}
							/>
						</g>
						<g transform="matrix(1,0,0,1,1250.23,1119.99)">
							<path
								d="M0,0L181.441,0"
								fill="none"
								fillRule="nonzero"
								stroke={color} //rgb(49,57,65)
								strokeWidth={"24px"}
							/>
						</g>
						<g transform="matrix(1,0,0,1,1250.99,1212.72)">
							<path
								d="M0,0L181.441,0"
								fill="none"
								fillRule="nonzero"
								stroke={color} //rgb(49,57,65)
								strokeWidth={"24px"}
							/>
						</g>
						<g transform="matrix(1.53223,0,0,1.53223,-10981.3,-3995.72)">
							<text
								x="7948.17px"
								y="3308.67px"
								fontFamily={`'AppleSDGothicNeo-Bold', 'Apple SD Gothic Neo'`}
								fontWeight={700}
								fontSize="65.602px"
								fill={color}
							>
								1
							</text>
						</g>
						<g transform="matrix(1.53223,0,0,1.53223,-10987.8,-3909.16)">
							<text
								x="7948.17px"
								y="3308.67px"
								fontFamily={`'AppleSDGothicNeo-Bold', 'Apple SD Gothic Neo'`}
								fontWeight={700}
								fontSize="65.602px"
								fill={color}
							>
								2
							</text>
						</g>
						<g transform="matrix(1.53223,0,0,1.53223,-10986.4,-3819.06)">
							<text
								x="7948.17px"
								y="3308.67px"
								fontFamily={`'AppleSDGothicNeo-Bold', 'Apple SD Gothic Neo'`}
								fontWeight={700}
								fontSize="65.602px"
								fill={color}
							>
								3
							</text>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default olLight;
