export const stepsIcon = (color?: string) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="0 0 16 17"
			fill="none"
		>
			<path
				opacity="0.2"
				d="M15 3.82568V12.3257C15 12.4583 14.9473 12.5855 14.8536 12.6792C14.7598 12.773 14.6326 12.8257 14.5 12.8257H4.5V9.82568H8V6.82568H11.5V3.82568H15Z"
				fill={color ?? "currentColor"}
			/>
			<path
				d="M15.5 3.82568C15.5 3.95829 15.4473 4.08547 15.3536 4.17924C15.2598 4.27301 15.1326 4.32568 15 4.32568H12V6.82568C12 6.95829 11.9473 7.08547 11.8536 7.17924C11.7598 7.27301 11.6326 7.32568 11.5 7.32568H8.5V9.82568C8.5 9.95829 8.44732 10.0855 8.35355 10.1792C8.25979 10.273 8.13261 10.3257 8 10.3257H5V12.8257C5 12.9583 4.94732 13.0855 4.85355 13.1792C4.75979 13.273 4.63261 13.3257 4.5 13.3257H1C0.867392 13.3257 0.740215 13.273 0.646447 13.1792C0.552678 13.0855 0.5 12.9583 0.5 12.8257C0.5 12.6931 0.552678 12.5659 0.646447 12.4721C0.740215 12.3784 0.867392 12.3257 1 12.3257H4V9.82568C4 9.69308 4.05268 9.5659 4.14645 9.47213C4.24021 9.37836 4.36739 9.32568 4.5 9.32568H7.5V6.82568C7.5 6.69308 7.55268 6.5659 7.64645 6.47213C7.74021 6.37836 7.86739 6.32568 8 6.32568H11V3.82568C11 3.69308 11.0527 3.5659 11.1464 3.47213C11.2402 3.37836 11.3674 3.32568 11.5 3.32568H15C15.1326 3.32568 15.2598 3.37836 15.3536 3.47213C15.4473 3.5659 15.5 3.69308 15.5 3.82568Z"
				fill={color ?? "currentColor"}
			/>
		</svg>
	);
};
