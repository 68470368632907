import {
	GuideData,
	GuidePreview,
} from "@giga-user-fern/api/types/api/resources/guides";
import React, { useEffect, useRef } from "react";
import { Device } from "../../../types/devices";
import {
	MediaCommunitySkin,
	MediaOutlet,
	useMediaStore,
	MediaPlayer,
	MediaPoster,
	MediaPlayButton,
	useMediaRemote,
} from "@vidstack/react";
import type {
	MediaPlayEvent,
	MediaPlayFailEvent,
	MediaPlayRequestEvent,
	MediaPauseEvent,
	MediaPlayerElement,
} from "vidstack";
import "./SimpleVideo.css";
import useWindowSize from "../../../hooks/useWindowSize";
import { calculateMediaDuration } from "../../../core/canvas/canvasUtils/videoUtils";

type EmbedVideoProps = {
	video: GuideData["video"];
	videoSrc?: string;
	guidePreview?: GuidePreview;
	overrideDevice?: Device;
};

const EmbedVideo: React.FC<EmbedVideoProps> = (props) => {
	var device: Device = useWindowSize().device;
	if (props.overrideDevice) device = props.overrideDevice;
	const vidRef = useRef<HTMLVideoElement>(null);

	useEffect(() => {
		// logger.debug("src: ", props.video.metadata?.generatedVideo?.src)

		if (device === "mobile" && vidRef.current) {
			calculateMediaDuration(vidRef.current);
		}
	}, []);

	if (device == "laptop")
		return (
			<MediaPlayer
				title={props.guidePreview?.header.name}
				src={props.videoSrc || props.video.originalSrc}
				poster="https://media-files.vidstack.io/poster.png"

				// poster="https://image.mux.com/VZtzUzGRv02OhRnZCxcNg49OilvolTqdnFLEqBsTwaxU/thumbnail.webp?time=268&width=980"
				// thumbnails="https://media-files.vidstack.io/sprite-fight/thumbnails.vtt"
				// aspectRatio={16 / 9}
				// crossorigin=""
			>
				<MediaOutlet></MediaOutlet>

				<MediaCommunitySkin />
			</MediaPlayer>
		);
	else {
		return (
			<video
				ref={vidRef}
				preload="metadata"
				controls
				crossOrigin="anonymous"
				className={`gigauser-editor-video`}
				src={props.videoSrc || props.video.originalSrc}
				// onPlay={handlePlay}
				// onPause={handlePause}s
			>
				{props.video.generated?.subtitles && (
					<track
						label="English"
						kind="subtitles"
						srcLang="en"
						src={props.video.generated?.subtitles}
					/>
				)}
			</video>
		);
	}
};
export default EmbedVideo;
