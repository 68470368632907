const f = (color = "#000000") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 64 64"
			xmlns="http://www.w3.org/2000/svg"
			aria-hidden="true"
			role="img"
			preserveAspectRatio="xMidYMid meet"
		>
			<path
				d="M62 32c0-13.1-8.3-24.2-20-28.3v56.6C53.7 56.2 62 45.1 62 32"
				fill="#ed4c5c"
			></path>

			<path
				d="M2 32c0 13.1 8.4 24.2 20 28.3V3.7C10.4 7.8 2 18.9 2 32z"
				fill="#75a843"
			></path>

			<path
				d="M42 3.7C38.9 2.6 35.5 2 32 2s-6.9.6-10 1.7v56.6c3.1 1.1 6.5 1.7 10 1.7s6.9-.6 10-1.7V3.7"
				fill="#ffffff"
			></path>

			<g fill="#75a843">
				<path d="M40.7 31.7c-.1-.3-.2.1-.2.2c0-.3-.1-.5-.3-.8c-.3.3-.4.7-.4 1.1c-.1-.3-.2-.6-.5-.7c.2.3.1.5.1.8c0 .4.3.7.3 1.1c0 .4-.2.7-.5 1v-.3c-1 .4-.8.9-.8 1.8s-.5.7-1.1 1.2c.1-.2.1-.5 0-.7c0 .6-.8.6-.9 1.1c-.1.2 0 .6-.3.7c-.2.1-.5.3-.5.5c-.2-.1-.4-.1-.7-.1c.1-.1.2-.1.3-.2c-1-.5-1.3.6-2.2.7c-.3 0-1-.4-1 0c.9 0 1.3.8 2 .8c.6 0 1.1-.5 1.7-.5c-.1 0-.4-.2-.7-.2c.5-.3.8-.9 1.4-.9c1 0 1.5-.2 1.8-1.2c-.1 0-.2 0-.3.1c0-.4.4-.9.8-1.1c1-.2.9-1 1.5-1.7c-.2 0-.4.1-.6.3c0-.6-.1-1.2.4-1.6c1-.5.9-.7.7-1.4m-2.3 5"></path>

				<path d="M23.3 31.7c-.2.8-.3 1 .5 1.5c.6.4.4 1 .4 1.6c-.2-.2-.3-.3-.6-.3c.6.6.5 1.5 1.5 1.7c.3.2.8.7.8 1.1c-.1 0-.2-.1-.3-.1c.3 1 .8 1.2 1.8 1.2c.6 0 .9.6 1.4.9c-.3 0-.6.1-.7.2c.6 0 1.1.6 1.7.5c.7 0 1.2-.8 2-.8c0-.4-.7 0-1 0c-.8-.2-1.1-1.2-2.2-.8c.1.1.2.2.3.2c-.2 0-.5-.1-.7.1c-.1-.2-.3-.4-.5-.5c-.2-.2-.2-.5-.3-.7c-.2-.5-.9-.6-.9-1.1c-.1.2-.1.5 0 .7c-.6-.5-1.1-.3-1.1-1.2c0-.8.2-1.4-.8-1.8v.3c-.3-.3-.5-.5-.5-1c0-.4.4-.7.3-1.1c0-.3-.1-.5.1-.8c-.3.1-.4.4-.5.7c0-.4-.1-.8-.4-1.1c-.1.2-.2.5-.3.8c.1-.2 0-.5 0-.2m2.3 5"></path>
			</g>

			<path
				d="M34.7 37.7c-.2 0-.3-.2-.4-.3c0-.2.8-.4.9-.4v-.2c-1.3.4-5.2.7-6-.7c-.4.6-1.1.2-1.8-.3c.1.2.5 1.2.5 1.1c.2.1 1.5.4 1.5.7l-.3.3c2.1.7 3.4.9 5.6-.2"
				fill="#428bc1"
			></path>

			<path
				d="M33.4 37.7c.1-.1.1-.2.1-.4l-.1-.3c.2-.2 0-.7-.3-.6c-.2.1-.1.1-.3 0c-.1 0-.1-.1-.2-.1h-1.8c-.2 0-.2-.1-.4-.1c-.2-.1-.3.1-.4.2c0 .2.1.2.1.3c0 .1-.1.2-.1.4s.1.3.2.4c-.4.3 0 .8.4.5c.1.2.4.2.7.2h1.1c.2 0 .6.1.7-.2c.3.5.7-.1.3-.3"
				fill="#ed4c5c"
			></path>

			<path
				d="M36.6 35.1c-.8-1-1.2.5-2.1.4c.2-1.2-1.6-.8-2.1-.5c.1-.1.2-.3.3-.5c-.4 0-.9.2-1.2-.1c-.6-.4-1.4-.6-1.9.1c-.4-.6-1.5-1.2-2.1-.6c0-.6-.6-1.5-1.3-1.4c-.8.1-.5 1.1-.1 1.5c.3.3.7.4 1.1.4c0 .2.1.3.2.4c.5.4 1.5.5 2 .1c0 1 2 1 2.5.5c-.2.5-.4 1.5.3 1.5c.6 0 .4-.6.9-.8c.4-.2.9-.3 1.3-.1c.7.4 2.9.3 2.2-.9"
				fill="#428bc1"
			></path>

			<g fill="#75a843">
				<path d="M28.4 32.6c-.1-.2-.3-.4-.3-.4c-.4.1-.4-.2-.5-.5c-.1-.5-.8-.7-.8-1.1c0-.4.4-.8.1-1.2c-.3-.4-.8-.6-.8-.4c-.1.2.4.2.4.7c0 .6-.6 1.1.1 1.6c.4.6.4 1.7 1.1 1.7c.3 0 .6-.1.7-.4"></path>

				<path d="M29.9 32.1c-.2-.4 0-1-.4-1.3c-.5-.4-1.5.4-1.6-.6c0-.2 1.1-1.4 1.3-1.5c.3-.4.2-1.1-.1-1.3c-.4-.2-.5 0-.8.4c.1.1.5.3.5.4c-.3.7-1.2.8-1.4 1.5c-.2.5-.1 1.3.4 1.6c.3.2.5.2.8.2c.9-.1.4.1.5.3c0 .2.3.9.8.3"></path>

				<path d="M26.9 27.2c0 .6.6.5 1 .4c0-.2 0-.4.1-.6c-.2.1-.5.1-.6 0c-.3-.2.7-.6.6-.6c0 0-1 .5-.6-.1c.1-.2.3-.4.4-.6c.1-.2-.3 0-.3 0c-.1 0-.2 0-.3.1c-.6.5-.4.8-.3 1.4"></path>
			</g>

			<path
				d="M37.5 27.2c-.5-.7-2.4-3-5.1-3.2c-.3 0-1.1.3-1.4.4c-.9.6 1 1.1 1.1 1.5c0 .2.1.3.1.5c-.3-.5-.6-.8-.7-1c-.5.2-1.3-.1-1.4-.6c-.1.2-.1.3-.3.5c0-.3-.1-.3-.2-.5c0 .3 0 .7-.3.8c.1-.2 0-.4-.1-.6c0 .3 0 .5-.2.7c.1-.2-.1-.3-.1-.5c-.1.8-1.2 1.1-.1 1.4c.4.1.6 0 1 .2c.1.1.3.3.3.2c0 .1-.4.5-.1.6c-.1.1-.4.7-.4.7c.3 0 .1.2-.1.4c-.3.5 0 1 .1 1.6c0-.2.3.1.3.4c0 .4.4.6.5.9c-.5-.5-.6 0 0 .3c-.5 0-.6.3-.1.5c-1.2 0-.2.5 0 .6c.8.2 1.4-.4 1.4-.5c0 0 1.6 1.6 1.7 1.6c.1 0 .9-.4 1.1-.2c.2.3.4 0 .6.1c.2.2 1 0 1.2-.1c.1 0 1.1-.2.9-.4c-.5-.4-1.1-.8-1.6-1.2c-.5-.4-1-.7-1.4-1.1c-.2-.2-.2-.6-.3-.9c.9.9 1.8 1.4 1.7-.3c.6.5 1.8 3.3 1.9 3.3c.3 0 0-3 0-3.3c.4.4.4 3.7.5 3.7c.5.1 1.2-4.3-.5-6.5"
				fill="#89664c"
			></path>

			<g fill="#ffce31">
				<path d="M30.3 32.3c-.1 0-.6-.7-.6-.8c.3 1.2-.9.5-.6 0c-.4-.1-1.4.5-1 1.1c-.2.4 1.4.1 1.5-.1c.4.9 1.4-.1.7-.2"></path>

				<path d="M35 35.3c.5-.7-1.6-.9-1.4-1.7c-.3.2-.7.9-1.1.7c-.2-.1-1 .4-.6.7c0-.4.2-.1.5-.2c-.1.3-.4.6.1.8c-.2-.6.6-.3.5-.6c-.2-.2.7-.2.8-.2c.3 0 1.3.3.9.6c0 0 .2 0 .3-.1"></path>

				<path d="M28.3 26.7c-.1.2-.5.5-.5.8c0 .2.5.7.7.4c-.6-.4.1-.7.4-.7c.1 0 .1.2.1.2c.1.1.9-.2.8-.4c.1-.4-1.2-.4-1.5-.3m0 0"></path>
			</g>
		</svg>
	);
};

export default f;
