const rectangleIcon = (color = "#ffffff") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7 29V1L28 19.648L16.0409 21.5126L7 29ZM9.43492 6.41609V23.8235L15.0036 19.2126L22.5238 18.0389L9.43492 6.41609Z"
				fill={color}
			/>
		</svg>
	);
};
export default rectangleIcon;
