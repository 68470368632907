import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { useAppSelector } from "../useAppRedux";

type HelpCenterUiState = {
	searchVisible: boolean;
	theme: "gigauser-light" | "gigauser-dark";
};

const initialState: HelpCenterUiState = {
	// viewerContainer: null,
	searchVisible: false,
	theme: "gigauser-light",
};

export const hcUiSlice = createSlice({
	name: "hcUi",
	initialState,
	reducers: {
		setSearchVisible: (state, action: PayloadAction<boolean>) => {
			state.searchVisible = action.payload;
		},
		setTheme: (
			state,
			action: PayloadAction<"gigauser-light" | "gigauser-dark">,
		) => {
			state.theme = action.payload;
		},
	},
});

export const { setSearchVisible, setTheme } = hcUiSlice.actions;
// export const selectViewerContainer = (state: RootState) => state.hcUi.viewerContainer
export default hcUiSlice.reducer;
