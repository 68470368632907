const f = (color = "#000000") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 800 800"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M135 135V400H775C775 192.5 607.5 25 400 25C296.25 25 202.5 67.5 135 135Z"
				fill="#F9F9F9"
			/>
			<path
				d="M135 400V665C202.5 732.5 296.25 775 400 775C607.5 775 775 607.5 775 400H135Z"
				fill="#D7141A"
			/>
			<path
				d="M135 135C67.5 202.5 25 296.25 25 400C25 503.75 67.5 597.5 135 665L400 400L135 135Z"
				fill="#11457E"
			/>
		</svg>
	);
};
export default f;
