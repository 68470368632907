import React, { useContext, useState } from "react";
import paintbucketIcon from "../../../../../../../assets/svgs/screenshot/paintbucketIcon";
import penIcon from "../../../../../../../assets/svgs/screenshot/penIcon";
import strokeWidthIcon from "../../../../../../../assets/svgs/screenshot/strokeWidthIcon";
import Icon from "../../../../../../../ui/Icon/Icon";

import "./ShapeCustomizer.css";
import Clickout from "../../../../../../../layouts/Clickout/Clickout";
import ScreenshotEditorContext, {
	Shape,
	UpdateShapeProperties,
} from "../../ScreenshotEditorContext";
import CColorPicker from "../../../../../../../ui/inputs/CColorPicker/CColorPicker";

type ShapeCustomizerProps = {
	shape: Shape;
};

const ShapeCustomizer: React.FC<ShapeCustomizerProps> = ({ shape }) => {
	const [showFillPopup, setShowFillPopup] = useState(false);
	const [showStrokePopup, setShowStrokePopup] = useState(false);
	const [showStrokeWidthPopup, setShowStrokeWidthPopup] = useState(false);

	const sc = useContext(ScreenshotEditorContext);

	const onUpdateShape = (
		id: string,
		updatedProperties: UpdateShapeProperties,
	) => {
		sc.updateShape(shape.id, updatedProperties);

		const currentProperties = JSON.parse(
			localStorage.getItem(`ArticleScreenshot${shape.geo}Properties`) ||
				"{}",
		);
		localStorage.setItem(
			`ArticleScreenshot${shape.geo}Properties`,
			JSON.stringify({ ...currentProperties, ...updatedProperties }),
		);
	};

	const setFillColor = (color?: string, opacity?: number) => {
		onUpdateShape(shape.id, {
			fill: color,
			fillOpacity: opacity,
		});
	};

	const setStrokeColor = (color?: string) => {
		onUpdateShape(shape.id, {
			stroke: color,
		});
	};

	const setStrokeWidth = (width: number) => {
		const bounds = sc.imgRef?.current!.getBoundingClientRect();
		if (!bounds) return;

		onUpdateShape(shape.id, {
			strokeWidth:
				(width * 1000) / Math.max(bounds?.width, bounds.height),
			version: "2024-04-26",
		});
	};

	const strokes = [1, 2, 3, 4, 6, 8, 10];
	if (shape.geo === "circle") strokes.unshift(0);

	const bounds = sc.imgRef?.current!.getBoundingClientRect();
	return (
		<>
			<div className="ShapeCustomizer">
				{shape.geo !== "arrow" && (
					<div className="customizer-popup-icon-container">
						<CColorPicker
							color={shape.fill || "#ffffff"}
							opacity={
								shape.fillOpacity == undefined
									? 30
									: shape.fillOpacity
							}
							onChange={setFillColor}
							allowClear
						>
							<Icon
								onClick={() => {
									setShowFillPopup(!showFillPopup);
								}}
								hoverTip="Fill"
								className="customizer-icon customizer-fill"
								style={{
									borderColor: shape.fill
										? shape.fill
										: "transparent",
								}}
							>
								{paintbucketIcon("white")}
							</Icon>
						</CColorPicker>
					</div>
				)}

				<div className="customizer-popup-icon-container">
					<CColorPicker
						disableOpacity
						allowClear
						color={shape.stroke || "#000000"}
						onChange={setStrokeColor}
					>
						<Icon
							onClick={() => {
								setShowStrokePopup(!showStrokePopup);
							}}
							hoverTip="Stroke color"
							className="customizer-icon"
							style={{
								borderColor: shape.stroke || "transparent",
							}}
						>
							{penIcon("white")}
						</Icon>
					</CColorPicker>
				</div>

				<div className="customizer-popup-icon-container">
					<Icon
						onClick={() => {
							setShowStrokeWidthPopup(!showStrokeWidthPopup);
						}}
						hoverTip="Stroke Width"
						className="customizer-icon borderless"
					>
						{strokeWidthIcon("white")}
					</Icon>

					{showStrokeWidthPopup ? (
						<div className="customizer-popup fillPopup">
							<Clickout
								closeFunction={() => {
									setShowStrokeWidthPopup(false);
								}}
							>
								<div className="StrokeWidthPicker">
									{strokes.map((sw) => (
										<div
											className={`StrokeWidthOption ${
												sw ==
												Math.round(
													(shape.strokeWidth ?? 1) *
														Math.max(
															bounds?.width ?? 1,
															bounds?.height ?? 1,
														),
												) /
													1000
													? "active-sw"
													: "inactive-sw"
											}`}
											onClick={() => {
												setStrokeWidth(sw);
											}}
										>
											{sw} px
										</div>
									))}
								</div>
							</Clickout>
						</div>
					) : null}
				</div>
			</div>
		</>
	);
};
export default ShapeCustomizer;
