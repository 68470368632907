import React, { useContext, useState } from "react";
import { rootCollection } from "@gigauser/common/src/types/files";

import "../Breadcrumbs/Breadcrumbs.css";
import BreadcrumbSection from "./BreadcrumbSection";
import { Collection } from "@gigauser/common/src/core/types/collections";
import homeIcon from "../../../assets/svgs/home";
import Icon from "../../Icon/Icon";
import downArrowheadIcon from "../../../assets/svgs/downArrowheadIcon";

export type BreadcrumbsProps = {
	rootHandler?: () => void; //defined if root is to be appended to the beginning.
	segments: [Collection, ...Collection[]];
	navigationHandler?: (segment: Collection) => void;
	droppable?: boolean; //whether files can be dropped on the sections to move them there.
	appTheme?: "gigauser-light" | "gigauser-dark";
};

const Breadcrumbs: React.FC<BreadcrumbsProps> = (props) => {
	const { segments } = props;

	// Map each segment
	const finalList: any = [];

	const navigationHandler = (segment: Collection) => {
		if (props.navigationHandler) return props.navigationHandler(segment);
		else return null;
	};

	segments.forEach((segment, index) => {
		finalList.push(
			<BreadcrumbSection
				appTheme={props.appTheme}
				key={segment.id}
				segment={segment}
				navigationHandler={
					props.navigationHandler ? navigationHandler : undefined
				}
				activeSegment={index === segments.length - 1}
			/>,
		);
		if (index < segments.length - 1) {
			finalList.push(
				<div
					key={segment.id + ">"}
					className="gigauser-Breadcrumb-arrow"
				>
					{downArrowheadIcon("#c2c2c2")}
				</div>,
			);
		}
	});
	return <div className="gigauser-Breadcrumbs">{finalList}</div>;
};
export default Breadcrumbs;
