import React, { useContext, useEffect, useState } from "react";
import {
	Slider,
	SliderTrack,
	SliderFilledTrack,
	SliderThumb,
	SliderMark,
} from "@chakra-ui/react";

import "./CanvasScrubber.css";
import { formatTime } from "../../../canvasUtils/videoUtils";
import CanvasPlayerContext from "../../../CanvasPlayerContext";

type CanvasScrubberProps = {};

const CanvasScrubber: React.FC<CanvasScrubberProps> = () => {
	const [sliderValue, setSliderValue] = React.useState(5);
	const [showTooltip, setShowTooltip] = React.useState(false);

	const [mouseDown, setMouseDown] = useState(false);

	const [smooth, setSmooth] = useState(true);

	const cp = useContext(CanvasPlayerContext);

	const vidRef = cp.vidRef;

	if (!vidRef) throw new Error("Missing vidRef in CanvasScrubber!");

	const [value, setValue] = useState(cp.currentTime);

	const onChange = (value: number) => {
		setSmooth(false);
		setValue(value);
		cp.hardsetCurrentTime(value);
	};

	const onChangeEnd = () => {
		if (cp.paused) setSmooth(false);
		else setSmooth(true);
	};

	useEffect(() => {
		setValue(cp.currentTime);
	}, [cp.currentTime]);

	useEffect(() => {
		if (cp.paused) setSmooth(false);
		else setSmooth(true);
	}, [cp.paused]);

	const totalDuration = cp.getVideoDuration();

	return (
		<div
			onMouseEnter={() => {
				setMouseDown(true);
			}}
			onMouseUp={() => {
				setTimeout(() => {
					setMouseDown(false);
				}, 400);
			}}
		>
			<Slider
				aria-label="slider-ex-1"
				defaultValue={0}
				value={value}
				min={0}
				max={totalDuration}
				onChange={onChange}
				onChangeEnd={onChangeEnd}
				className="gigauser-scrubber-slider"
				colorScheme="pink"
			>
				<SliderTrack className="gigauser-scrubber-slidertrack">
					<SliderFilledTrack
						className={`gigauser-scrubber-sliderfilledtrack 
                ${smooth ? "gigauser-smooth-track" : ""}`}
					/>
				</SliderTrack>
				<SliderThumb className="gigauser-scrubber-sliderthumb" />
			</Slider>
		</div>
	);
};
export default CanvasScrubber;
