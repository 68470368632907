import React, { useEffect, useRef, useState } from "react";
import Breadcrumbs from "../../ui/tabs/BreadcrumbsReadonly/Breadcrumbs";
import { Collection, CollectionChildren } from "../../core/types/collections";
import { GigaUserApi } from "@giga-user-fern/api";
import { rootCollection } from "../../types/files";
import {
	GuideData,
	GuidePreview,
} from "@giga-user-fern/api/types/api/resources/guides";
import GuidesList from "../../components/guides/GuidesList/GuidesList";
import CollectionList from "../../components/CollectionList/CollectionList";
import "../HelpCenter/HelpCenter.css";
import "./ContentNavigator.css";
import { Device } from "../../types/devices";
import useWindowSize from "../../hooks/useWindowSize";
import { Navigate } from "../HelpCenter/HelpCenter";
import { getPathFromRoute, isProdDomain } from "../utils/routeUtils";
import { current } from "@reduxjs/toolkit";
import logger from "../../utils/logger";
import { Organization } from "@giga-user-fern/api/types/api/resources/organizations";

type ContentNavigatorProps = {
	fetchAllChildren: (collectionId?: string) => Promise<CollectionChildren>;
	onOpenGuide: (preview: GuidePreview) => void;
	getPath: (
		id: GigaUserApi.Id,
	) => Promise<false | [Collection, ...Collection[]]>;
	getOrganization: () => Organization | null;

	collectionPath?: [Collection, ...Collection[]]; //if collectionPath not passed will assume as loading.
	setCollectionPath: React.Dispatch<
		React.SetStateAction<
			[
				GigaUserApi.collections.Collection,
				...GigaUserApi.collections.Collection[],
			]
		>
	>;

	onStartOpenCollection?: (collectionID: GigaUserApi.Id) => void; //Triggered when a collection is clicked and the loading of data starts.
	onFinishOpenCollection?: (
		collections: Collection[],
		guides: GuidePreview[],
	) => void;
	showPublishedStatus: boolean; //whether to show circle in guideslist
	/**
	 * Triggered when the new collection is done loading.
	 * This is useful for parent elements to know what's happening inside the ContentNavigator
	 * Eg: When recorder needs to know config (parentID and sequenceNumber) before it can start recording.
	 * @param collections: an array of collections that are children of collection
	 * @param guides: an array of guide previews that are children of collection
	 */

	//OPTIONAL
	overrideDevice?: Device;
	navigator?: () => Navigate;
	showPrivate?: boolean;
};

const ContentNavigator: React.FC<ContentNavigatorProps> = ({
	collectionPath,
	setCollectionPath,
	...props
}) => {
	var navigate: Navigate | undefined;

	if (props.navigator) {
		navigate = props.navigator();
	}

	var device = useWindowSize().device;
	if (props.overrideDevice) device = props.overrideDevice;
	const gigDevice = "gigauser-" + device;

	// const previousID = useRef<GigaUserApi.Id | undefined>()

	const currentId = collectionPath
		? collectionPath[collectionPath.length - 1].id
		: GigaUserApi.Id("loading");

	const [prevCurrentId, setPrevCurrentId] =
		useState<GigaUserApi.Id>(currentId);

	const [collectionChildren, setCollectionChildren] =
		useState<CollectionChildren>({
			collections: [],
			guidePreviews: [],
		});

	const [loading, setLoading] = useState(true);

	const navigateToLink = (string: string) => {
		if (navigate) {
			navigate(
				string +
					(!isProdDomain()
						? `?org=${props.getOrganization()?.id}`
						: ""),
			);
		}
	};

	const loadData = () => {
		logger.debug("initialising! ", currentId);
		setLoading(true);

		if (currentId === "loading") return;

		if (props.onStartOpenCollection) props.onStartOpenCollection(currentId);

		props
			.fetchAllChildren(currentId)
			.then((newChildren: CollectionChildren) => {
				logger.debug(
					"init got children in ContentNavigator: ",
					newChildren,
				);

				const { collections, guidePreviews } = newChildren;

				setCollectionChildren({
					collections: collections.filter(
						(collection) => !collection.deleted,
					),
					guidePreviews: guidePreviews.filter(
						(guidePreview) => !guidePreview.deleted,
					),
				});

				setLoading(false);

				if (props.onFinishOpenCollection) {
					props.onFinishOpenCollection(
						newChildren.collections,
						newChildren.guidePreviews,
					);
				}
			});
	};

	if (prevCurrentId !== currentId) {
		//The current ID has changed
		setPrevCurrentId(currentId);
		loadData();
	}

	useEffect(() => {
		logger.debug("need to load data");

		// ------- Initialise ----------
		loadData();
	}, []);

	const onClickCollectionBreadcrumbs = async (collection: Collection) => {
		const newSegments: Collection[] = [];

		if (!collectionPath) return;

		for (let i = 0; i < collectionPath.length; i++) {
			logger.debug("check: ", collectionPath[i].id, collection.id);

			if (collectionPath[i].id === collection.id) {
				newSegments.push(collectionPath[i]);
				// previousID.current = currentID
				setCollectionPath(newSegments as [Collection, ...Collection[]]);

				navigateToLink("/folders/" + collection.id);

				return;
			}

			newSegments.push(collectionPath[i]);
		}
	};

	const onAppendCollection = async (collection: Collection) => {
		if (!collectionPath) return;

		setCollectionPath([...collectionPath, collection]);

		logger.debug("onOpenCollection");
		// previousID.current = currentID

		navigateToLink("/folders/" + collection.id);

		// setLoading(true)
	};

	return (
		<div
			className={`gigauser-guideslist-container ${loading ? "gigauser-loading-flash" : ""} ${gigDevice}`}
		>
			{collectionPath ? (
				<div className="gigauser-helpcenter-breadcrumbs">
					<Breadcrumbs
						navigationHandler={onClickCollectionBreadcrumbs}
						segments={collectionPath}
					></Breadcrumbs>
				</div>
			) : null}

			{collectionChildren.collections.length &&
			collectionChildren.guidePreviews.length === 0 ? null : ( //If are collections but no articles, don't show empty message in articles.
				<>
					<h3 className="gigauser-guideslist-title-help">Projects</h3>

					<GuidesList
						guidePreviews={
							// (searchString && searchResults.length)?
							// searchResults.map(res=>res.guidePreview)
							// :
							collectionChildren.guidePreviews
							// collectionChildren.guidePreviews.flatMap(ele => [ele, ele, ele, ele, ele, ele, ele, ele, ele, ele, ele, ele])
						}
						showPublishedStatus={props.showPublishedStatus}
						onOpen={props.onOpenGuide}
						loading={!collectionPath ? true : loading}
						// onClose={onCloseGuide}
					></GuidesList>
				</>
			)}

			{collectionChildren.collections.length ? (
				<>
					<h3 className="gigauser-guideslist-title-help">
						Collections
					</h3>

					<CollectionList
						collections={collectionChildren.collections}
						onOpen={onAppendCollection}
						loading={!collectionPath ? true : loading}
						showPrivate={props.showPrivate}
					/>
				</>
			) : null}
		</div>
	);
};
export default ContentNavigator;
