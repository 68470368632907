// DEPRECATED - USE WORKER
import { hexToRGBA } from "../../../../../utils/colorUtils";
import { ScreenshotRenderEdits } from "../../components/EditScreenshot/useEditScreenshot";
import "context-filter-polyfill";

const makeArrow: (
	context: CanvasRenderingContext2D,
	fromx: number,
	fromy: number,
	tox: number,
	toy: number,
	width?: number,
) => void = (context, fromx, fromy, tox, toy, width) => {
	width = width || 0;

	// const headlen = width ? 5 * width : 10;
	// const angle = Math.atan2(toy - fromy, tox - fromx);
	// Draw the lines for the arrowhead

	const alpha = Math.atan2(1, 2); //half-angle of the arrow head
	const theta = Math.atan2(toy - fromy, tox - fromx);
	const L = (2 * width) / Math.cos(alpha); //length of the arrow head
	const l = width / Math.cos(alpha);

	const x_t = tox - l * Math.cos(theta);
	const y_t = toy - l * Math.sin(theta);

	context.beginPath();
	context.moveTo(fromx, fromy);
	context.lineTo(x_t, y_t);

	context.moveTo(x_t, y_t);
	context.lineTo(
		x_t - L * Math.cos(theta + alpha),
		y_t - L * Math.sin(theta + alpha),
	);
	context.lineTo(x_t, y_t);
	context.lineTo(
		x_t - L * Math.cos(theta - alpha),
		y_t - L * Math.sin(theta - alpha),
	);
	context.lineTo(
		x_t - L * Math.cos(theta + alpha),
		y_t - L * Math.sin(theta + alpha),
	);
	context.lineTo(x_t, y_t);

	context.fill();
	context.stroke();
};

const getEditedScreenshot: (
	img: HTMLImageElement,
	edits: ScreenshotRenderEdits,
) => string = (img, edits) => {
	var { zoomFactor, zoomCenter, shapes } = edits;

	zoomFactor = zoomFactor || 1;
	zoomCenter = zoomCenter || { x: 0.5, y: 0.5 };

	const canv = document.createElement("canvas");

	canv.height = img.height / zoomFactor;
	canv.width = img.width / zoomFactor;

	const ctx = canv.getContext("2d");
	let cropHeight = img.height;
	let cropWidth = img.width;
	let cropX = 0;
	let cropY = 0;

	// add crop here
	if (edits.crop) {
		cropHeight = img.height * edits.crop.size[1];
		cropWidth = img.width * edits.crop.size[0];
		cropX = img.width * edits.crop.position[0];
		cropY = img.height * edits.crop.position[1];
		canv.height = cropHeight / zoomFactor;
		canv.width = cropWidth / zoomFactor;
	}

	const sx = Math.min(
		Math.max(
			cropWidth * zoomCenter.x - cropWidth / (zoomFactor * 2) + 0,
			0,
		),
		cropWidth - cropWidth / zoomFactor,
	);

	const sy = Math.min(
		Math.max(cropHeight * zoomCenter.y - cropHeight / (zoomFactor * 2), 0),
		cropHeight - cropHeight / zoomFactor,
	);

	ctx?.drawImage(
		img,
		sx + cropX,
		sy + cropY,
		cropWidth / zoomFactor,
		cropHeight / zoomFactor,
		0,
		0,
		cropWidth / zoomFactor,
		cropHeight / zoomFactor,
	);

	shapes?.forEach((s) => {
		if (!ctx) return;

		let shape = s;
		if ((shape.size[0] < 0 || shape.size[1] < 0) && shape.geo !== "arrow") {
			// logic to fix blurs with negative size, dont use on arrows (this will reverse the direction of the arrow)
			const isXNeg = shape.size[0] < 0;
			const isYNeg = shape.size[1] < 0;
			shape = {
				...shape,
				position: [
					isXNeg
						? shape.position[0] + shape.size[0]
						: shape.position[0],
					isYNeg
						? shape.position[1] + shape.size[1]
						: shape.position[1],
				],
				size: [Math.abs(shape.size[0]), Math.abs(shape.size[1])],
			};
		}

		try {
			var {
				position,
				size,
				stroke,
				fill,
				fillOpacity,
				strokeWidth,
				geo,
				version,
			} = shape;
			if (!position || !size) return;
			ctx?.beginPath();
			if (!version) {
				// Old stroke width: absolute value
				strokeWidth = (strokeWidth ?? 2) * zoomFactor;
			} else {
				strokeWidth =
					((strokeWidth ?? 0) * Math.max(img.width, img.height)) /
					1000;
			}
			const [x, y] = position;
			const [width, height] = size;
			var rectX = x * cropWidth - sx;
			var rectY = y * cropHeight - sy;
			var rectWidth = width * cropWidth;
			var rectHeight = height * cropHeight;

			if (geo == "arrow") {
			} else {
				rectX += strokeWidth / 2;
				rectY += strokeWidth / 2;
				rectWidth -= strokeWidth ? strokeWidth : 0;
				rectHeight -= strokeWidth ? strokeWidth : 0;
			}

			if (geo === "rectangle") {
				// Calculate the actual position and size based on the image dimensions and zoomFactor
				ctx?.rect(rectX, rectY, rectWidth, rectHeight);
				// ctx.rect(20, 20, 150, 100)
			} else if (geo == "blur") {
				// Step 2 & 3: Capture the area and apply blur
				const tempCanvas = document.createElement("canvas");
				const tempCtx = tempCanvas.getContext("2d");

				const blurRadius = 36;

				if (!tempCtx) return;

				// Set the dimensions of the temp canvas to the size of the rectangle
				tempCanvas.width = rectWidth + 2 * blurRadius;
				tempCanvas.height = rectHeight + 2 * blurRadius;

				// Draw the specific area of the original image onto the temp canvas
				tempCtx.drawImage(
					ctx.canvas,
					rectX - blurRadius,
					rectY - blurRadius,
					rectWidth + 2 * blurRadius,
					rectHeight + 2 * blurRadius,
					0,
					0,
					rectWidth + 2 * blurRadius,
					rectHeight + 2 * blurRadius,
				);

				// Apply the blur filter
				tempCtx.filter = `blur(${blurRadius / 3}px)`; // adjust the blur radius as needed
				tempCtx.drawImage(tempCanvas, 0, 0);

				// Step 4: Draw the blurred area back onto the original canvas
				ctx.drawImage(
					tempCanvas,
					blurRadius,
					blurRadius,
					rectWidth,
					rectHeight,
					rectX,
					rectY,
					rectWidth,
					rectHeight,
				);
			} else if (geo === "circle") {
				ctx.strokeStyle = stroke || "transparent";
				const centerX = rectX + rectWidth / 2;
				const centerY = rectY + rectHeight / 2;
				const radius = rectHeight / 2;
				ctx.arc(centerX, centerY, radius, 0, Math.PI * 2);
			} else if (geo === "arrow") {
				// const x_from = x_center - (rectWidth / 2) * Math.cos(angle);
				// const y_from = y_center - (rectWidth / 2) * Math.sin(angle);

				const x_from = rectX;
				const y_from = rectY;

				// const x_to = x_center + (rectWidth / 2) * Math.cos(angle);
				// const y_to = y_center + (rectWidth / 2) * Math.sin(angle);
				const x_to = rectX + rectWidth;
				const y_to = rectY + rectHeight;

				// ctx?.strokeRect(rectX, rectY, rectWidth, rectHeight);

				ctx.fillStyle = stroke || "transparent";
				ctx.strokeStyle = "transparent";

				makeArrow(ctx, x_from, y_from, x_to, y_to, strokeWidth);
			}

			// Apply fill style if 'fill' property is provided
			if (fill) {
				ctx.fillStyle = hexToRGBA(fill, fillOpacity);
				ctx.fill();
			}

			// Apply stroke style if 'stroke' property is provided
			if (stroke) {
				ctx.strokeStyle = stroke || "transparent";
				if (strokeWidth !== undefined) {
					ctx.lineWidth = strokeWidth;
				}
				ctx.stroke();
			}

			ctx.closePath();
		} catch (e) {
			console.error("Error processing shape", e, shape);
		}
	});

	const zoomedInSource = canv.toDataURL("image/png");
	return zoomedInSource;
};

export default getEditedScreenshot;
