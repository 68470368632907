// logger.ts

export type LogLevelStrings =
	| "error"
	| "warn"
	| "info"
	| "verbose"
	| "debug"
	| "silent";

interface LogLevels {
	[level: string]: number;
}

class Logger {
	private levels: LogLevels = {
		silent: -1,
		error: 0,
		warn: 1,
		info: 2,
		verbose: 3,
		debug: 4,
	};

	private currentLevel: number = this.levels.info;

	public setLevel(level: LogLevelStrings): void {
		if (this.levels.hasOwnProperty(level)) {
			this.currentLevel = this.levels[level];
		} else {
			throw new Error(`Unknown log level: ${level}`);
		}
	}

	public error(...args: any[]): void {
		if (this.currentLevel >= this.levels.error) {
			console.error(...args);
		}
	}

	public warn(...args: any[]): void {
		if (this.currentLevel >= this.levels.warn) {
			console.warn(...args);
		}
	}

	public info(...args: any[]): void {
		if (this.currentLevel >= this.levels.info) {
			console.info(...args);
		}
	}

	public verbose(...args: any[]): void {
		if (this.currentLevel >= this.levels.verbose) {
			console.log(...args);
		}
	}

	public debug(...args: any[]): void {
		if (this.currentLevel >= this.levels.debug) {
			console.log(...args);
		}
	}
}

export default new Logger();
