const f = (color = "#000000") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 64 64"
			xmlns="http://www.w3.org/2000/svg"
			aria-hidden="true"
			role="img"
			preserveAspectRatio="xMidYMid meet"
		>
			<path
				d="M32 62c16.6 0 30-13.4 30-30H2c0 16.6 13.4 30 30 30z"
				fill="#ffe62e"
			></path>
			<path
				d="M32 2C15.4 2 2 15.4 2 32h60C62 15.4 48.6 2 32 2z"
				fill="#428bc1"
			></path>
		</svg>
	);
};
export default f;
