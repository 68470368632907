import { Node } from "@remirror/pm/dist-types/model";
import { Selection } from "remirror";

export function getImagesAroundPosition(
	pos: number,
	doc: Node,
): { start: number; end: number } {
	let start = -1;
	let end = 10000000000000;

	// Function to check if a node is an image
	function isImageNode(node: Node) {
		return node.type.name === "image";
	}

	// Scan backward
	doc.nodesBetween(0, pos, (node, position) => {
		if (isImageNode(node)) {
			if (node.attrs.metadata.timestamp) {
				start = node.attrs.metadata.timestamp;
			}
			// We stop the iteration as soon as we find the latest image before the given position
		}
	});

	// Scan forward
	doc.nodesBetween(pos, doc.content.size, (node, position) => {
		if (isImageNode(node) && position > pos && end === 10000000000000) {
			if (node.attrs.metadata.timestamp) {
				end = node.attrs.metadata.timestamp;
			}
			// We stop the iteration as soon as we find the earliest image after the given position
		}
	});

	return {
		start,
		end,
	};
}
