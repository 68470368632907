import { createRoutesFromElements, Route } from "react-router-dom";
import React from "react";
import { Provider } from "react-redux";
import App from "./App";

const HardRedirect: React.FC = () => {
	const searchParams = window.location.search;
	window.location.replace("/folders/Collection_root/" + searchParams);
	return <></>;
};

const routes = createRoutesFromElements(
	<>
		<Route path="/folders/:objectID" element={<App page="Browser"></App>} />

		<Route path="/guide/*" element={<App page="Browser" />} />

		<Route path="/embed/*" element={<App page="Embed" />} />

		<Route path="/share/*" element={<App page="Unlisted" />} />

		{/* <Route path="/" element={
                <App page="Home" />
            }>

        </Route> */}

		<Route path="*" element={<HardRedirect />} />
	</>,
);

export { routes };
export function CatchBoundary() {}
