const editorUnderline = (color = "#000000") => {
	return (
		<svg viewBox="0 0 1024 1024" version="1.1">
			<path
				fill={color}
				d="M232 872h560v-80H232v80m280-160c132.4 0 240-107.6 240-240V152H652v320c0 77.2-62.8 140-140 140s-140-62.8-140-140V152H272v320c0 132.4 107.6 240 240 240z"
			/>
		</svg>
	);
};

export default editorUnderline;
