export type OS = "Windows" | "MacOS" | "Linux OS" | "UNIX OS" | null;

export const getOperatingSystem: () => OS = () => {
	if (window.navigator.userAgent.indexOf("Win") !== -1) return "Windows";
	if (window.navigator.userAgent.indexOf("Mac") !== -1) return "MacOS";
	if (window.navigator.userAgent.indexOf("Linux") !== -1) return "Linux OS";
	if (window.navigator.userAgent.indexOf("X11") !== -1) return "UNIX OS";
	return null;
};

export function timeAgoFromString(dateString: string): string {
	// Convert the Zulu time string to a Date object
	const date = new Date(dateString);
	const now = new Date();

	// Calculate the difference in seconds
	const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

	const intervals: { [key: string]: number } = {
		year: 31536000,
		month: 2592000,
		week: 604800,
		day: 86400,
		hour: 3600,
		minute: 60,
		second: 1,
	};

	// Iterate over the intervals and find the appropriate time difference
	for (const key in intervals) {
		const interval = Math.floor(seconds / intervals[key]);
		if (interval >= 1) {
			return `${interval} ${key}${interval > 1 ? "s" : ""} ago`;
		}
	}

	return "just now";
}

export function timeAgoFromDate(date: Date): string {
	const now = new Date();
	const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

	const intervals: { [key: string]: number } = {
		year: 31536000,
		month: 2592000,
		week: 604800,
		day: 86400,
		hour: 3600,
		minute: 60,
		second: 1,
	};

	for (const key in intervals) {
		const interval = Math.floor(seconds / intervals[key]);
		if (interval >= 1) {
			return `${interval} ${key}${interval > 1 ? "s" : ""} ago`;
		}
	}

	return "just now";
}

export function timeRemainingToDate(date: Date): string {
	const now = new Date();
	const seconds = Math.floor((date.getTime() - now.getTime()) / 1000);

	const intervals: { [key: string]: number } = {
		year: 31536000,
		month: 2592000,
		week: 604800,
		day: 86400,
		hour: 3600,
		minute: 60,
		second: 1,
	};

	for (const key in intervals) {
		const interval = Math.floor(seconds / intervals[key]);
		if (interval >= 1) {
			return `${interval} ${key}${interval > 1 ? "s" : ""}`;
		}
	}

	return "just now";
}

export function formatDateTime(datetimeStr: string): string {
	// Strip everything after the plus (including the plus) from the string
	// This is used for the old format of createdAt in the guides.
	const strippedDatetimeStr = datetimeStr.replace(/\+.*/, "");

	// Parse the string as UTC
	const utcDate = new Date(strippedDatetimeStr + "Z"); // Adding 'Z' to indicate it's UTC

	// Convert UTC date to local time zone
	const localDate = new Date(
		utcDate.toLocaleString("en-US", {
			timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
		}),
	);

	// Format the hours and minutes
	let hours = localDate.getHours();
	const minutes = ("0" + localDate.getMinutes()).slice(-2);
	const ampm = hours >= 12 ? "pm" : "am";
	hours = hours % 12;
	hours = hours ? hours : 12; // The hour '0' should be '12'

	// Format the day and month
	const day = localDate.getDate();
	const months = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];
	const monthName = months[localDate.getMonth()];

	// Format the year
	const year = localDate.getFullYear();

	// Combine all parts into the final string
	return `${hours}:${minutes}${ampm} on ${day} ${monthName} ${year}`;
}

function sanitizeFilename(input: string): string {
	// Define a regular expression that matches disallowed characters
	const disallowedChars = /[<>:"/\\|?*]+/g;

	// Replace disallowed characters with an empty string
	return input.replace(disallowedChars, "");
}

function getFileFormatFromPresignedUrl(url: string): string {
	// Find the position of the last '.' and the '?'
	const lastDotIndex = url.lastIndexOf(".");
	const questionMarkIndex = url.indexOf("?");

	// Extract the substring between these two positions
	if (
		lastDotIndex > -1 &&
		questionMarkIndex > -1 &&
		lastDotIndex < questionMarkIndex
	) {
		return url.substring(lastDotIndex + 1, questionMarkIndex);
	}

	// Return an empty string if the format cannot be determined
	return "";
}

export async function downloadFromCloudFrontWithProgress(
	url: string,
	filename: string,
	onProgressCallback?: (progress: number) => void,
) {
	// Fetch the content of the file
	const response = await fetch(url);
	const contentLength = Number(response.headers.get("content-length"));
	let receivedLength = 0;

	if (!response.body) return;

	const reader = response.body.getReader();
	const chunks: Uint8Array[] = [];

	while (true) {
		const { done, value } = await reader.read();

		if (done) {
			break;
		}

		chunks.push(value);
		receivedLength += value.length;

		// Calculate progress
		const progress = receivedLength / contentLength;

		// Call the progress callback if provided
		if (onProgressCallback) {
			onProgressCallback(progress);
		}
	}

	// Combine the chunks into a single Uint8Array
	const uint8Array = new Uint8Array(receivedLength);
	let position = 0;
	for (const chunk of chunks) {
		uint8Array.set(chunk, position);
		position += chunk.length;
	}

	// Create a blob from the Uint8Array
	const blob = new Blob([uint8Array]);

	// Create a blob URL
	const blobURL = window.URL.createObjectURL(blob);

	const a = document.createElement("a");
	a.href = blobURL;

	console.log("blobUrl :", blobURL);

	// Optionally set a filename. If you don't provide this, a default name is used.
	if (filename) {
		filename = sanitizeFilename(filename);
	} else {
		const urlSections = url.split("/");
		filename = sanitizeFilename(urlSections[urlSections.length - 1]); // Use the last section of the URL as the file name
	}

	const format = getFileFormatFromPresignedUrl(url);
	a.download = filename + "." + format;

	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);

	// Clean up the blob URL
	window.URL.revokeObjectURL(blobURL);
}

export async function downloadFromCloudFront(url: string, filename: string) {
	// Fetch the content of the file
	const response = await fetch(url);
	const blob = await response.blob();

	// Create a blob URL
	const blobURL = window.URL.createObjectURL(blob);

	const a = document.createElement("a");
	a.href = blobURL;

	console.log("blobUrl :", blobURL);

	// Optionally set a filename. If you don't provide this, a default name is used.
	if (filename) {
		filename = sanitizeFilename(filename);
	} else {
		const urlSections = url.split("/");
		filename = sanitizeFilename(urlSections[urlSections.length - 1]); // Use the last section of the URL as the file name
	}

	const format = getFileFormatFromPresignedUrl(url);
	a.download = filename + "." + format;

	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);

	// Clean up the blob URL
	window.URL.revokeObjectURL(blobURL);
}
