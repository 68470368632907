const f = (color = "#000000") => {
	return (
		<svg
			height="100%"
			width="100%"
			version="1.1"
			id="Layer_1"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
		>
			<circle fill="#F0F0F0" cx="256" cy="256" r="256" />
			<circle fill="#D80027" cx="256" cy="256" r="111.304" />
		</svg>
	);
};
export default f;
