const downArrowheadIcon = (color: string = "currentColor") => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill={color || "#000000"}
			width="100%"
			height="100%"
			viewBox="0 0 1024 1024"
		>
			<path d="M759.2 419.8L697.4 358 512 543.4 326.6 358l-61.8 61.8L512 667z" />
		</svg>
	);
};

export default downArrowheadIcon;
