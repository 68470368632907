const f = (color = "#000000") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 800 800"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M651.25 125C583.75 62.5 495 25 396.25 25C297.5 25 208.75 62.5 141.25 125H651.25Z"
				fill="#F9F9F9"
			/>
			<path
				d="M141.25 675C208.75 737.5 297.5 775 396.25 775C495 775 583.75 737.5 651.25 675H141.25Z"
				fill="#F9F9F9"
			/>
			<path
				d="M21.25 400C21.25 453.75 32.5 503.75 52.5 550H740C760 503.75 771.25 453.75 771.25 400C771.25 346.25 760 296.25 740 250H52.5C32.5 296.25 21.25 346.25 21.25 400Z"
				fill="#F9F9F9"
			/>
			<path
				d="M142.5 675H651.25C688.75 640 720 597.5 740 550H52.5C73.75 597.5 105 640 142.5 675Z"
				fill="#428BC1"
			/>
			<path
				d="M651.25 125H142.5C105 160 73.75 202.5 53.75 250H741.25C720 202.5 688.75 160 651.25 125Z"
				fill="#428BC1"
			/>
			<path
				d="M508.75 337.5H433.75L396.25 275L358.75 337.5H283.75L321.25 400L283.75 462.5H358.75L396.25 525L433.75 462.5H508.75L471.25 400L508.75 337.5ZM396.25 311.25L412.5 337.5H381.25L396.25 311.25ZM317.5 356.25H348.75L333.75 382.5L317.5 356.25ZM317.5 443.75L332.5 417.5L347.5 443.75H317.5ZM396.25 488.75L381.25 462.5H412.5L396.25 488.75ZM423.75 443.75H371.25L343.75 400L370 356.25H422.5L450 400L423.75 443.75ZM476.25 443.75H445L460 417.5L476.25 443.75ZM461.25 381.25L446.25 355H477.5L461.25 381.25Z"
				fill="#428BC1"
			/>
		</svg>
	);
};
export default f;
