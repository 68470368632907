export const getModifiedWidthAndHeight = (
	desiredWidth: number,
	desiredHeight: number,
	maxWidth: number,
	maxHeight: number,
) => {
	const widthRatio = maxWidth / desiredWidth;
	const heightRatio = maxHeight / desiredHeight;
	const ratio = Math.min(widthRatio, heightRatio);
	return {
		width: desiredWidth * ratio,
		height: desiredHeight * ratio,
	};
};
