import React, { useState } from "react";

import { Collection } from "@gigauser/common/src/core/types/collections";
import homeIcon from "../../../assets/svgs/home";
import { getBrandingColor } from "../../../core/utils/styleUtils";
import { rootCollection } from "../../../types/files";

type BreadcrumbSectionProps = {
	segment: Collection;
	navigationHandler?: (segment: Collection) => void | null;
	activeSegment?: boolean; //if its the last section,
	appTheme?: "gigauser-light" | "gigauser-dark";
};

const BreadcrumbSection: React.FC<BreadcrumbSectionProps> = (props) => {
	const brandColor = getBrandingColor(props.appTheme);
	const lightBrandColor = getBrandingColor(props.appTheme, true);
	const defaultHomeColor = props.activeSegment ? brandColor : "#c2c1c1";

	const [homeColor, setHomeColor] = useState(defaultHomeColor);

	const onHomeHover = () => {
		// setHomeColor(brandColor)
	};

	const onHomeLeave = () => {
		setHomeColor(defaultHomeColor);
	};

	const onNavigate = () => {
		if (props.navigationHandler) props.navigationHandler(props.segment);
	};

	return (
		<div
			className={`gigauser-PageTitle-pathSegment 
        ${props.navigationHandler ? "navigable" : "nonnavigable"}
        ${props.activeSegment ? "gigauser-activeSegment" : ""} ${props.segment.id === rootCollection.id ? "gigauser-home-segment" : "gigauser-text-segment"}`}
			onClick={onNavigate}
			onMouseEnter={onHomeHover}
			onMouseLeave={onHomeLeave}
		>
			{!props.navigationHandler && (
				<div
					className={`gigauser-breadcrumbs-statuslight ${props.segment.private ? "private" : "public"}`}
				>
					.
				</div>
			)}

			{props.segment.id === rootCollection.id ? (
				<div
					className="gigauser-breadcrumbs-home"
					onMouseEnter={onHomeHover}
					onMouseLeave={onHomeLeave}
				>
					{homeIcon(
						props.activeSegment
							? brandColor
							: props.appTheme === "gigauser-light"
								? brandColor
								: "#c2c1c1",
					)}
				</div>
			) : (
				props.segment.name
			)}
		</div>
	);
};
export default BreadcrumbSection;
