const f = (color = "#000000") => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="0 0 64 64"
			aria-hidden="true"
			role="img"
			preserveAspectRatio="xMidYMid meet"
		>
			<circle cx="32" cy="32" r="30" fill="#f5f5f5"></circle>

			<path
				d="M23.4 33.7c2.8 1.9 6.7 1.1 8.6-1.7c1.9-2.8 5.7-3.6 8.6-1.7c2.7 1.8 3.5 5.3 2 8c3.3-5.6 1.8-12.9-3.8-16.6c-5.7-3.8-13.4-2.3-17.2 3.4c-.1.2-.2.4-.3.5c-1.4 2.9-.5 6.3 2.1 8.1"
				fill="#ed4c5c"
			></path>

			<path
				d="M42.3 38.9c.1-.2.2-.4.3-.5c-.1.1-.2.3-.3.5"
				fill="#003478"
			></path>

			<path
				d="M40.6 30.3c-2.8-1.9-6.7-1.1-8.6 1.7c-1.9 2.8-5.7 3.6-8.6 1.7c-2.7-1.8-3.5-5.3-2-8c-3.4 5.6-1.8 12.9 3.8 16.6c5.7 3.8 13.4 2.3 17.2-3.4c.1-.2.2-.4.3-.5c1.4-2.9.5-6.3-2.1-8.1"
				fill="#428bc1"
			></path>

			<g fill="#3e4347">
				<path d="M7.8 23.3L14.7 13l-.7-.5l-.7-.4l-6.9 10.3l.7.4z"></path>

				<path d="M9.7 24.6l.7.4l6.8-10.3l-.6-.4l-.7-.5L9 24.1z"></path>

				<path d="M18.4 15.5l-6.8 10.3l.7.5l.7.4l6.8-10.3l-.7-.4z"></path>

				<path d="M48.5 42.5l.7.5l3.2-4.8l-.7-.5l-.7-.4l-3.2 4.8z"></path>

				<path d="M56.2 40.7L53 45.5l.7.5l.7.4l3.2-4.8l-.7-.4z"></path>

				<path d="M51.8 44.7l3.2-4.8l-.7-.5l-.7-.4l-3.2 4.8l.7.4z"></path>

				<path d="M48.1 43.2l-.7-.4l-3.2 4.8l.7.4l.7.5l3.2-4.8z"></path>

				<path d="M50.6 44.9l-.6-.4l-3.2 4.8l.6.4l.7.5l3.2-4.8z"></path>

				<path d="M52.5 46.2L49.3 51l.7.5l.7.4l3.2-4.8l-.7-.5z"></path>

				<path d="M7.1 41.2l-.7.4l6.9 10.3l.7-.4l.7-.5l-6.9-10.3z"></path>

				<path d="M13.4 44.9l-.7.5l3.2 4.8l.7-.5l.6-.4l-3.2-4.8z"></path>

				<path d="M12.3 37.7l-.7.5l6.8 10.3l.7-.5l.7-.4L13 37.3z"></path>

				<path d="M9 39.9l3.2 4.8l.7-.5l.7-.4l-3.2-4.8l-.7.4z"></path>

				<path d="M53.2 17.4l.7-.5l-3.2-4.8l-.7.4l-.7.5l3.2 4.8z"></path>

				<path d="M48.1 20.8l.7-.5l-3.2-4.8l-.7.5l-.7.4l3.2 4.8z"></path>

				<path d="M53 18.5l3.2 4.8l.7-.5l.7-.4l-3.2-4.8l-.7.4z"></path>

				<path d="M54.3 24.6l.7-.5l-6.9-10.3l-.7.5l-.6.4L53.6 25z"></path>

				<path d="M51.7 26.3l.7-.5l-3.2-4.8l-.7.5l-.7.4l3.2 4.8z"></path>
			</g>
		</svg>
	);
};
export default f;
