const editorItalic = (color = "#000000") => {
	return (
		<svg viewBox="0 0 1024 1024" version="1.1">
			<path
				fill={color}
				d="M426.667 170.667v128h94.293L375.04 640H256v128h341.333V640H503.04l145.92-341.333H768v-128H426.667z"
			/>
		</svg>
	);
};

export default editorItalic;
