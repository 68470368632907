const f = (color = "#000000") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 800 800"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M700 175H400V250H743.75C732.5 223.75 717.5 197.5 700 175Z"
				fill="#428BC1"
			/>
			<path
				d="M400 175H700C678.75 146.25 653.75 121.25 625 100H400V175Z"
				fill="#F9F9F9"
			/>
			<path
				d="M743.75 250H400V325H767.5C762.5 298.75 753.75 273.75 743.75 250Z"
				fill="#F9F9F9"
			/>
			<path
				d="M56.25 550H743.75C753.75 526.25 762.5 501.25 767.5 475H32.5C37.5 501.25 46.25 526.25 56.25 550Z"
				fill="#428BC1"
			/>
			<path
				d="M100 625H700C717.5 602.5 732.5 576.25 743.75 550H56.25C67.5 576.25 82.5 602.5 100 625Z"
				fill="#F9F9F9"
			/>
			<path
				d="M100 625C121.25 653.75 146.25 678.75 175 700H625C653.75 678.75 678.75 653.75 700 625H100Z"
				fill="#428BC1"
			/>
			<path
				d="M175 700C237.5 747.5 315 775 400 775C485 775 562.5 747.5 625 700H175Z"
				fill="#F9F9F9"
			/>
			<path
				d="M250 400V250H400V175H250V56.25C223.75 67.5 197.5 82.5 175 100V175H100C82.5 197.5 67.5 223.75 56.25 250H175V400H25C25 426.25 27.5 451.25 32.5 475H767.5C772.5 451.25 775 426.25 775 400H250Z"
				fill="#F9F9F9"
			/>
			<path
				d="M767.5 325H400V250H250V400H775C775 373.75 772.5 348.75 767.5 325Z"
				fill="#428BC1"
			/>
			<path
				d="M400 25C346.25 25 296.25 36.25 250 56.25V175H400V100H625C562.5 52.5 485 25 400 25Z"
				fill="#428BC1"
			/>
			<path
				d="M175 175V100C146.25 121.25 121.25 146.25 100 175H175Z"
				fill="#428BC1"
			/>
			<path
				d="M56.25 250C36.25 296.25 25 346.25 25 400H175V250H56.25Z"
				fill="#428BC1"
			/>
		</svg>
	);
};
export default f;
