const flag = (color = "#000000") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 202 202"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="mask0_1073_23"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="202"
				height="202"
			>
				<circle cx="101" cy="101" r="101" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_1073_23)">
				<path d="M251 0H-49V200H251V0Z" fill="#CE1126" />
				<path d="M251 100H-49V200H251V100Z" fill="white" />
			</g>
		</svg>
	);
};

export default flag;
