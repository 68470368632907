import { configureStore } from "@reduxjs/toolkit";
import hcSlice from "./slices/hcSlice";
import hcUiSlice from "./slices/hcUiSlice";

const store = configureStore({
	middleware: (getDefaultMiddleware) => {
		return getDefaultMiddleware({
			serializableCheck: false,
		});
	},
	reducer: {
		hc: hcSlice,
		hcUi: hcUiSlice,
	},
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
