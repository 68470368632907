import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "@gigauser/common/src/redux/store";
import { Item, rootCollection } from "@gigauser/common/src/types/files";
import { GuidePreview } from "@gigauser/common/src/core/types/guide";
import { Collection } from "@gigauser/common/src/core/types/collections";
import { GigaUserApi } from "@giga-user-fern/api";
import logger from "../../utils/logger";

// Define a type for the slice state
export type CurrentCollectionData = {
	currentCollection: Collection; //The collection currently open on the Files section of the home screen.
	collectionsData: Collection[];
	guidesData: GuidePreview[];
	loading?: Boolean;
};

export type CollectionSection = {
	collection: Collection;
	collapsed: Boolean;
	children: CollectionSection[];
	loaded: Boolean;
};

interface CollectionState {
	exploreCollectionsData: CollectionSection[];
	currentCollectionData: CurrentCollectionData;
	allDeletedGuides: GuidePreview[];
}

type ReorderGuidesFrontendPayload = {
	succeedingGuide: GuidePreview;
	precedingGuide?: GuidePreview;
};

type ReorderCollectionsFrontendPayload = {
	succeedingCollection: Collection;
	precedingCollection?: Collection;
};

// Define the initial state using that type
const initialState: CollectionState = {
	currentCollectionData: {
		currentCollection: rootCollection,
		collectionsData: [],
		guidesData: [],
		loading: true,
	},
	exploreCollectionsData: [
		{
			collection: rootCollection,
			collapsed: true,
			children: [],
			loaded: false,
		},
		// {
		//     folder: sharedFiles,
		//     collapsed: true,
		//     children: [],
		//     loaded: false
		// }
	],
	allDeletedGuides: [],
};

// Both web an
// Both web and electron will have to dispatch an initial update
// To load an initial path

export const collectionDataSlice = createSlice({
	name: "collectionData",
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		setCurrentCollectionData: (
			state,
			action: PayloadAction<CurrentCollectionData>,
		) => {
			state.currentCollectionData.currentCollection =
				action.payload.currentCollection;
			state.currentCollectionData.collectionsData =
				action.payload.collectionsData;
			state.currentCollectionData.guidesData = action.payload.guidesData;
			// logger.debug("SETTING DATA")
			state.currentCollectionData.loading = false;
		},

		updateExploreCollectionData: (
			state,
			action: PayloadAction<CollectionSection>,
		) => {
			// Update a folder section in the explore folder data
			const collection = action.payload.collection;
			// Loop through the exploreFoldersData, until this folder is found.
			const exploreNode = (currCollection: CollectionSection) => {
				if (currCollection.collection.id === collection.id) {
					currCollection = action.payload;
					return currCollection;
				} else {
					currCollection.children =
						currCollection.children.map(exploreNode);
					return currCollection;
				}
			};
			const copy = [...state.exploreCollectionsData];
			state.exploreCollectionsData = copy.map(exploreNode);
		},

		loadingCurrentCollectionData: (state) => {
			// logger.debug("SETTING LOADING")
			state.currentCollectionData.collectionsData = [];
			state.currentCollectionData.guidesData = [];
			state.currentCollectionData.loading = true;
		},

		createCollectionFrontend: (
			state,
			action: PayloadAction<Collection>,
		) => {
			// TODO: Don't add here if parentID does not match!

			const collection = action.payload;

			logger.debug("createCollection frontend: ", collection);

			const currentParentId =
				state.currentCollectionData.currentCollection.id;

			logger.debug(
				"currentParentId: ",
				currentParentId,
				collection.parentId,
			);

			if (
				collection.parentId == currentParentId ||
				(!collection.parentId && currentParentId == "Collection_root")
			) {
				state.currentCollectionData.collectionsData.push(collection);
				logger.debug("need to push!");
			}

			// Loop through the exploreFoldersData, until this folder is found.
			const exploreNode = (currCollection: CollectionSection) => {
				if (currCollection.collection.id == collection.parentId) {
					currCollection.children.push({
						collection: collection,
						children: [],
						collapsed: true,
						loaded: false,
					});
					return currCollection;
				} else {
					currCollection.children =
						currCollection.children.map(exploreNode);
					return currCollection;
				}
			};

			const copy = [...state.exploreCollectionsData];
			state.exploreCollectionsData = copy.map(exploreNode);
		},

		editItemFrontend: (state, action: PayloadAction<Item>) => {
			const file = action.payload;
			const fileType = file.type;

			const editMapFunction = (x: Collection | GuidePreview) => {
				if (x.id === file.entry.id) {
					return {
						...x,
						...action.payload.entry,
					};
				} else {
					return x;
				}
			};

			if (fileType === "Collection") {
				state.currentCollectionData.collectionsData =
					state.currentCollectionData.collectionsData.map(
						editMapFunction,
					) as Collection[];

				//TODO: Handle the explore panel here.
			} else if (fileType === "Guide") {
				state.currentCollectionData.guidesData =
					state.currentCollectionData.guidesData.map(
						editMapFunction,
					) as GuidePreview[];
			}
		},

		setPinnedGuide: (
			state,
			action: PayloadAction<{
				isPinned: boolean;
				guidePreview: GuidePreview;
			}>,
		) => {
			const { isPinned, guidePreview } = action.payload;

			const editMapFunction = (x: GuidePreview) => {
				const guidePreviewCopy = { ...x };

				if (x.id === guidePreview.id) {
					guidePreviewCopy.isPinned = isPinned;
				} else {
					guidePreviewCopy.isPinned = false;
				}
				return guidePreviewCopy;
			};

			state.currentCollectionData.guidesData =
				state.currentCollectionData.guidesData.map(
					editMapFunction,
				) as GuidePreview[];
		},

		addGuideToCollection: (state, action: PayloadAction<GuidePreview>) => {
			const guide = action.payload;
			state.currentCollectionData.guidesData =
				state.currentCollectionData.guidesData
					.concat([guide])
					.sort((a, b) => {
						return (
							(a.sequenceNumber || 0) - (b.sequenceNumber || 0)
						);
					});
		},

		moveItemFrontend: (
			state,
			action: PayloadAction<{ item: Item; newParentID: GigaUserApi.Id }>,
		) => {
			const item = action.payload.item;
			const newParentID = action.payload.newParentID;
			const itemType = item.type;

			const moveMapFunction = (i: GuidePreview | Collection) => {
				if (i.id === item.entry.id) {
					return false;
				} else {
					return true;
				}
			};

			if (itemType === "Collection") {
				state.currentCollectionData.collectionsData =
					state.currentCollectionData.collectionsData.filter(
						moveMapFunction,
					);

				// const exploreNode = (currFolder :FolderSection)=>{
				//     if (currFolder.folder.objectID === file.parentID){
				//         currFolder.children = currFolder.children.filter((x)=>x.folder.objectID !== file.objectID)
				//     }
				//     if (currFolder.folder.objectID === newParent){
				//         currFolder.children.push({folder: file, children: [], collapsed: true, loaded: false})
				//     }
				//     currFolder.children = currFolder.children.map(exploreNode)
				//     return currFolder

				// }
				// const copy = [...state.exploreFoldersData]
				// state.exploreFoldersData = copy.map(exploreNode)
			} else if (itemType === "Guide") {
				state.currentCollectionData.guidesData =
					state.currentCollectionData.guidesData.filter(
						moveMapFunction,
					) as GuidePreview[];
			}
		},

		deleteCollectionFrontend: (
			state,
			action: PayloadAction<Collection>,
		) => {
			//this permanently deletes the collection and everything inside
			const deletedCollection = action.payload;
			const deleteMapFunction = (x: Collection) =>
				x.id !== deletedCollection.id;

			state.currentCollectionData.collectionsData =
				state.currentCollectionData.collectionsData.filter(
					deleteMapFunction,
				);
		},

		deleteGuideFrontend: (state, action: PayloadAction<GuidePreview>) => {
			//This moves the deleted guide to bin.
			const deletedGuide = action.payload;
			const deleteMapFunction = (x: GuidePreview) =>
				x.id !== deletedGuide.id;

			state.currentCollectionData.guidesData =
				state.currentCollectionData.guidesData.filter(
					deleteMapFunction,
				);
			state.allDeletedGuides =
				state.allDeletedGuides.concat(deletedGuide);
		},

		setDeletedGuidesFrontend: (
			state,
			action: PayloadAction<GuidePreview[]>,
		) => {
			state.allDeletedGuides = action.payload;
		},

		reorderGuidesFrontend: (
			state,
			action: PayloadAction<ReorderGuidesFrontendPayload>,
		) => {
			const { succeedingGuide, precedingGuide } = action.payload;

			const guidesArray = [...state.currentCollectionData.guidesData];

			const succeedingIndex = guidesArray.findIndex(
				(guide) => guide.id === succeedingGuide.id,
			);

			// Remove the succeedingCollection from its original place
			const [succeedingGuideRemoved] = guidesArray.splice(
				succeedingIndex,
				1,
			);

			// If precedingCollection is not provided, move succeedingCollection to the top
			if (!precedingGuide) {
				guidesArray.unshift(succeedingGuideRemoved);
			} else {
				// Find the index of the preceding collection
				const precedingIndex = guidesArray.findIndex(
					(guide) => guide.id === precedingGuide.id,
				);
				// Insert the succeedingCollection after the precedingCollection
				guidesArray.splice(
					precedingIndex + 1,
					0,
					succeedingGuideRemoved,
				);
			}

			state.currentCollectionData.guidesData = guidesArray;
		},

		reorderCollectionsFrontend: (
			state,
			action: PayloadAction<ReorderCollectionsFrontendPayload>,
		) => {
			const { succeedingCollection, precedingCollection } =
				action.payload;

			const collectionsArray = [
				...state.currentCollectionData.collectionsData,
			];

			const succeedingIndex = collectionsArray.findIndex(
				(collection) => collection.id === succeedingCollection.id,
			);

			// Remove the succeedingCollection from its original place
			const [succeedingCollectionRemoved] = collectionsArray.splice(
				succeedingIndex,
				1,
			);

			// If precedingCollection is not provided, move succeedingCollection to the top
			if (!precedingCollection) {
				collectionsArray.unshift(succeedingCollectionRemoved);
			} else {
				// Find the index of the preceding collection
				const precedingIndex = collectionsArray.findIndex(
					(collection) => collection.id === precedingCollection.id,
				);
				// Insert the succeedingCollection after the precedingCollection
				collectionsArray.splice(
					precedingIndex + 1,
					0,
					succeedingCollectionRemoved,
				);
			}

			state.currentCollectionData.collectionsData = collectionsArray;
		},
	},
});

export const {
	setCurrentCollectionData,
	setPinnedGuide,
	setDeletedGuidesFrontend,
	loadingCurrentCollectionData,
	createCollectionFrontend,
	deleteCollectionFrontend,
	deleteGuideFrontend,
	editItemFrontend,
	moveItemFrontend,
	updateExploreCollectionData,
	reorderGuidesFrontend,
	reorderCollectionsFrontend,
	addGuideToCollection,
} = collectionDataSlice.actions;
// Other code such as selectors can use the imported `RootState` type
export const selectCurrentCollectionData = (state: RootState) =>
	state.collectionData.currentCollectionData;
export const selectExploreCollectionData = (state: RootState) =>
	state.collectionData.exploreCollectionsData;
export default collectionDataSlice.reducer;
