const rectangleIcon = (color = "#ffffff") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 28 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M24 0.666992H3.99999C3.11684 0.66963 2.27061 1.02163 1.64612 1.64612C1.02163 2.27061 0.66963 3.11684 0.666992 3.99999V24C0.666992 25.833 2.16699 27.333 3.99999 27.333H24C25.833 27.333 27.333 25.833 27.333 24V3.99999C27.333 2.16699 25.833 0.666992 24 0.666992ZM24 24H3.99999V3.99999H24V24Z"
				fill={color}
			/>
		</svg>
	);
};

export default rectangleIcon;
