import React, { useEffect, useRef } from "react";
import "./SizeController.css";
import { Shape, UpdateShapeFunction } from "../../ScreenshotEditorContext";
import { Coordinates } from "@giga-user-fern/api/types/api";

export type SizeControllerPos =
	| "tl"
	| "t"
	| "tr"
	| "r"
	| "br"
	| "b"
	| "bl"
	| "l"
	| "a";

export type SizeControllerThumbProps = {
	shape: Shape;
	position: SizeControllerPos;

	resizeControls?: boolean;
	zoomFactor?: number;
	getRelativeCoords: (pos: Coordinates) => Coordinates;
	updateShape: UpdateShapeFunction;

	boundLimits?: boolean;
	lockRatio?: boolean;
};

const SizeControllerThumb: React.FC<SizeControllerThumbProps> = ({
	zoomFactor = 1,
	...props
}) => {
	const isDragging = useRef<boolean>(false);

	const startX = useRef(0);
	const startY = useRef(0);

	const onMouseDown = (e: React.MouseEvent) => {
		isDragging.current = true;
		startX.current = e.clientX;
		startY.current = e.clientY;

		initListeners();
	};

	const onMouseMove = (e: MouseEvent) => {
		if (!isDragging.current) return;

		const x_0 = props.shape.position[0];
		const y_0 = props.shape.position[1];

		const w_0 = props.shape.size[0];
		const h_0 = props.shape.size[1];

		var dx = (e.clientX - startX.current) / zoomFactor;
		var dy = (e.clientY - startY.current) / zoomFactor;

		var { x, y } = props.getRelativeCoords({ x: dx, y: dy });

		const MIN_DIM = -100000;

		var new_x, new_y, new_w, new_h;
		switch (props.position) {
			case "t":
				new_y = y_0 + y;
				new_h = h_0 - y;
				new_w = w_0;

				if (props.boundLimits) {
					if (new_y < 0) {
						new_y = 0;
						new_h = props.shape.size[1] + y_0;
					}
				}

				if (new_h > MIN_DIM && new_w > MIN_DIM) {
					props.updateShape(props.shape.id, {
						position: [x_0, new_y],
						size: [new_w, new_h],
					});
				}

				break;

			case "tl":
				new_x = x_0 + x;
				new_y = y_0 + y;
				new_w = w_0 - x;
				new_h = h_0 - y;

				if (props.lockRatio) {
					new_h = (new_w * h_0) / w_0;
					new_y = y_0 + h_0 - new_h;
				}

				if (props.boundLimits) {
					if (new_x < 0) {
						new_x = 0;
						new_w = w_0 + x_0;
						if (props.lockRatio) break;
					}

					if (new_y < 0) {
						new_y = 0;
						new_h = h_0 + y_0;
						if (props.lockRatio) break;
					}
				}

				if (new_h > MIN_DIM && new_w > MIN_DIM)
					props.updateShape(props.shape.id, {
						position: [new_x, new_y],
						size: [new_w, new_h],
					});
				break;

			case "tr":
				new_y = y_0 + y;
				new_w = w_0 + x;
				new_h = h_0 - y;

				if (props.lockRatio) {
					new_h = (new_w * h_0) / w_0;
					new_y = y_0 + h_0 - new_h;
				}

				if (props.boundLimits) {
					if (new_y < 0) {
						new_y = 0;
						new_h = h_0 + y_0;
						if (props.lockRatio) break;
					}
					if (x_0 + new_w > 1) {
						new_w = 1 - x_0;
						if (props.lockRatio) break;
					}
				}

				if (new_h > MIN_DIM && new_w > MIN_DIM)
					props.updateShape(props.shape.id, {
						position: [x_0, new_y],
						size: [new_w, new_h],
					});
				break;

			case "l":
				new_x = x_0 + x;
				new_w = w_0 - x;

				if (props.boundLimits) {
					if (new_x < 0) {
						new_x = 0;
						new_w = props.shape.size[0] + x_0;
					}
				}

				if (new_w > MIN_DIM)
					props.updateShape(props.shape.id, {
						position: [new_x, y_0],
						size: [new_w, h_0],
					});
				break;

			case "bl":
				new_x = x_0 + x;
				new_w = w_0 - x;
				new_h = h_0 + y;

				if (props.lockRatio) new_h = (new_w * h_0) / w_0;

				if (new_x < 0) {
					new_x = 0;
					new_w = props.shape.size[0] + x_0;
					if (props.lockRatio) break;
				}

				if (new_h + y_0 > 1) {
					new_h = 1 - y_0;
					if (props.lockRatio) break;
				} else if (new_h > MIN_DIM && new_w > MIN_DIM)
					props.updateShape(props.shape.id, {
						position: [new_x, y_0],
						size: [new_w, new_h],
					});
				break;

			case "r":
				new_w = w_0 + x;

				if (new_w + props.shape.position[0] > 1) {
					new_w = 1 - props.shape.position[0];
				}

				if (new_w > MIN_DIM) {
					props.updateShape(props.shape.id, {
						size: [new_w, h_0],
					});
				}

				break;

			case "br":
				new_w = w_0 + x;
				new_h = h_0 + y;

				if (props.lockRatio) {
					new_h = (new_w * h_0) / w_0;
				}

				if (new_w + props.shape.position[0] > 1) {
					new_w = 1 - props.shape.position[0];
					if (props.lockRatio) break;
				}

				if (new_h + props.shape.position[1] > 1) {
					new_h = 1 - props.shape.position[1];
					if (props.lockRatio) break;
				}

				// if (new_h > MIN_DIM && new_w > MIN_DIM)
				props.updateShape(props.shape.id, { size: [new_w, new_h] });

				break;

			case "b":
				new_h = h_0 + y;

				if (new_h + props.shape.position[1] > 1) {
					new_h = 1 - props.shape.position[1];
				}

				if (new_h > MIN_DIM)
					props.updateShape(props.shape.id, { size: [w_0, new_h] });

				break;
		}
	};

	const onMouseUp = () => {
		isDragging.current = false;
		cleanUpListeners();
	};

	const initListeners = () => {
		window.addEventListener("mousemove", onMouseMove);
		window.addEventListener("mouseup", onMouseUp);
	};

	const cleanUpListeners = () => {
		window.removeEventListener("mousemove", onMouseMove);
		window.removeEventListener("mouseup", onMouseUp);
	};

	useEffect(() => {}, []);

	useEffect(() => {
		return cleanUpListeners;

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const effectiveZoom = props.resizeControls ? zoomFactor : 1;
	return (
		<div
			className={` SizeController position-${props.position}`}
			style={{
				border: `${2 / Math.sqrt(effectiveZoom)}px solid white`,
				width: 12 / Math.sqrt(effectiveZoom),
				aspectRatio: 1,
			}}
			onMouseDown={onMouseDown}
		></div>
	);
};

export default SizeControllerThumb;
