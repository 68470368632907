const searchIcon = (color?: string) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill={color || "currentColor"}
			width="100%"
			height="100%"
			viewBox="-2 0 19 19"
		>
			<path d="M14.147 15.488a1.112 1.112 0 0 1-1.567 0l-3.395-3.395a5.575 5.575 0 1 1 1.568-1.568l3.394 3.395a1.112 1.112 0 0 1 0 1.568zm-6.361-3.903a4.488 4.488 0 1 0-1.681.327 4.443 4.443 0 0 0 1.68-.327z" />
		</svg>
	);
};

export default searchIcon;
