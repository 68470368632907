import { GigaUserApi } from "@giga-user-fern/api";
import { GuideData } from "@giga-user-fern/api/types/api/resources/guides";
import { VideoEdits } from "@giga-user-fern/api/types/api/resources/video";
import { HelpCenterProps } from "@gigauser/common/src/help-center/HelpCenter/HelpCenter";
import { helpSaver as saver } from "@gigauser/common/src/network/helpcenterSaver";
import React from "react";

type ExtHelpCenterContextValue = HelpCenterProps;

const ExtHelpCenterContext = React.createContext<ExtHelpCenterContextValue>({
	navigator: () => (a: string) => {},
	environment: "ext-hc",

	search: saver.search,

	getOrganization: () => {
		return saver.organization;
	},

	fetchAllGuidePreviews: async () => {
		const guidePreviews = await saver.fetchAllGuidePreviews();
		return guidePreviews;
	},

	fetchAllChildren: async (collectionId?: string) => {
		const { guides, collections } = await saver.fetchAllChildren(
			collectionId,
			true,
		);
		return Promise.resolve({
			collections: collections,
			guidePreviews: guides,
		});
	},

	fetchGuidePreview: async (id: GigaUserApi.Id) => {
		const guidePreview = await saver.fetchGuidePreview(id);
		return guidePreview;
	},

	getPath: async (id: string) => {
		const path = await saver.getPath(id);
		return path;
	},

	fetchGuideData: async (id: GigaUserApi.Id) => {
		const guideData = (await saver.fetchGuideData(id)) as GuideData;
		return guideData;
	},

	// getVideoEdits:async (id: GigaUserApi.Id) => {
	//     const videoEdits = await saver.getVideoEdits(id) as VideoEdits
	//     return videoEdits;
	// },

	close: () => {},
});

export default ExtHelpCenterContext;
