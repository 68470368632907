import React, { useContext, useEffect, useRef, useState } from "react";
import CanvasPlayer, { CanvasPlayerProps } from "../CanvasPlayer/CanvasPlayer";
import { GuideData, GuidePreview } from "../../types/guide";
import { Device } from "../../../types/devices";
import useWindowSize from "../../../hooks/useWindowSize";
import playIcon from "../../../assets/svgs/playIcon";
import logoIcon from "../../../assets/svgs/logoIcon";

import "./canvasplayer-styles/CanvasPlayerController.css";
import "./canvasplayer-styles/CanvasPlayerControls.css";

import pauseIcon from "../../../assets/svgs/pauseIcon";
import Icon from "../../../ui/Icon/Icon";
import CanvasScrubber from "./canvasplayer-components/CanvasScrubber/CanvasScrubber";
import CanvasControls from "./canvasplayer-components/CanvasControls/CanvasControls";
import CanvasPlayerContext from "../CanvasPlayerContext";
import { HelpCenterEnvironment } from "../../../components/Guide/Viewer/Viewer";
import { openExternalLink } from "../../../utils/url";

export type CanvasPlayerControllerEnvironment =
	| HelpCenterEnvironment
	| "internal";

type CanvasPlayerControllerProps = {
	guidePreview?: GuidePreview;
	environment: CanvasPlayerControllerEnvironment;
	// videoSrc?: string
	// video: GuideData["video"];
	overrideDevice?: Device;

	hideScrubber?: boolean;
	hideFullscreen?: boolean;
	hideBigPlay?: boolean;
	hideControls?: boolean;
};

const CanvasPlayerController: React.FC<CanvasPlayerControllerProps> = (
	props,
) => {
	const [firstPlayDone, setFirstPlayDone] = useState(false);

	const [flashPlay, setFlashPlay] = useState(false);
	const [flashPause, setFlashPause] = useState(false);

	const [showControls, setShowControls] = useState(false);

	const cp = useContext(CanvasPlayerContext);

	var device: Device = useWindowSize().device;
	if (props.overrideDevice) device = props.overrideDevice;

	const [volumeLevel, setVolumeLevel] = useState(
		cp.vidRef?.current?.volume || 0.8,
	); //0 -> 1

	const vidRef = cp.vidRef;
	if (!vidRef) return <div>Where's vidref in canvasplayer controller ?</div>;

	const playHandler = () => {
		cp.play();

		if (!firstPlayDone) {
			setShowControls(true);
			setFirstPlayDone(true);
		}
		setFlashPlay(true);

		setTimeout(() => {
			setFlashPlay(false);
		}, 200);
	};

	const pauseHandler = () => {
		cp.pause();

		setFlashPause(true);

		setTimeout(() => {
			setFlashPause(false);
		}, 200);
	};

	const togglePlay = () => {
		if (!vidRef.current) return;

		if (cp.paused) {
			playHandler();
		} else {
			pauseHandler();
		}
	};

	const onCanvasHoverIn = () => {
		if (!firstPlayDone) setShowControls(false);
		else {
			setShowControls(true);
		}
	};

	const onCavasHoverOut = () => {
		setShowControls(false);
	};

	const changeVolume = (level: number) => {
		//level is between 0.0 and 1.0
		if (vidRef.current) {
			setVolumeLevel(level);
			vidRef.current.volume = level;
		}
	};

	const toggleFullscreen = () => {
		if (props.environment !== "remote-script") {
			cp.toggleFullscreen();
		} else {
			//@ts-ignore
			const hostname = window.clueso.hostname;
			if (hostname)
				openExternalLink(
					`https://${hostname}/guide/${props.guidePreview?.id}`,
				);
		}
	};

	return (
		<>
			{cp.fullscreen ? (
				<div
					className="gigauser-canvas-blackscreen"
					onClick={toggleFullscreen}
				>
					.
				</div>
			) : null}

			<div
				className={`gigauser-CanvasPlayerController 
                ${
					cp.vidRef?.current &&
					cp.vidRef.current.videoHeight > cp.vidRef.current.videoWidth
						? "gigauser-CanvasPlayerMobile"
						: ""
				}
                ${cp.fullscreen ? "gigauser-fullscreen-canvas" : ""} gigauser-${device}`}
				// onClick={togglePlay}
				onMouseOver={onCanvasHoverIn}
				onMouseOut={onCavasHoverOut}
			>
				<CanvasPlayer />

				<div
					className={`thumbnail-play 
                    ${cp.paused && !showControls && !props.hideBigPlay ? "gigauser-show-thumbnail-play" : "gigauser-hide-thumbnail-play"}
                }`}
					onClick={playHandler}
				>
					<div className="thumbnail-play-icon-container">
						<div className="thumbnail-play-bg"></div>
						<div className="thumbnail-play-icon">
							{playIcon("white")}
						</div>
					</div>
					{props.environment !== "internal" ? (
						<CanvasPlayerCluesoPreview />
					) : null}
				</div>

				<div
					className={`flash-play ${flashPlay || flashPause ? "flash-show" : ""}`}
				>
					{flashPlay ? (
						<Icon className="flash-icon-container">
							{playIcon("white")}
						</Icon>
					) : flashPause ? (
						<Icon className="flash-icon-container">
							{pauseIcon("white")}
						</Icon>
					) : null}
				</div>

				<div
					className={`gigauser-canvasplayer-controls  ${showControls && !props.hideControls ? "gigauser-show-controls" : "gigauser-hide-controls"}`}
				>
					{props.environment !== "internal" ? (
						<div className={"gigauser-clueso-branding"}>
							<CanvasPlayerCluesoPreview />
						</div>
					) : null}

					<div
						className={"gigauser-clueso-scrim"}
						onClick={togglePlay}
					></div>

					<div className={`gigauser-controller-footer`}>
						{!props.hideScrubber ? (
							<div className="gigauser-video-scrubber">
								<CanvasScrubber />
							</div>
						) : null}

						<div className={`gigauser-video-controls `}>
							<CanvasControls
								customPlayHandler={playHandler}
								customPauseHandler={pauseHandler}
								environment={props.environment}
								overrideDevice={props.overrideDevice}
								volume={volumeLevel}
								setVolume={changeVolume}
								toggleFullscreen={toggleFullscreen}
								hideFullscreen={props.hideFullscreen}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

type CanvasPlayerCluesoPreviewProps = {};

const CanvasPlayerCluesoPreview: React.FC<
	CanvasPlayerCluesoPreviewProps
> = () => {
	return (
		<a
			className="thumbnail-clueso-preview"
			href="https://www.clueso.io/features/video-tutorials"
			target="_blank"
		>
			<span>Made in</span>
			<div className="thumbnail-clueso-logo">{logoIcon()}</div>
			{/* <img className="thumbnail-clueso-logo"></img> */}
		</a>
	);
};

export default CanvasPlayerController;
