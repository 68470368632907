import React, { useEffect, useRef } from "react";
import { useEffectOnce } from "../../hooks/useEffectOnce";

type ClickoutProps = {
	children: React.ReactNode;
	closeFunction: (a?: any) => void;
	closeEarly?: boolean; //true if to close on mousedown instead of on mouse click.
};

const Clickout: React.FC<ClickoutProps> = ({
	children,
	closeFunction,
	closeEarly,
}) => {
	const clickoutRef = useRef<HTMLDivElement>(null);

	const clickOutsidePopup = (e: any) => {
		if (clickoutRef.current?.contains(e.composedPath()[0] as Node)) {
		} else if (
			(e.target as Element).classList.contains("keepClickoutOpen")
		) {
			//hardcoded keep open
			return;
		} else {
			closeFunction();
		}
	};

	useEffectOnce(() => {
		const triggerAction = closeEarly ? "mousedown" : "click";
		setTimeout(() => {
			const a = document.getElementsByClassName(
				"chakra-modal__content-container",
			)[0];
			if (a) a.addEventListener(triggerAction, clickOutsidePopup);
			document.addEventListener(triggerAction, clickOutsidePopup);
		}, 300);

		return () => {
			const a = document.getElementsByClassName(
				"chakra-modal__content-container",
			)[0];
			if (a) a.removeEventListener(triggerAction, clickOutsidePopup);
			document.removeEventListener(triggerAction, clickOutsidePopup);
		};
	});

	return (
		<div className="Clickout" ref={clickoutRef}>
			{children}
		</div>
	);
};
export default Clickout;
