import axios from "axios";

export type Font = {
	family?: string;
	variants?: string[];
	subsets?: string[];
	version?: string;
	lastModified?: string;
	files?: {
		[key: string]: string;
	};
	category?: string;
	kind?: string;
	menu?: string;
};

type FontURLProps = {
	family?: string;
	subset?: string;
	capability?: "VF" | "WOFF2";
	sort?: "alpha" | "date" | "popularity" | "style" | "trending";
};

const getAPI = async (url: string) => {
	try {
		const response = await axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
	}
};

const getFontURL = ({ family, subset, capability, sort }: FontURLProps) => {
	let BASE_URL = process.env.REACT_APP_GOOGLE_FONTS_API_URL;

	if (!BASE_URL) throw new Error("Fonts API is not defined");

	if (family) BASE_URL += `&family=${family}`;
	if (subset) BASE_URL += `&subset=${subset}`;
	if (capability) BASE_URL += `&capability=${capability}`;
	if (sort) BASE_URL += `&sort=${sort}`;

	return BASE_URL;
};

export const getFonts = async () => {
	const url = getFontURL({});
	const response = await getAPI(url);

	return response.items.map((font: Font) => font.family);
};

export const getFont = async (isTemplate: boolean, family?: string) => {
	if (family === undefined) {
		if (isTemplate) {
			family = "League Spartan";
		} else {
			family = "Inter";
		}
	}

	try {
		const fontValue = isFontInDocument(family);

		if (fontValue) {
			return fontValue;
		}

		const key = await getFontFromLocal(family);
		if (key) {
			return key;
		}

		//If the font has not been loaded yet, go here:

		const url = getFontURL({ family });
		const response = await getAPI(url);

		const font = response.items.find(
			(item: Font) => item.family === family,
		);
		if (!font) throw new Error("Font not found");

		const fontName = family;
		let fontWeight = "600";

		if (!font?.files[fontWeight]) {
			fontWeight = "regular";
		}

		const fontUrl = font?.files[fontWeight];

		localStorage.setItem(`${fontName}-${fontWeight}`, fontUrl);

		if (fontName && fontUrl) {
			const fontFace = new FontFace(
				`${fontName}-${fontWeight}`,
				`url(${fontUrl})`,
			);
			await fontFace.load();
			document.fonts.add(fontFace);
		}

		return `${fontName}-${fontWeight}`;
	} catch (error) {
		return false;
	}
};

export const isFontInDocument = (family: string) => {
	var fontFamily: string | boolean = false;

	document.fonts.forEach((font) => {
		const splitFont = font.family.split("-");

		if (splitFont[0]?.toLowerCase() === family?.toLowerCase()) {
			if (
				!splitFont[1] ||
				!(
					splitFont[1]?.toLowerCase() === "regular" ||
					splitFont[1]?.toLowerCase() === "600"
				)
			) {
				//no scene
			} else {
				fontFamily = font.family;
			}
		}
	});

	return fontFamily;
};

export const getFontFromLocal = async (family: string) => {
	for (let i = 0; i < localStorage.length; i++) {
		const key = localStorage.key(i) || "";
		if (key.split("-")[0] == family && localStorage.getItem(key) != null) {
			const url = localStorage.getItem(key)!;
			if (key && url) {
				const fontFace = new FontFace(key, `url(${url})`);
				await fontFace.load();
				document.fonts.add(fontFace);
			}

			return key;
		}
	}
};

export const getFontSync = (family: string) => {
	const defaultFamily = "Inter";
};
