export const chevronDown = (color?: string) => {
	return (
		<svg
			width="10"
			height="10"
			viewBox="0 0 10 10"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8.82063 2.5L9.375 2.9925L5 7.5L0.625 2.9925L1.17937 2.5L5 6.43625L8.82063 2.5Z"
				fill={color ?? "#838BA0"}
			/>
		</svg>
	);
};
