const homeIcon = (color?: string) => {
	const fill = color || "#000000";

	return (
		<svg
			width="100%"
			height="82%"
			style={{
				transition: "0.3s",
			}}
			viewBox="0 0 25 21"
			fill={fill}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5.47222 20V8.6H1L12.5 1L24 8.6H19.5278V20H15.6944V15.5667C15.6944 14.5174 14.8363 13.6667 13.7778 13.6667H11.2222C10.1637 13.6667 9.30556 14.5174 9.30556 15.5667V20H5.47222Z"
				fill={fill}
				stroke={fill}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default homeIcon;
