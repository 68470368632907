const inIcon = (color = "#000000") => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="0 0 64 64"
			fill="none"
		>
			<rect width="64" height="64" rx="32" fill="white" />
			<path
				d="M40 32C40 27.5789 36.4211 24 32 24C27.5789 24 24 27.5789 24 32C24 36.414 27.5789 40 32 40C36.4211 40 40 36.4211 40 32Z"
				fill="#000088"
			/>
			<path
				d="M38.9965 32.0002C38.9965 28.1335 35.8666 25.0037 32 25.0037C28.1333 25.0037 25.0035 28.1335 25.0035 32.0002C25.0035 35.8668 28.1333 38.9966 32 38.9966C35.8666 38.9966 38.9965 35.8668 38.9965 32.0002Z"
				fill="white"
			/>
			<path
				d="M33.3965 31.9997C33.3965 31.2278 32.7719 30.5962 32 30.5962C31.2281 30.5962 30.6035 31.2208 30.6035 31.9997C30.6035 32.7716 31.2281 33.3962 32 33.3962C32.7719 33.3962 33.3965 32.7716 33.3965 31.9997Z"
				fill="#000088"
			/>
			<path
				d="M39.2843 32.9613C39.3123 32.7718 39.172 32.5963 38.9825 32.5683C38.793 32.5402 38.6176 32.6806 38.5895 32.87C38.5614 33.0595 38.7018 33.2349 38.8913 33.263C39.0878 33.2841 39.2632 33.1507 39.2843 32.9613Z"
				fill="#000088"
			/>
			<path
				d="M31.9999 38.9961L32.2385 34.7996L31.9999 32.7996L31.7614 34.7996L31.9999 38.9961Z"
				fill="#000088"
			/>
			<path
				d="M38.786 34.8139C38.8632 34.6384 38.772 34.4279 38.5965 34.3577C38.4211 34.2805 38.2106 34.3718 38.1404 34.5472C38.0632 34.7226 38.1544 34.9332 38.3299 35.0033C38.5123 35.0735 38.7158 34.9893 38.786 34.8139Z"
				fill="#000088"
			/>
			<path
				d="M30.1895 38.7577L31.5088 34.7647L31.7965 32.7717L31.0456 34.6454L30.1895 38.7577Z"
				fill="#000088"
			/>
			<path
				d="M37.8317 36.4772C37.951 36.3228 37.9229 36.1053 37.7686 35.986C37.6142 35.8667 37.3966 35.8948 37.2773 36.0492C37.158 36.2035 37.1861 36.4211 37.3405 36.5404C37.4949 36.6597 37.7124 36.6246 37.8317 36.4772Z"
				fill="#000088"
			/>
			<path
				d="M28.4984 38.0632L30.8071 34.5475L31.6001 32.6948L30.3931 34.3018L28.4984 38.0632Z"
				fill="#000088"
			/>
			<path
				d="M36.4701 37.8316C36.6245 37.7123 36.6525 37.4948 36.5332 37.3404C36.4139 37.186 36.1964 37.158 36.042 37.2773C35.8876 37.3966 35.8596 37.6141 35.9789 37.7685C36.0982 37.9159 36.3227 37.9509 36.4701 37.8316Z"
				fill="#000088"
			/>
			<path
				d="M27.0527 36.9473L30.1895 34.1473L31.4316 32.5684L29.8527 33.8105L27.0527 36.9473Z"
				fill="#000088"
			/>
			<path
				d="M34.814 38.7929C34.9894 38.7157 35.0807 38.5122 35.0035 38.3368C34.9263 38.1613 34.7228 38.0701 34.5473 38.1473C34.3719 38.2175 34.2807 38.428 34.3578 38.6034C34.428 38.7789 34.6315 38.8631 34.814 38.7929Z"
				fill="#000088"
			/>
			<path
				d="M25.9369 35.5019L29.6983 33.6072L31.3053 32.4001L29.4527 33.1931L25.9369 35.5019Z"
				fill="#000088"
			/>
			<path
				d="M32.9615 39.2843C33.1509 39.2563 33.2843 39.0808 33.2632 38.8913C33.2351 38.7019 33.0597 38.5685 32.8702 38.5896C32.6808 38.6177 32.5474 38.7931 32.5685 38.9826C32.5895 39.1791 32.765 39.3124 32.9615 39.2843Z"
				fill="#000088"
			/>
			<path
				d="M25.2421 33.8107L29.3614 32.9546L31.2281 32.2107L29.2351 32.4914L25.2421 33.8107Z"
				fill="#000088"
			/>
			<path
				d="M31.0387 39.2843C31.2282 39.3123 31.4036 39.172 31.4317 38.9825C31.4597 38.793 31.3194 38.6176 31.1299 38.5895C30.9404 38.5614 30.765 38.7018 30.7369 38.8913C30.7159 39.0878 30.8492 39.2632 31.0387 39.2843Z"
				fill="#000088"
			/>
			<path
				d="M25.0035 32.0001L29.2 32.2387L31.2 32.0001L29.2 31.7615L25.0035 32.0001Z"
				fill="#000088"
			/>
			<path
				d="M29.1859 38.7929C29.3614 38.8701 29.5719 38.7789 29.6421 38.6034C29.7123 38.428 29.628 38.2175 29.4526 38.1473C29.2772 38.0701 29.0666 38.1613 28.9965 38.3368C28.9263 38.5122 29.0105 38.7157 29.1859 38.7929Z"
				fill="#000088"
			/>
			<path
				d="M25.2421 30.1895L29.2351 31.5088L31.2281 31.7965L29.3614 31.0456L25.2421 30.1895Z"
				fill="#000088"
			/>
			<path
				d="M27.5229 37.8316C27.6773 37.9509 27.8948 37.9229 28.0141 37.7685C28.1334 37.6141 28.1053 37.3966 27.9509 37.2773C27.7966 37.158 27.579 37.186 27.4597 37.3404C27.3474 37.4948 27.3755 37.7123 27.5229 37.8316Z"
				fill="#000088"
			/>
			<path
				d="M25.9369 28.498L29.4527 30.8068L31.3053 31.5998L29.6983 30.3928L25.9369 28.498Z"
				fill="#000088"
			/>
			<path
				d="M26.1685 36.4772C26.2878 36.6316 26.5053 36.6597 26.6597 36.5404C26.8141 36.4211 26.8421 36.2035 26.7228 36.0492C26.6035 35.8948 26.386 35.8667 26.2316 35.986C26.0843 36.0983 26.0492 36.3228 26.1685 36.4772Z"
				fill="#000088"
			/>
			<path
				d="M27.0527 27.0525L29.8527 30.1893L31.4316 31.4385L30.1895 29.8525L27.0527 27.0525Z"
				fill="#000088"
			/>
			<path
				d="M25.207 34.8139C25.2842 34.9893 25.4877 35.0805 25.6631 35.0033C25.8386 34.9261 25.9298 34.7226 25.8526 34.5472C25.7754 34.3718 25.5719 34.2805 25.3964 34.3577C25.221 34.4279 25.1368 34.6314 25.207 34.8139Z"
				fill="#000088"
			/>
			<path
				d="M28.4984 25.937L30.3931 29.6984L31.6001 31.3054L30.8071 29.4598L28.4984 25.937Z"
				fill="#000088"
			/>
			<path
				d="M24.7158 32.9613C24.7439 33.1508 24.9193 33.2841 25.1088 33.2631C25.2983 33.235 25.4316 33.0596 25.4106 32.8701C25.3825 32.6806 25.2071 32.5473 25.0176 32.5683C24.8211 32.5894 24.6878 32.7648 24.7158 32.9613Z"
				fill="#000088"
			/>
			<path
				d="M30.1895 25.2422L31.0456 29.3615L31.7965 31.2282L31.5088 29.2352L30.1895 25.2422Z"
				fill="#000088"
			/>
			<path
				d="M24.7159 31.0383C24.6878 31.2278 24.8281 31.4032 25.0176 31.4313C25.2071 31.4594 25.3825 31.319 25.4106 31.1295C25.4387 30.9401 25.2983 30.7646 25.1088 30.7366C24.9124 30.7155 24.7369 30.8488 24.7159 31.0383Z"
				fill="#000088"
			/>
			<path
				d="M31.9999 25.0037L31.7614 29.2002L31.9999 31.2002L32.2385 29.2002L31.9999 25.0037Z"
				fill="#000088"
			/>
			<path
				d="M25.214 29.1856C25.1368 29.3611 25.228 29.5716 25.4035 29.6418C25.5789 29.7119 25.7894 29.6277 25.8596 29.4523C25.9368 29.2769 25.8456 29.0663 25.6701 28.9962C25.4877 28.926 25.2842 29.0102 25.214 29.1856Z"
				fill="#000088"
			/>
			<path
				d="M33.8106 25.2422L32.4913 29.2352L32.2036 31.2282L32.9544 29.3615L33.8106 25.2422Z"
				fill="#000088"
			/>
			<path
				d="M26.1684 27.5299C26.0491 27.6843 26.0772 27.9018 26.2316 28.0211C26.386 28.1404 26.6035 28.1123 26.7228 27.9579C26.8421 27.8036 26.814 27.586 26.6597 27.4667C26.5053 27.3474 26.2877 27.3755 26.1684 27.5299Z"
				fill="#000088"
			/>
			<path
				d="M35.5017 25.937L33.1929 29.4598L32.4 31.3054L33.607 29.6984L35.5017 25.937Z"
				fill="#000088"
			/>
			<path
				d="M27.5299 26.1683C27.3755 26.2876 27.3475 26.5052 27.4668 26.6596C27.5861 26.8139 27.8036 26.842 27.958 26.7227C28.1124 26.6034 28.1405 26.3859 28.0212 26.2315C27.9019 26.0771 27.6773 26.056 27.5299 26.1683Z"
				fill="#000088"
			/>
			<path
				d="M36.9474 27.0525L33.8105 29.8525L32.5684 31.4385L34.1474 30.1893L36.9474 27.0525Z"
				fill="#000088"
			/>
			<path
				d="M29.1859 25.2142C29.0105 25.2914 28.9263 25.4949 28.9965 25.6703C29.0737 25.8457 29.2772 25.937 29.4526 25.8598C29.628 25.7826 29.7193 25.5791 29.6421 25.4036C29.5719 25.2212 29.3684 25.137 29.1859 25.2142Z"
				fill="#000088"
			/>
			<path
				d="M38.0632 28.498L34.3018 30.3928L32.6948 31.5998L34.5474 30.8068L38.0632 28.498Z"
				fill="#000088"
			/>
			<path
				d="M31.0387 24.716C30.8492 24.7441 30.7159 24.9195 30.7369 25.109C30.765 25.2984 30.9404 25.4318 31.1299 25.4107C31.3194 25.3826 31.4597 25.2072 31.4317 25.0177C31.4106 24.8283 31.2352 24.6879 31.0387 24.716Z"
				fill="#000088"
			/>
			<path
				d="M38.7579 30.1895L34.6386 31.0456L32.7719 31.7965L34.7649 31.5088L38.7579 30.1895Z"
				fill="#000088"
			/>
			<path
				d="M32.9614 24.716C32.772 24.6879 32.5965 24.8283 32.5685 25.0177C32.5404 25.2072 32.6807 25.3826 32.8702 25.4107C33.0597 25.4388 33.2351 25.2984 33.2632 25.109C33.2843 24.9195 33.1509 24.737 32.9614 24.716Z"
				fill="#000088"
			/>
			<path
				d="M38.9966 32.0001L34.8001 31.7615L32.8001 32.0001L34.8001 32.2387L38.9966 32.0001Z"
				fill="#000088"
			/>
			<path
				d="M34.814 25.2137C34.6385 25.1365 34.428 25.2277 34.3578 25.4032C34.2807 25.5786 34.3719 25.7891 34.5473 25.8593C34.7228 25.9295 34.9333 25.8453 35.0035 25.6698C35.0736 25.4874 34.9894 25.2839 34.814 25.2137Z"
				fill="#000088"
			/>
			<path
				d="M38.7579 33.8107L34.7649 32.4914L32.7719 32.2107L34.6386 32.9546L38.7579 33.8107Z"
				fill="#000088"
			/>
			<path
				d="M36.4701 26.1681C36.3157 26.0488 36.0982 26.0769 35.9789 26.2313C35.8596 26.3857 35.8876 26.6032 36.042 26.7225C36.1964 26.8418 36.4139 26.8137 36.5332 26.6594C36.6525 26.505 36.6245 26.2874 36.4701 26.1681Z"
				fill="#000088"
			/>
			<path
				d="M38.0632 35.5019L34.5474 33.1931L32.6948 32.4001L34.3018 33.6072L38.0632 35.5019Z"
				fill="#000088"
			/>
			<path
				d="M37.8317 27.5299C37.7124 27.3755 37.4949 27.3474 37.3405 27.4667C37.1861 27.586 37.158 27.8036 37.2773 27.9579C37.3966 28.1123 37.6142 28.1404 37.7686 28.0211C37.9159 27.9018 37.951 27.6772 37.8317 27.5299Z"
				fill="#000088"
			/>
			<path
				d="M36.9474 36.9473L34.1474 33.8105L32.5684 32.5684L33.8105 34.1473L36.9474 36.9473Z"
				fill="#000088"
			/>
			<path
				d="M38.793 29.1859C38.7158 29.0104 38.5123 28.9192 38.3368 28.9964C38.1614 29.0736 38.0701 29.2771 38.1473 29.4525C38.2245 29.628 38.428 29.7122 38.6035 29.642C38.7789 29.5718 38.8631 29.3683 38.793 29.1859Z"
				fill="#000088"
			/>
			<path
				d="M35.5017 38.0632L33.607 34.3018L32.4 32.6948L33.1929 34.5475L35.5017 38.0632Z"
				fill="#000088"
			/>
			<path
				d="M39.2843 31.0383C39.2562 30.8488 39.0808 30.7155 38.8913 30.7366C38.7018 30.7646 38.5685 30.9401 38.5895 31.1295C38.6176 31.319 38.793 31.4524 38.9825 31.4313C39.179 31.4102 39.3123 31.2348 39.2843 31.0383Z"
				fill="#000088"
			/>
			<path
				d="M33.8106 38.7577L32.9544 34.6454L32.2036 32.7717L32.4913 34.7647L33.8106 38.7577Z"
				fill="#000088"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M2 21C6.48565 8.74571 18.2511 0 32.0592 0C45.8673 0 57.6328 8.74571 62.1185 21H2Z"
				fill="#FF9933"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M62.1185 43C57.6328 55.2543 45.8673 64 32.0592 64C18.2511 64 6.48565 55.2543 2 43H62.1185Z"
				fill="#128807"
			/>
		</svg>
	);
};
export default inIcon;
