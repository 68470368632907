import React, {
	useContext,
	useEffect,
	useLayoutEffect,
	useRef,
	useState,
} from "react";
import "./ScreenshotEditor.css";
import zoominIcon from "../../../../../assets/svgs/screenshot/zoominIcon";
import zoomoutIcon from "../../../../../assets/svgs/screenshot/zoomoutIcon";
import rectangleIcon from "../../../../../assets/svgs/screenshot/rectangleIcon";
import circleIcon from "../../../../../assets/svgs/screenshot/circleIcon";
import selectIcon from "../../../../../assets/svgs/screenshot/selectIcon";
import blurIcon from "../../../../../assets/svgs/screenshot/blurIcon";
import Cutton from "../../../../../ui/buttons/Cutton/Cutton";
import Icon from "../../../../../ui/Icon/Icon";
import {
	GifExtensionMetadata,
	ImageExtensionMetadata,
} from "../../extensions/SpecialImageExtension";
import ImgShape from "./components/ImgShape/ImgShape";
import ScreenshotEditorContext, {
	Shape,
	UpdateShapeFunction,
	UpdateShapeProperties,
} from "./ScreenshotEditorContext";
import ShapeCustomizer from "./components/ShapeCustomizer/ShapeCustomizer";
import { ScreenshotRenderEdits } from "./useEditScreenshot";
import { useAppDispatch, useAppSelector } from "../../../../../redux";
import { closeOverlay } from "../../../../../layouts/Overlay";
import { selectAnalytics } from "../../../../../redux/slices/backendSlice";
import { selectGuide } from "../../../../../redux/slices/guideSlice";
import CanvasPlayerController from "../../../../../core/canvas/CanvasPlayerController/CanvasPlayerController";
import circledPlayIcon from "../../../../../assets/svgs/videoeditor/circledPlayIcon";
import CanvasPlayerContext from "../../../../../core/canvas/CanvasPlayerContext";
import circledPauseIcon from "../../../../../assets/svgs/videoeditor/circledPauseIcon";
import { VideoClip } from "@giga-user-fern/api/types/api/resources/guides";
import Clickout from "../../../../../layouts/Clickout/Clickout";
import arrowIcon from "../../../../../assets/svgs/screenshot/arrowIcon";
import { fastSpeeds } from "../../../../../utils/speedUtils";
import { Size } from "../../../../../types/sizes";
import cropIcon from "../../../../../assets/svgs/videoeditor/cropIcon";
import SizeControllerThumb from "./components/SizeController/SizeControllerThumb";
import DragController from "./components/SizeController/DragController";
import {
	getPixelCrop,
	getShape,
	getSizeControls,
	resetCrop,
	updateCrop,
} from "../../../../../utils/cropUtils";
import { Crop } from "@giga-user-fern/api/types/api/resources/video";
import { set } from "lodash";

type ScreenshotEditorProps = {
	metadata: ImageExtensionMetadata;
	rootSrc: string;
	saveLabel?: string;
	removeControls?: boolean;
	size?: Size;
} & (
	| {
			srcType: "img";
			onEditScreenshot: (s: ScreenshotRenderEdits) => void;
	  }
	| {
			srcType: "video";
			clip: VideoClip;
			onMakeGif: (edits: ScreenshotRenderEdits, rate: number) => void;
	  }
);

interface Position {
	x: number;
	y: number;
}

const cropScreenshot = (src: string, crop: Crop): Promise<string> => {
	return new Promise((resolve, reject) => {
		const canvas = document.createElement("canvas");
		const ctx = canvas.getContext("2d");

		const img = new Image();
		img.crossOrigin = "anonymous";
		img.src = src;
		const { position, size } = crop;

		img.onload = () => {
			canvas.width = img.naturalWidth * size[0];
			canvas.height = img.naturalHeight * size[1];

			ctx?.drawImage(
				img,
				position[0] * img.width,
				position[1] * img.height,
				size[0] * img.width,
				size[1] * img.height,
				0,
				0,
				img.width * size[0],
				img.height * size[1],
			);

			const url = canvas.toDataURL("image/png");

			if (url) resolve(url);
			else reject(new Error("Failed to generate URL"));
		};

		img.onerror = (error) => {
			reject(error);
		};
	});
};

const ScreenshotEditor: React.FC<ScreenshotEditorProps> = (props) => {
	const [activeShapeId, setActiveShapeId] = useState<string | null>(null);
	const dispatch = useAppDispatch();
	const analytics = useAppSelector(selectAnalytics);
	const guide = useAppSelector(selectGuide);

	const [root, setRoot] = useState<string>(props.rootSrc);
	var screenshotContainer = document.getElementById("screenshot-editor");
	const cp = useContext(CanvasPlayerContext);

	const srcType = props.srcType || "img";

	const [loading, setLoading] = useState<boolean>(true);
	const [submitting, setSubmitting] = useState<boolean>(false);
	const [cropping, setCropping] = useState<boolean>(false);

	type Tool = "select" | "rectangle" | "circle" | "arrow" | "blur" | "crop";

	type ToolButton = {
		hoverTip?: string;
		id: Tool;
		icon: (color: string) => React.ReactElement;
	};

	const imgRef = useRef<HTMLDivElement>(null);
	const ssRef = useRef<HTMLImageElement | HTMLVideoElement | any>(null);

	var initZoomCenter: { x: number; y: number } = { x: 0.5, y: 0.5 };
	if (props.metadata.zoomCenter) initZoomCenter = props.metadata.zoomCenter;

	const initSpeed =
		(props.metadata as ImageExtensionMetadata & GifExtensionMetadata)
			.rate || 1;

	const [speed, setSpeed] = useState<number>(initSpeed);
	const [showSpeedOptions, setShowSpeedOptions] = useState<boolean>(false);

	const initZoomFactor = props.metadata.zoomFactor || 1.0;
	const [zoomFactor, setZoomFactor] = useState<number>(initZoomFactor);

	let initCrop = { position: [0, 0], size: [1, 1] };

	if (props.metadata.crop) {
		initCrop = props.metadata.crop;
	}
	const [crop, setCrop] = useState(initCrop);

	const initProps = {
		zoom: initZoomFactor,
		pos: initZoomCenter,
		crop: initCrop,
		cropped: false,
	};
	const [savedProps, setSavedProps] = useState(initProps);

	const sizeControls = getSizeControls();
	const shape = getShape(crop);
	const pixelCrop = getPixelCrop(crop, imgRef);

	const clampPosition = (newX: number, newY: number): Position => {
		if (!ssRef.current || !imgRef.current) return { x: 0, y: 0 };

		if (zoomFactor == 1) {
			//no moving if no zoom.
			return {
				x: 0,
				y: 0,
			};
		}

		const bounds = imgRef.current.getBoundingClientRect();

		const containerWidth = bounds.width;
		var containerHeight = bounds.height;

		const imageWidth = containerWidth * zoomFactor;
		const imageHeight = containerHeight * zoomFactor;

		const maxX = (imageWidth - containerWidth) / (2 * zoomFactor);
		const maxY = (imageHeight - containerHeight) / (2 * zoomFactor);

		// When zoomed in, don't allow the image to move beyond its boundaries
		var clampedX =
			zoomFactor > 1 ? Math.min(maxX, Math.max(-maxX, newX)) : 0;
		var clampedY =
			zoomFactor > 1 ? Math.min(maxY, Math.max(-maxY, newY)) : 0;
		return { x: clampedX, y: clampedY };
	};

	const zoomCenterToPosition: (zoomCenter: { x: number; y: number }) => {
		x: number;
		y: number;
	} = (zoomCenter) => {
		if (!imgRef.current || !ssRef.current) return { x: 0, y: 0 };

		const bounds = imgRef.current!.getBoundingClientRect();
		const imgWidth = bounds.width;
		const imgHeight = bounds.height;

		const sx = (0.5 - zoomCenter.x) * imgWidth;
		const sy = (0.5 - zoomCenter.y) * imgHeight;

		return clampPosition(sx, sy);
	};

	useEffect(() => {
		if (props.metadata.crop && props.srcType === "img") {
			const edit = cropScreenshot(root, props.metadata.crop);
			edit.then((url) => setRoot(url));
		}
	}, [props.metadata.crop]);

	const cancelCrop = () => {
		setCropping(false);
		setCrop(savedProps.crop);
		setZoomFactor(savedProps.zoom);
		setPosition(savedProps.pos);
		updateShapeForCrop(savedProps.crop, true);

		if (props.srcType === "img") {
			setSavedProps({ ...savedProps, crop: crop, cropped: true });
			const edit = cropScreenshot(root, savedProps.crop);
			edit.then((url) => setRoot(url));
		}

		// glitch fix below two functions
		setCrop({ position: [0, 0], size: [1, 1] });
		setTimeout(() => {
			setCrop(savedProps.crop);
		}, 500);

		setActiveTool("select");
	};

	const updateShapeForCrop = (crop: Crop, afterCropping: Boolean) => {
		if (!afterCropping) {
			shapes.forEach((shape) => {
				const shapeX = shape.position[0];
				const shapeY = shape.position[1];
				const shapeWidth = shape.size[0];
				const shapeHeight = shape.size[1];
				const cropX = crop.position[0];
				const cropY = crop.position[1];
				const cropWidth = crop.size[0];
				const cropHeight = crop.size[1];
				const newShapeX = shapeX * cropWidth + cropX;
				const newShapeY = shapeY * cropHeight + cropY;
				const newShapePosition: [number, number] = [
					newShapeX,
					newShapeY,
				];
				const newShapeWidth = shapeWidth * cropWidth;
				const newShapeHeight = shapeHeight * cropHeight;
				const newShapeSize: [number, number] = [
					newShapeWidth,
					newShapeHeight,
				];
				const strokeWidth =
					shape.strokeWidth ?? 1 * Math.min(cropWidth, cropHeight);

				updateShape(shape.id, {
					position: newShapePosition,
					size: newShapeSize,
					strokeWidth: strokeWidth,
				});
			});
		} else {
			shapes.forEach((shape) => {
				const shapeX = shape.position[0];
				const shapeY = shape.position[1];
				const shapeWidth = shape.size[0];
				const shapeHeight = shape.size[1];
				const cropX = crop.position[0];
				const cropY = crop.position[1];
				const cropWidth = crop.size[0];
				const cropHeight = crop.size[1];
				const newShapeX = (shapeX - cropX) / cropWidth;
				const newShapeY = (shapeY - cropY) / cropHeight;
				const newShapePosition: [number, number] = [
					newShapeX,
					newShapeY,
				];
				const newShapeWidth = shapeWidth / cropWidth;
				const newShapeHeight = shapeHeight / cropHeight;
				const newShapeSize: [number, number] = [
					newShapeWidth,
					newShapeHeight,
				];
				const newStrokeWidth =
					shape.strokeWidth ?? 1 / Math.min(cropWidth, cropHeight);

				updateShape(shape.id, {
					position: newShapePosition,
					size: newShapeSize,
					strokeWidth: newStrokeWidth,
				});
			});
		}
	};

	const saveCrop = () => {
		updateShapeForCrop(crop, true);
		if (props.srcType === "img") {
			setSavedProps({ ...savedProps, crop: crop, cropped: true });
			const edit = cropScreenshot(root, crop);
			edit.then((url) => setRoot(url));
		}
		setCrop({ position: [0, 0], size: [1, 1] });
		setTimeout(() => {
			setCrop(crop);
		}, 1);
		setActiveTool("select");
		setCropping(false);
	};

	const onSubmit = async () => {
		setSubmitting(true);
		if (props.srcType == "img") {
			props.onEditScreenshot({
				zoomCenter: positionToZoomCenter(position),
				zoomFactor: zoomFactor,
				shapes: shapes,
				crop: crop,
			});

			if (guide?.id) {
				analytics.captureEvent({
					eventName: "ScreenshotEditedTextGuide",
					value: { guide_id: guide.id.toString() },
				});
			}
		} else {
			await props.onMakeGif(
				{
					zoomCenter: positionToZoomCenter(position),
					zoomFactor: zoomFactor,
				},
				speed,
			);

			if (guide?.id) {
				analytics.captureEvent({
					eventName: "GifEditedTextGuide",
					value: { guide_id: guide.id.toString() },
				});
			}
		}

		setSubmitting(false);
		dispatch(closeOverlay());
	};

	const positionToZoomCenter: (position: { x: number; y: number }) => {
		x: number;
		y: number;
	} = (position) => {
		if (!imgRef.current) return { x: 0, y: 0 };

		// const bounds = ssRef.current!.getBoundingClientRect();
		const bounds = imgRef.current!.getBoundingClientRect();
		const imgWidth = bounds.width;
		var imgHeight = bounds.height;

		const zx = 0.5 - position.x / imgWidth;
		const zy = 0.5 - position.y / imgHeight;

		return { x: zx, y: zy };
	};

	const [position, setPosition] = useState<Position>(
		zoomCenterToPosition(initZoomCenter),
	);

	const [activeTool, setActiveTool] = useState<Tool>("select");

	const [shapes, setShapes] = useState<Shape[]>(props.metadata.shapes || []);

	const [panning, setPanning] = useState(false);
	const [panStart, setPanStart] = useState<Position>({ x: 0, y: 0 });

	const [drawing, setDrawing] = useState(false);
	const currentDrawingId = useRef<string>("");
	const drawStart = useRef<Position>({ x: 0, y: 0 });

	useEffect(() => {
		setPosition(zoomCenterToPosition(initZoomCenter));
	}, [
		imgRef.current,
		imgRef.current?.getBoundingClientRect().height,
		imgRef.current?.getBoundingClientRect().width,
	]);

	const getFractionalCoords = (e: React.MouseEvent) => {
		if (!ssRef.current) return { x: 0, y: 0 };
		const bounds = ssRef.current!.getBoundingClientRect();
		var x = e.clientX - bounds.left;
		var y = e.clientY - bounds.top;

		x = x / bounds.width;
		y = y / bounds.height;

		return { x, y };
	};

	const getRelativeCoords = (pos: { x: number; y: number }) => {
		const { x, y } = pos;
		if (!imgRef.current) return { x: 0, y: 0 };
		const bounds = imgRef.current!.getBoundingClientRect();

		const xr = x / bounds.width;
		const yr = y / bounds.height;

		return { x: xr, y: yr };
	};

	const tools: ToolButton[] = [
		{
			hoverTip: "Select",
			id: "select",
			icon: selectIcon,
		},

		{
			hoverTip: "Rectangle",
			id: "rectangle",
			icon: rectangleIcon,
		},

		{
			hoverTip: "Circle",
			id: "circle",
			icon: circleIcon,
		},

		{
			hoverTip: "Arrow",
			id: "arrow",
			icon: arrowIcon,
		},

		{
			hoverTip: "Blur",
			id: "blur",
			icon: blurIcon,
		},
	];
	const handleZoomOut = () => {
		setZoomFactor(Math.max(1, zoomFactor - 0.1)); // Decrease scale by 0.1, not going below 1
	};

	const handleZoomIn = () => {
		setZoomFactor(zoomFactor + 0.1);
	};

	const handleCropping = () => {
		setRoot(props.rootSrc);
		updateShapeForCrop(savedProps.crop, false);
		setCropping(true);
		setZoomFactor(1);
		setSavedProps({
			zoom: zoomFactor,
			pos: position,
			crop: crop,
			cropped: false,
		});
		setCrop({ position: [0, 0], size: [1, 1] });
		setTimeout(() => {
			setCrop(crop);
		}, 1);
	};

	useEffect(() => {
		setPosition(clampPosition(position.x, position.y));
	}, [zoomFactor]);

	const handleMouseDown = (e: React.MouseEvent) => {
		// Ignore if right click
		if (e.button === 2) return;
		setActiveShapeId(null);

		if (activeTool == "select") {
			setPanning(true);
			setPanStart({
				x: e.clientX - position.x,
				y: e.clientY - position.y,
			});
		} else if (
			activeTool === "rectangle" ||
			activeTool === "circle" ||
			activeTool === "arrow" ||
			activeTool === "blur"
		) {
			setDrawing(true);

			//set the startY
			if (!ssRef.current || !imgRef.current) return;
			const imgBounds = imgRef.current.getBoundingClientRect();
			const bounds = imgRef.current!.getBoundingClientRect();

			//crop adjustment
			var startX = e.clientX - bounds.left;
			var startY = e.clientY - bounds.top;

			drawStart.current = { x: startX, y: startY };

			//initialise a shape
			const currentTimestamp = new Date().toISOString();
			currentDrawingId.current = currentTimestamp;

			const { x, y } = getFractionalCoords(e);

			var defaultShapeProperties: UpdateShapeProperties = {};
			try {
				defaultShapeProperties = JSON.parse(
					localStorage.getItem(
						`ArticleScreenshot${activeTool}Properties`,
					) || "{}",
				) as UpdateShapeProperties;
			} catch {}

			let strokeWidth =
				defaultShapeProperties.strokeWidth ||
				(2 * 1000) / Math.max(imgBounds?.width, imgBounds.height);
			if (defaultShapeProperties.strokeWidth) {
				strokeWidth = defaultShapeProperties.strokeWidth / zoomFactor;
			}

			var newShape: Shape = {
				id: currentTimestamp,
				geo: activeTool,
				version: "2024-04-26",
				position: [x, y],
				size: [0, 0],
				stroke: defaultShapeProperties.stroke || "#d43f8c",
				strokeWidth: strokeWidth,
				fill: defaultShapeProperties.fill || undefined,
				fillOpacity:
					defaultShapeProperties.fillOpacity == undefined
						? 30
						: defaultShapeProperties.fillOpacity,
			};

			if (activeTool === "circle") {
				newShape = {
					...newShape,
					stroke: defaultShapeProperties.stroke || undefined,
					fill: defaultShapeProperties.fill || "#d43f8c",
				};
			} else if (activeTool === "blur") {
				newShape = {
					...newShape,
					stroke: undefined,
					strokeWidth: 0,
				};
			}

			setShapes([...shapes, newShape]);
		}
	};

	const handleMouseMove = (e: MouseEvent) => {
		/**
		 * Panning the image around
		 */

		if (panning) {
			const newX = e.clientX - panStart.x;
			const newY = e.clientY - panStart.y;
			setPosition(clampPosition(newX, newY));
		} else if (drawing) {
			updateCurrentDrawing(e);
		}
	};

	const handleMouseUp = (e: MouseEvent) => {
		//end panning
		setPanning(false);

		//end drawing
		updateCurrentDrawing(e, true);
		setDrawing(false);
		setActiveTool("select");
		currentDrawingId.current = "";
	};

	const updateCurrentDrawing = (e: MouseEvent, endDrawing?: boolean) => {
		if (!ssRef.current || !imgRef.current) return;
		const bounds = imgRef.current.getBoundingClientRect();
		var currentX = e.clientX - bounds.left;
		var currentY = e.clientY - bounds.top;
		const currentShape = shapes.find(
			(s) => s.id === currentDrawingId.current,
		);
		var dx = (currentX - drawStart.current.x) / zoomFactor;
		var dy = (currentY - drawStart.current.y) / zoomFactor;

		const lockRatio = activeTool == "circle";

		if (lockRatio) {
			dx = Math.max(dx, dy);
			dy = Math.max(dx, dy);
		}

		var { x, y } = getRelativeCoords({ x: dx, y: dy });

		var flag_too_small = false;
		if (currentShape?.geo === "arrow") {
			flag_too_small = Math.sqrt(x * x + y * y) < 0.005;
		} else {
			flag_too_small = Math.abs(x) < 0.005 || Math.abs(y) < 0.005;
		}

		if (endDrawing) {
			if (!lockRatio) {
				if (flag_too_small) {
					x = 0.1;
					y = 0.1;
				}
			} else if (props.metadata.naturalDimensions) {
				const dims = props.metadata.naturalDimensions;

				if (flag_too_small) {
					x = 0.04 / crop.size[0];
					y = (0.04 * (dims.x / dims.y)) / crop.size[1];
				}
			}

			setActiveShapeId(currentDrawingId.current);
		}

		if (Math.abs(y) < 0.001) y = 0.001;
		if (Math.abs(x) < 0.001) x = 0.001;
		updateShape(currentDrawingId.current, {
			size: [x, y],
		});
	};

	const handleBackspaceKey = (event: KeyboardEvent) => {
		if (!activeShapeId) return;

		if (event.key === "Backspace" || event.key === "Delete") {
			deleteShape(activeShapeId);
		}
	};

	useEffect(() => {
		/**
		 * Effect for event handlers
		 */

		const clearListeners = () => {
			window.removeEventListener("mousemove", handleMouseMove);
			window.removeEventListener("mouseup", handleMouseUp);
			window.removeEventListener("keydown", handleBackspaceKey);
		};

		if (panning || drawing) {
			window.addEventListener("mousemove", handleMouseMove);
			window.addEventListener("mouseup", handleMouseUp);
		} else {
			clearListeners();
		}

		window.addEventListener("keydown", handleBackspaceKey);

		return clearListeners;
	}, [panning, panStart, position, activeTool, drawing, activeShapeId]);

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 200);
	}, [position]);

	const togglePreview = () => {
		if (props.srcType !== "video") return;
		if (cp.vidRef?.current) cp.vidRef.current.volume = 0;

		if (cp.paused) {
			cp.hardsetCurrentTime(props.clip.startTime - 0.2);
			cp.play();
		} else cp.pause();
	};

	const updateShape: UpdateShapeFunction = (id, updatedProperties) => {
		const currentShape = shapes.find((s) => s.id === id);

		const newShape = { ...currentShape };

		Object.entries(updatedProperties).forEach(([key, value]) => {
			return ((newShape as any)[key] = value);
		});

		if (!newShape.position || !newShape.size) return;

		setShapes((prevShapes) =>
			prevShapes.map((s) => (s.id === id ? (newShape as Shape) : s)),
		);
	};

	const deleteShape = (shapeId: string) => {
		setShapes((prevShapes) =>
			prevShapes.filter((item) => item.id !== shapeId),
		);
	};

	const activeShape = shapes.find((shape) => shape.id === activeShapeId);

	const onChooseSpeed = (speed: number) => {
		setSpeed(speed);
		setShowSpeedOptions(false);
		cp.setPlaybackRate(speed);
	};

	var device: "laptop" | "mobile" | "tablet" = "laptop";

	if (
		(props.metadata.naturalDimensions &&
			props.metadata.naturalDimensions.y >
				1.2 * props.metadata.naturalDimensions.x) ||
		(cp.vidRef?.current &&
			cp.vidRef.current.videoHeight > 1.2 * cp.vidRef.current.videoWidth)
	)
		device = "mobile";
	else if (
		(props.metadata.naturalDimensions &&
			props.metadata.naturalDimensions.y >
				0.6 * props.metadata.naturalDimensions.x) ||
		(cp.vidRef?.current &&
			cp.vidRef.current.videoHeight > 0.6 * cp.vidRef.current.videoWidth)
	)
		device = "tablet";

	const changeClassListToResize = () => {
		if (screenshotContainer) {
			if (cropping) {
				screenshotContainer?.classList.add("ScreenshotEditor-laptop");
				screenshotContainer?.classList.remove(
					"ScreenshotEditor-mobile",
				);
				screenshotContainer?.classList.remove(
					"ScreenshotEditor-tablet",
				);
				setCrop({ position: [0, 0], size: [1, 1] });
				setTimeout(() => {
					setCrop(savedProps.crop);
				}, 1);
				return;
			}

			if (screenshotContainer.offsetHeight > window.screen.height - 100) {
				screenshotContainer.classList.remove("ScreenshotEditor-laptop");
				screenshotContainer.classList.add("ScreenshotEditor-mobile");
				setCrop({ position: [0, 0], size: [1, 1] });
				setTimeout(() => {
					setCrop(savedProps.crop);
				}, 1);
			} else {
				screenshotContainer.classList.add("ScreenshotEditor-laptop");
				setCrop({ position: [0, 0], size: [1, 1] });
				setTimeout(() => {
					setCrop(savedProps.crop);
				}, 1);
			}
		}
	};

	//3024 x 1568
	return (
		<ScreenshotEditorContext.Provider
			value={{
				//refs
				imgRef: imgRef,

				//state
				zoomFactor: zoomFactor,
				position: position,
				activeShapeId: activeShapeId,
				crop: savedProps.crop,

				//functions
				updateShape: updateShape,
				getFractionalCoords: getFractionalCoords,
				getRelativeCoords: getRelativeCoords,
				setActiveShapeId: setActiveShapeId,
			}}
		>
			<div className={`ScreenshotEditor ss-editor-${props.srcType}`}>
				<div className="ScreenshotEditor-buttons">
					{!props.removeControls && (
						<div className="screenshot-section">
							<Icon
								hoverTip="Zoom Out"
								disabled={cropping}
								onClick={handleZoomOut}
								className="screenshot-icon"
							>
								{zoomoutIcon("white")}
							</Icon>
							<Icon
								hoverTip="Zoom In"
								disabled={cropping}
								onClick={handleZoomIn}
								className={`screenshot-icon`}
							>
								{zoominIcon("white")}
							</Icon>
							{srcType == "img" ? (
								<Icon
									hoverTip="Crop"
									onClick={handleCropping}
									className={`screenshot-icon ${cropping ? "active-tool" : "inactive-tool"}`}
								>
									{cropIcon("white")}
								</Icon>
							) : null}
						</div>
					)}

					{srcType == "img" ? (
						<div className="screenshot-section">
							{tools.map((button) => (
								<Icon
									disabled={cropping}
									key={button.id}
									hoverTip={button.hoverTip}
									className={`screenshot-icon ${activeTool === button.id ? "active-tool" : "inactive-tool"}`}
									onClick={() => {
										setActiveTool(button.id);
									}}
								>
									{button.icon("white")}
								</Icon>
							))}

							<div
								className="color-editor"
								style={{ opacity: activeShapeId ? "1" : "0" }}
							>
								{activeShape && activeShape.geo !== "blur" ? (
									<ShapeCustomizer shape={activeShape} />
								) : null}
							</div>
						</div>
					) : (
						!props.removeControls && (
							<div className="screenshot-section">
								<div className="speed-selector">
									<div>Speed: </div>

									<div className="speed-selector-options">
										<div
											className="speed-selector-selected"
											onClick={() => {
												setShowSpeedOptions(true);
											}}
										>
											{speed} x{" "}
										</div>

										{showSpeedOptions ? (
											<Clickout
												closeFunction={() => {
													setShowSpeedOptions(false);
												}}
											>
												<div className="speed-selector-dropdownmenu">
													{fastSpeeds.map((s) => (
														<div
															className="speed-option"
															onClick={() => {
																onChooseSpeed(
																	s,
																);
															}}
														>
															{s} x
														</div>
													))}
												</div>
											</Clickout>
										) : null}
									</div>
								</div>
							</div>
						)
					)}

					<div className="screenshot-section screenshot-buttons-section">
						{/* <button className='save-button'>Save</button> */}

						{srcType == "video" ? (
							<Cutton
								rank="secondary"
								onClick={togglePreview}
								leftIcon={
									cp.paused
										? circledPlayIcon("white")
										: circledPauseIcon("white")
								}
							>
								Preview
							</Cutton>
						) : null}

						{cropping ? (
							<>
								<Cutton rank="secondary" onClick={cancelCrop}>
									Cancel
								</Cutton>
								<Cutton
									rank="secondary"
									onClick={() => resetCrop(setCrop, initCrop)}
								>
									Reset
								</Cutton>
								<Cutton rank="primary" onClick={saveCrop}>
									Save Crop
								</Cutton>
							</>
						) : (
							<Cutton
								onClick={onSubmit}
								isLoading={submitting}
								loadingText="Submitting ..."
							>
								{props.saveLabel || "Save"}
							</Cutton>
						)}
					</div>
				</div>

				<div
					id={"screenshot-editor"}
					className={`ScreenshotEditor-img-container
                ScreenshotEditor-src-${props.srcType}
                ScreenshotEditor-${device}
                
            `}
					ref={imgRef}
					style={{
						overflow: cropping ? undefined : "hidden",
						//visibility: loading?'hidden':'visible'
					}}
				>
					<div
						className="ScreenshotEditor-img-container-child"
						style={{
							transform: `scale(${zoomFactor}) translate(${position.x}px, ${position.y}px)`,
							cursor:
								activeTool !== "select"
									? "crosshair"
									: panning
										? "grabbing"
										: "grab",
						}}
					>
						{srcType == "img" ? (
							<img
								ref={ssRef}
								crossOrigin="anonymous"
								className={`ScreenshotEditor-img 
                                ${props.srcType == "video" ? "ScreenshotEditor-Gif" : ""}
                                ScreenshotEditor-Gif-${device || "laptop"}
                                ${props.size}
                            `}
								src={root}
								onLoad={() => {
									changeClassListToResize();
									setPosition(
										zoomCenterToPosition(initZoomCenter),
									);
								}}
								onMouseDown={handleMouseDown}
							></img>
						) : (
							<div
								ref={ssRef}
								onMouseDown={handleMouseDown}
								className={`ScreenshotEditor-img ${props.srcType == "video" ? "ScreenshotEditor-Gif" : ""}
                            ScreenshotEditor-Gif-${device}
                            ${props.size}
                        `}
							>
								<CanvasPlayerController
									environment="internal"
									hideFullscreen
									hideScrubber
									hideBigPlay
									hideControls
								/>
							</div>
						)}

						{!loading && props.srcType == "img"
							? shapes.map((shape) => (
									<ImgShape
										key={shape.id}
										shape={shape}
										isCropping={cropping}
									/>
								))
							: null}

						{cropping && (
							<div
								className="ScreenshotEditor-img-selector"
								style={{
									left: `${pixelCrop.position.x}px`,
									top: `${pixelCrop.position.y}px`,
									width: `${pixelCrop.size.x}px`,
									height: `${pixelCrop.size.y}px`,
								}}
							>
								<DragController
									shape={shape}
									zoomFactor={1}
									getRelativeCoords={(pos) =>
										getRelativeCoords(pos)
									}
									updateShape={(_, updtdProps) => {
										updateCrop(crop, setCrop, updtdProps);
									}}
									boundLimits
								/>
								{sizeControls.map((pos) => (
									<SizeControllerThumb
										shape={shape}
										position={pos}
										key={pos}
										zoomFactor={1}
										getRelativeCoords={(pos) => {
											return getRelativeCoords(pos);
										}}
										updateShape={(_, updtdProps) => {
											updateCrop(
												crop,
												setCrop,
												updtdProps,
											);
										}}
										boundLimits
										lockRatio={false}
									/>
								))}
							</div>
						)}
					</div>
				</div>
			</div>
		</ScreenshotEditorContext.Provider>
	);
};
export default ScreenshotEditor;
