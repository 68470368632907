const editorStrikethrough = (color = "#000000") => {
	return (
		<svg
			viewBox="0 0 1024 1024"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill={color}
				d="M898.719 512v70.312H744.383c35.156 75.235 33.398 281.25-219.024 281.25-292.851 1.758-281.601-228.515-281.601-228.515l139.57 1.758c1.055 118.476 111.094 118.476 132.89 117.07 22.5-1.758 106.524-1.406 113.204-83.672 2.812-38.32-35.86-67.5-78.047-87.89H125.281V512H898.72M772.508 367.508l-139.922-1.055s5.976-97.383-115.313-97.734c-121.289-0.703-110.742 77.343-110.742 87.187 1.407 9.844 11.953 58.36 105.469 81.563H290.867S168.172 200.867 467.703 160.437c306.211-42.187 305.508 207.774 304.805 207.07z"
			/>
		</svg>
	);
};

export default editorStrikethrough;
