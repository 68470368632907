const zoomoutIcon = (color = "#ffffff") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 39 42"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M29.4271 28.348L39 37.9979L35.0838 42L25.0758 31.857C22.6286 33.2273 19.8002 33.9958 16.8622 33.9958C7.56085 33.9958 0 26.3741 0 16.9979C0 7.62169 7.56085 0 16.8622 0C26.1636 0 33.7784 7.62169 33.7784 16.9979C33.7784 21.3842 32.0918 25.3863 29.4271 28.348ZM4.68028 16.9979C4.68028 23.7967 10.1194 29.2252 16.864 29.2252C23.5546 29.2252 29.0477 23.7967 29.0477 16.9979C29.0477 10.1991 23.5546 4.77057 16.864 4.77057C10.1194 4.77057 4.68028 10.1991 4.68028 16.9979ZM18.8221 15.0785H25.5666V18.9718H18.8221H18H16.5H14.9598H8.26925V15.0785H14.9598H16.864H18H18.8221Z"
				fill={color}
			/>
		</svg>
	);
};
export default zoomoutIcon;
