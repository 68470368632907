import {
	useActive,
	useChainedCommands,
	useCommands,
	useEditorView,
	useRemirrorContext,
} from "@remirror/react";
import React, { useEffect, useRef, useState, MutableRefObject } from "react";
// import { orderedList } from '../../../../../../assets/svgs/text-editor-toolbar/ol';
import bold from "../../../../../../assets/svgs/text-editor-toolbar/editor-bold";
import italic from "../../../../../../assets/svgs/text-editor-toolbar/editor-italic";
import underline from "../../../../../../assets/svgs/text-editor-toolbar/editor-underline";
import strikethrough from "../../../../../../assets/svgs/text-editor-toolbar/editor-strikethrough";

import ToolbarButton from "../ToolbarButton/ToolbarButton";
import ListOptions from "../ListOptions/ListOptions";
import BlockquoteButton from "../BlockquoteButton/BlockquoteButton";
import HRButton from "../HRButton/HRButton";
import { useAppDispatch } from "../../../../../../redux";
import { openOverlay } from "../../../../../../layouts/Overlay";
import LinkPopup from "../LinkPopup/LinkPopup";
import externalLinkIcon from "../../../../../../assets/svgs/externalLinkIcon";
import link from "../../../../../../assets/svgs/text-editor-toolbar/link";
import ScreenshotDropdown from "../ScreenshotDropdown/ScreenshotDropdown";
import AlignmentDropdown from "../AlignmentDropdown/AlignmentDropdown";

type TextStyleOptionsProps = {
	disabled?: boolean;
};

const TextStyleOptions: React.FC<TextStyleOptionsProps> = (props) => {
	const dispatch = useAppDispatch();
	const active = useActive();
	const commands = useChainedCommands();
	const view = useEditorView();
	const items = [
		{
			label: "bold",
			active: () => active.bold(),
			handler: () => commands.toggleBold().focus().run(),
			hoverTip: {
				text: "Bold",
				shortcut: "CmdOrCtrl+B",
			},
			source: bold("white"),
		},
		{
			label: "italic",
			active: () => active.italic(),
			handler: () => commands.toggleItalic().focus().run(),
			hoverTip: {
				text: "Italic",
				shortcut: "CmdOrCtrl+I",
			},
			source: italic("white"),
		},
		{
			label: "underline",
			active: () => active.underline(),
			handler: () => commands.toggleUnderline().focus().run(),
			hoverTip: {
				text: "Underline",
				shortcut: "CmdOrCtrl+U",
			},
			source: underline("white"),
		},
		{
			label: "strikethrough",
			active: () => active.strike(),
			handler: () => commands.toggleStrike().focus().run(),
			hoverTip: {
				text: "Strikethrough",
				shortcut: "CmdOrCtrl+D",
			},
			source: strikethrough("white"),
		},
	];

	return (
		<div style={{ display: "flex", alignItems: "center" }}>
			{items.map((x) => (
				<ToolbarButton
					active={x.active}
					handler={x.handler}
					hoverTip={x.hoverTip.text}
					source={x.source}
					disabled={props.disabled}
				/>
			))}
			<ToolbarButton
				disabled={props.disabled}
				active={() => active.link()}
				handler={() => {
					const text = view.state.doc.textBetween(
						view.state.selection.from,
						view.state.selection.to,
					);
					dispatch(
						openOverlay({
							heading: {
								icon: null,
								title: "Link Details",
								data: "",
							},
							component: (
								<LinkPopup
									text={text}
									currentLink={""}
									save={(newText, newLink) => {
										if (newLink) {
											if (newLink.includes("https"))
												commands
													.replaceText({
														content: newText,
													})
													.updateLink({
														href: newLink,
													})
													.run();
											else
												commands
													.replaceText({
														content: newText,
													})
													.updateLink({
														href: "#0",
														guideId: newLink,
													})
													.run();
										}
									}}
									delete={() => commands.removeLink().run()}
								></LinkPopup>
							),
						}),
					);
				}}
				hoverTip="Link"
				source={link("white")}
			></ToolbarButton>
			<AlignmentDropdown disabled={props.disabled}></AlignmentDropdown>
			<ListOptions disabled={props.disabled}></ListOptions>
			<BlockquoteButton disabled={props.disabled}></BlockquoteButton>
			<HRButton disabled={props.disabled}></HRButton>
			<ScreenshotDropdown disabled={props.disabled}></ScreenshotDropdown>
		</div>
	);
};
export default TextStyleOptions;
