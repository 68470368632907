const f = (color = "#000000") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 800 800"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M400 25C236.25 25 97.5 130 46.25 275H753.75C702.5 130 563.75 25 400 25Z"
				fill="#ED4C5C"
			/>
			<path
				d="M400 775C563.75 775 702.5 671.25 753.75 525H46.25C97.5 671.25 236.25 775 400 775Z"
				fill="#83BF4F"
			/>
			<path
				d="M46.25 275C32.5 313.75 25 356.25 25 400C25 443.75 32.5 486.25 46.25 525H753.75C767.5 486.25 775 443.75 775 400C775 356.25 767.5 313.75 753.75 275H46.25Z"
				fill="#F9F9F9"
			/>
		</svg>
	);
};
export default f;
