import React, { useContext, useState } from "react";
import FolderSectionComponent from "./FolderSection";

import "./FolderAccordion.css";

import { GuideNode, Tree } from "../../../../redux/slices/hcSlice";
import { Collection } from "@gigauser/common/src/core/types/collections";
import { useAppSelector } from "../../../../redux";
import useOpenGuide from "../../../../hooks/useOpenGuide";
import { rootCollection } from "@gigauser/common/src/types/files";

type FolderAccordionProps = {
	tree: Tree;
	parentSegments: Collection[];
};

const FolderAccordion: React.FC<FolderAccordionProps> = (props) => {
	const currentGuide = useAppSelector((state) => state.hc.currentGuide);
	const openGuide = useOpenGuide();

	return (
		<div className="FolderAccordion">
			{props.tree.map((node) => {
				if (node.type === "guide") {
					const segments = [rootCollection, ...props.parentSegments];
					var isPrivate = false;
					segments.map((collection) => {
						if (collection.private) isPrivate = true;
					});

					return (
						<div
							style={{
								display:
									node.guide.isPinned || isPrivate
										? "none"
										: "block",
							}}
							key={node.guide.id}
							onClick={() => {
								openGuide(
									node.guide,
									segments as [Collection, ...Collection[]],
								);
							}}
							className={`accordion-guide accordion-item ${currentGuide && currentGuide.id === node.guide.id ? "accordion-active-item" : "accordion-inactive-item"}`}
						>
							<div className="accordion-guide-name">
								{node.guide.header.name}
							</div>
						</div>
					);
				} else {
					return (
						<FolderSectionComponent
							key={node.collection.id}
							node={node}
							parentSegments={props.parentSegments}
						></FolderSectionComponent>
					);
				}
			})}

			{props.tree.length === 0 && (
				<div className="no-folders">
					<i>Empty collection</i>
				</div>
			)}
		</div>
	);
};
export default FolderAccordion;
