export const loudspeakerIcon = (color?: string) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="0 0 16 17"
			fill="none"
		>
			<path
				opacity="0.2"
				d="M8.5 4.64367V11.0074L2.64 12.8049C2.56553 12.8266 2.48703 12.8307 2.41071 12.8169C2.33439 12.803 2.26233 12.7716 2.20025 12.7251C2.13816 12.6786 2.08775 12.6183 2.053 12.5489C2.01824 12.4796 2.0001 12.4031 2 12.3255V3.32555C2.0001 3.24798 2.01824 3.17149 2.053 3.10215C2.08775 3.0328 2.13816 2.97249 2.20025 2.92598C2.26233 2.87948 2.33439 2.84806 2.41071 2.83421C2.48703 2.82036 2.56553 2.82445 2.64 2.84617L8.5 4.64367Z"
				fill={color ?? "currentColor"}
			/>
			<path
				d="M13.7837 5.74215L2.78 2.36715C2.63107 2.32371 2.47407 2.31552 2.32142 2.34322C2.16878 2.37093 2.02467 2.43377 1.9005 2.52677C1.77633 2.61978 1.6755 2.7404 1.60599 2.8791C1.53648 3.01779 1.50019 3.17076 1.5 3.3259V12.3259C1.5 12.5911 1.60536 12.8455 1.79289 13.033C1.98043 13.2205 2.23478 13.3259 2.5 13.3259C2.59562 13.3259 2.69076 13.3123 2.7825 13.2853L8 11.684V12.3259C8 12.5911 8.10536 12.8455 8.29289 13.033C8.48043 13.2205 8.73478 13.3259 9 13.3259H11C11.2652 13.3259 11.5196 13.2205 11.7071 13.033C11.8946 12.8455 12 12.5911 12 12.3259V10.4571L13.7837 9.91027C13.9902 9.84825 14.1712 9.72152 14.3002 9.54879C14.4292 9.37606 14.4992 9.16646 14.5 8.9509V6.7009C14.4991 6.48545 14.429 6.27599 14.3 6.10338C14.1711 5.93077 13.9901 5.80415 13.7837 5.74215ZM8 10.6384L2.5 12.3259V3.3259L8 5.0134V10.6384ZM11 12.3259H9V11.3771L11 10.7634V12.3259ZM13.5 8.9509H13.4931L9 10.3309V5.3209L13.4931 6.6959H13.5V8.9459V8.9509Z"
				fill={color ?? "currentColor"}
			/>
		</svg>
	);
};
