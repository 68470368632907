const f = (color = "#000000") => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="0 0 64 64"
			aria-hidden="true"
			role="img"
			preserveAspectRatio="xMidYMid meet"
		>
			<g fill="#ffffff">
				<path d="M61.7 28c-.1-1-.3-2-.5-3H2.8c-.2 1-.4 2-.5 3h59.4"></path>

				<path d="M2.3 36c.1 1 .3 2 .5 3h58.3c.2-1 .4-2 .5-3H2.3"></path>

				<path d="M20 4.5c-1 .4-2 1-3 1.5v52c1 .6 2 1.1 3 1.5v-55"></path>

				<path d="M28 2.3v59.5l3 .3V2c-1 0-2 .1-3 .3"></path>
			</g>

			<g fill="#428bc1">
				<path d="M2 32c0 1.4.1 2.7.3 4h59.5c.2-1.3.3-2.6.3-4s-.1-2.7-.3-4H2.3c-.2 1.3-.3 2.6-.3 4"></path>

				<path d="M20 59.5c2.5 1.1 5.2 1.9 8 2.2V2.3c-2.8.4-5.5 1.1-8 2.2v55"></path>
			</g>

			<g fill="#ed4c5c">
				<path d="M17 25V6C10 10.1 4.8 16.9 2.8 25H17z"></path>

				<path d="M17 39v19C10 53.9 4.8 47.1 2.8 39H17z"></path>

				<path d="M32 2h-1v23h30.2C58 11.8 46.2 2 32 2z"></path>

				<path d="M31 39v23h1c14.2 0 26-9.8 29.2-23H31"></path>
			</g>
		</svg>
	);
};
export default f;
