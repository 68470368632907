const paintbucketIcon = (color = "#ffffff") => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="0 0 44 42"
			fill="none"
		>
			<path
				d="M12.6384 0.600905C12.4455 0.404638 12.2076 0.249199 11.9414 0.145536C11.6752 0.0418725 11.3873 -0.00749198 11.0978 0.00091988C10.5173 0.00843379 9.96361 0.224086 9.55728 0.600905C9.34381 0.778884 9.17338 0.995234 9.05679 1.23623C8.94021 1.47723 8.88 1.73761 8.88 2.00087C8.88 2.26413 8.94021 2.52451 9.05679 2.76551C9.17338 3.00651 9.34381 3.22286 9.55728 3.40084L15.8296 9.0007L1.30423 22.1004C0.891595 22.4639 0.563525 22.8991 0.339485 23.3801C0.115445 23.8611 0 24.378 0 24.9003C0 25.4226 0.115445 25.9396 0.339485 26.4205C0.563525 26.9015 0.891595 27.3367 1.30423 27.7002L15.8296 40.7999C16.2243 41.1823 16.7018 41.4862 17.232 41.6928C17.7623 41.8993 18.3338 42.0038 18.9107 41.9999C19.4866 41.998 20.0562 41.8907 20.5856 41.6846C21.1149 41.4784 21.5932 41.1776 21.9919 40.7999L39.5984 24.9003L12.6384 0.600905ZM4.49541 24.9003L18.9107 11.9006L33.216 24.9003H4.49541Z"
				fill={color}
			/>
			<path
				d="M39.5984 30.0002C39.5984 30.0002 35.1967 35.8001 35.1967 38C35.1967 39.0609 35.6605 40.0783 36.486 40.8284C37.3114 41.5785 38.431 42 39.5984 42C40.7658 42 41.8853 41.5785 42.7108 40.8284C43.5363 40.0783 44 39.0609 44 38C44 35.8001 39.5984 30.0002 39.5984 30.0002Z"
				fill={color}
			/>
		</svg>
	);
};
export default paintbucketIcon;
