export const getDomain = () => {
	const url = new URL(window.location.href);

	if (url.origin.includes("localhost")) {
		return "";
	}

	const arr = url.host.split(".");
	return `${arr[arr.length - 2]}.${arr[arr.length - 1]}`;
};

export function getFullDomain() {
	const url = window.location.href;
	if (url.indexOf("://") > -1) {
		const protocolEnd = url.indexOf("://") + 3;
		const domainEnd = url.indexOf("/", protocolEnd);
		if (domainEnd > -1) {
			return url.substring(protocolEnd, domainEnd);
		} else {
			return url.substring(protocolEnd);
		}
	}
	return null;
}

export const getPlatformPage: () => "editor" | "home" = () => {
	const url = window.location.href;
	if (url.includes("/guide")) {
		return "editor";
	}
	return "home";
};

export const getURLSearchParam = (searchParam: string) => {
	let params = new URLSearchParams(document.location.search);
	return params.get(searchParam);
};

export const openExternalLink = async (url: string) => {
	const a = document.createElement("a");
	a.href = url;
	a.target = "_blank";
	a.click();
	a.remove();
};

export const ExternalLink = (props: {
	href: string;
	children: React.ReactNode;
}) => {
	/**
	 * A react component to be used in place of <a> that implements openExternalLink
	 */

	return (
		<a
			onClick={() => {
				openExternalLink(props.href);
			}}
		>
			{props.children}
		</a>
	);
};
