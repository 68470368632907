import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@gigauser/common/src/redux/store";
import { rootCollection } from "@gigauser/common/src/types/files";
import { Collection } from "@gigauser/common/src/core/types/collections";

// Define a type for the slice state
interface CollectionPathState {
	value: {
		segments: [Collection, ...Collection[]];
		loading: Boolean;
	};
}

// Define the initial state using that type
const initialState: CollectionPathState = {
	value: {
		segments: [rootCollection],
		loading: true,
	},
};
// Both web and electron will have to dispatch an initial update
// To load an initial path

export const collectionPathSlice = createSlice({
	name: "collectionPath",
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		updateCollectionPath: (
			state,
			action: PayloadAction<[Collection, ...Collection[]]>,
		) => {
			state.value.segments = action.payload;
			state.value.loading = false;
		},
		loadingCollectionPath: (state) => {
			state.value.loading = true;
		},
		editCollectionInPath: (state, action: PayloadAction<Collection>) => {
			//called when folder is edited from the topbar in the home screen
			const editedCollection = action.payload;

			state.value.segments = state.value.segments.map((collection) => {
				if (collection.id == editedCollection.id)
					return editedCollection;
				else return collection;
			}) as [Collection, ...Collection[]];
		},
	},
});

export const {
	updateCollectionPath,
	loadingCollectionPath,
	editCollectionInPath,
} = collectionPathSlice.actions;
// Other code such as selectors can use the imported `RootState` type
export const selectCollectionPath = (state: RootState) =>
	state.collectionPath.value;
export default collectionPathSlice.reducer;
