import { Node } from "@remirror/pm/model";
import {
	useActive,
	useChainedCommands,
	useCommands,
	useRemirrorContext,
} from "@remirror/react";
import React, { useEffect, useRef, useState, MutableRefObject } from "react";
import { saver } from "../../../../../../network/saver";
import { useAppSelector } from "../../../../../../redux";
import { selectGuidePreview } from "../../../../../../redux/slices/guideSlice";
import { GuidePreview } from "../../../../../../core/types/guide";
import useAddScreenshot from "../../AddScreenshot/useAddScreenshot";
import "./ScreenshotDropdown.css";
import Icon from "../../../../../../ui/Icon/Icon";
import imagePlus from "../../../../../../assets/svgs/imagePlus";

type ScreenshotDropdownProps = {
	disabled?: boolean;
};

const ScreenshotDropdown: React.FC<ScreenshotDropdownProps> = (props) => {
	const fileInputRef = useRef<HTMLInputElement>(null);
	const { commands, manager } = useRemirrorContext();
	const guidePreview = useAppSelector(selectGuidePreview) as GuidePreview;
	const originalVideo = useAppSelector(
		(state) => state.guide.value.guide?.guideData.video.originalSrc,
	) as string;
	const addScreenshot = useAddScreenshot("textEditor");
	const handleFileUpload = async (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		const file = event.target.files?.[0];
		if (file) {
			// Call your function here with the file data
			// Example: uploadImage(file);
			// Define the command to insert the screenshot
			// Replace uri with the actual image uri

			// Function to convert blob to data uri

			//Get format of file
			const format = file.name.split(".")[1];
			var reader = new FileReader();
			reader.onload = async function () {
				var uri = reader.result as string;
				const resp = await saver.uploadImage(guidePreview.id, uri);
				const node: Node = manager.schema.nodes.image.create({
					src: uri,
					metadata: {
						stepId: Date.now().toString(),
						s3ObjectId: resp,
						format: format,
					},
				});
				commands.insertNode(node);
				// Now you can use the dataURI as needed, for example, setting it as the source of an image or a link.
			};

			reader.readAsDataURL(file);
		}
	};

	return (
		<div className="dark-app-texteditor-toolbar-dropdown gigauser-texteditor-toolbar-blocktype-dropdown">
			{/* Replace text with an image icon */}
			<div className="dark-app-texteditor-toolbar-dropdown-heading">
				<Icon
					disabled={props.disabled}
					className={`gigauser-texteditor-toolbar-button`}
				>
					{imagePlus("white")}
				</Icon>
			</div>

			<div
				className="dark-app-texteditor-toolbar-dropdown-content"
				style={{
					marginTop: originalVideo ? "120px" : "80px",
					display: props.disabled ? "none" : undefined,
				}}
			>
				{/* Check if originalVideo exists before rendering */}
				{originalVideo && (
					<div
						className="gigauser-texteditor-toolbar-blocktype-dropdown-content-item dark-app-texteditor-toolbar-dropdown-content-item"
						onClick={
							props.disabled
								? () => {}
								: (e) => {
										e.preventDefault();
										addScreenshot();
									}
						}
					>
						Choose from video
					</div>
				)}

				<div
					className="gigauser-texteditor-toolbar-blocktype-dropdown-content-item dark-app-texteditor-toolbar-dropdown-content-item"
					onClick={(e) => {
						e.preventDefault();
						fileInputRef.current?.click();
					}}
				>
					Upload from computer
				</div>
			</div>

			<input
				type="file"
				accept="image/*"
				ref={fileInputRef}
				style={{ display: "none" }}
				onChange={handleFileUpload}
			/>
		</div>
	);
};

export default ScreenshotDropdown;
