import {
	useActive,
	useChainedCommands,
	useCommands,
	useRemirrorContext,
} from "@remirror/react";
import React, { useEffect, useRef, useState, MutableRefObject } from "react";
import Icon from "../../../../../../ui/Icon/Icon";
// import { orderedList } from '../../../../../../assets/svgs/text-editor-toolbar/ol';
import blockquote from "../../../../../../assets/svgs/text-editor-toolbar/blockquote";
import ToolbarButton from "../ToolbarButton/ToolbarButton";
import "./BlockquoteButton.css";
type BlockquoteButtonProps = {
	disabled?: boolean;
};

const BlockquoteButton: React.FC<BlockquoteButtonProps> = (props) => {
	// const active = useActive();
	const commands = useChainedCommands() as any;
	const handler = (color?: string) => {
		commands.toggleBlockquote(color).run();
	};

	const active = () => false;
	// taskList: {
	//     label: "taskList",
	//     active: () => false,
	//     handler: () => {
	//         commands.toggleTaskList().focus().run();
	//     },
	//     hoverTip: {
	//         text: "Task List",
	//     },
	// }

	const srcImg = blockquote("white");

	return (
		<>
			<div className="gigauser-texteditor-toolbar-blockquote-dropdown">
				<ToolbarButton
					disabled={props.disabled}
					active={active}
					handler={() => handler()}
					source={srcImg}
				/>
				<div
					className="gigauser-texteditor-toolbar-blockquote-dropdown-content"
					style={{
						display: props.disabled ? "none" : undefined,
					}}
				>
					<ToolbarButton
						disabled={props.disabled}
						active={active}
						handler={() => handler("gray")}
						source={blockquote()}
						backgroundColor={"#f2f2f7"}
					/>
					<ToolbarButton
						disabled={props.disabled}
						active={active}
						handler={() => handler("blue")}
						source={blockquote()}
						backgroundColor={"#ddf7ff"}
					/>
					<ToolbarButton
						disabled={props.disabled}
						active={active}
						handler={() => handler("green")}
						source={blockquote()}
						backgroundColor={"rgb(217, 248, 217)"}
					/>

					<ToolbarButton
						disabled={props.disabled}
						active={active}
						handler={() => handler("yellow")}
						source={blockquote()}
						backgroundColor={"rgb(250, 240, 200)"}
					/>
					<ToolbarButton
						disabled={props.disabled}
						active={active}
						handler={() => handler("red")}
						source={blockquote()}
						backgroundColor={"rgb(256, 220, 220)"}
					/>
				</div>
			</div>
		</>
	);
};
export default BlockquoteButton;
