export type Speed = 0.25 | 0.5 | 0.75 | 1 | 1.25 | 1.5 | 1.75 | 2;

export const defaultSpeed: Speed = 1;

const _slowSpeeds: Speed[] = [0.25, 0.5, 0.75];
const _fastSpeeds: Speed[] = [1.25, 1.5, 1.75, 2];

export const slowSpeeds: Speed[] = [..._slowSpeeds, defaultSpeed];
export const fastSpeeds: Speed[] = [defaultSpeed, ..._fastSpeeds];
export const speeds: Speed[] = [..._slowSpeeds, defaultSpeed, ..._fastSpeeds];
