export const flyingSendIcon = (color?: string) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="0 0 16 17"
			fill="none"
		>
			<path
				d="M13.9918 7.77661L3.49182 1.78349C3.31483 1.68421 3.11182 1.64112 2.90976 1.65996C2.7077 1.6788 2.51615 1.75866 2.36056 1.88896C2.20497 2.01925 2.09271 2.19379 2.03868 2.3894C1.98465 2.58502 1.99141 2.79244 2.05807 2.98411L3.99557 8.63849C3.99532 8.64057 3.99532 8.64266 3.99557 8.64474C3.99522 8.64681 3.99522 8.64892 3.99557 8.65099L2.05807 14.3179C2.00469 14.4686 1.98825 14.63 2.01014 14.7885C2.03203 14.9469 2.09161 15.0978 2.18388 15.2285C2.27615 15.3591 2.39841 15.4657 2.54041 15.5394C2.68241 15.613 2.83999 15.6515 2.99995 15.6516C3.17349 15.6512 3.34398 15.606 3.49495 15.5204L13.9893 9.51724C14.1441 9.43052 14.2731 9.30418 14.3629 9.15118C14.4528 8.99818 14.5003 8.82402 14.5006 8.64659C14.5009 8.46916 14.454 8.29483 14.3647 8.14151C14.2754 7.98819 14.1469 7.86138 13.9924 7.77411L13.9918 7.77661ZM2.99995 14.6516V14.646L4.8837 9.15161H8.49995C8.63255 9.15161 8.75973 9.09894 8.8535 9.00517C8.94727 8.9114 8.99995 8.78422 8.99995 8.65161C8.99995 8.51901 8.94727 8.39183 8.8535 8.29806C8.75973 8.20429 8.63255 8.15161 8.49995 8.15161H4.8887L3.0037 2.65911L2.99995 2.65161L13.4999 8.64099L2.99995 14.6516Z"
				fill={color ?? "currentColor"}
			/>
		</svg>
	);
};
