const pauseIcon = (color: string = "#000000") => {
	return (
		// <svg width="100%" height="100%" viewBox="0 0 225 225" fill="none" xmlns="http://www.w3.org/2000/svg">
		//     <circle cx="112.5" cy="112.5" r="105.5" stroke={color} strokeWidth="14"/>
		//     <rect x="75" y="68" width="30" height="89" rx="5" fill={color}/>
		//     <rect x="121" y="68" width="30" height="89" rx="5" fill={color}/>
		// </svg>

		<svg
			width="79%"
			height="100%"
			viewBox="0 0 13 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				y="0.545456"
				width="4.72727"
				height="18.9091"
				rx="2.36364"
				fill={color}
			></rect>
			<rect
				x="8.27274"
				y="0.545456"
				width="4.72727"
				height="18.9091"
				rx="2.36364"
				fill={color}
			></rect>
		</svg>
	);
};

export default pauseIcon;
