import { Collection } from "../core/types/collections";
// import { Id } from "../core/types/baseTypes"
import { GigaUserApi } from "@giga-user-fern/api";

import { GuidePreview } from "../core/types/guide";

export type Item =
	| {
			type: "Collection";
			entry: Collection;
	  }
	| {
			type: "Guide";
			entry: GuidePreview;
	  };

export type FileType = "Folder" | "Reading" | "Writing";

const rootCollection: Collection = {
	id: GigaUserApi.baseTypes.Id("Collection_root"),
	name: "Projects",
	sequenceNumber: 1,
	parentId: undefined,
};

const unravelItem: (item: Item) => {
	name: string;
	description?: string;
	parentId?: GigaUserApi.Id;
} = (item) => {
	var name: string = "";
	var description: string | undefined;
	var parentId: GigaUserApi.Id | undefined;

	if (item.type === "Collection") {
		name = item.entry.name;
		description = item.entry.description;
		parentId = item.entry.parentId;
	} else if (item.type === "Guide") {
		name = item.entry.header.name;
		description = item.entry.header.description;
		parentId = item.entry.parentId;
	}

	return {
		name,
		description,
		parentId,
	};
};

export { rootCollection, unravelItem };
