import { Background, Cover } from "@giga-user-fern/api/types/api";

export const DEFAULT_BACKGROUND_SRC =
	"https://clueso-public-assets.s3.ap-south-1.amazonaws.com/e1559718-994c-44eb-9c82-cd1c34a1e780/video_assets/Bookend.png";

// export interface Cover {

//     visible: boolean,
//     duration: number,

//     text1?: string,
//     text2?:string,
//     showLogo?: boolean,
//     logoPosition?: FracPosition,
//     logoSize?:number,
//     intersectionDuration?: number,
//     backgroundSrc?:string,

// }

// export interface Background{
//     visible:boolean,
//     padding?: number,
//     borderRadius?:number,
//     shadow?:number,
//     src:string,
// }

export const defaultIntroCover: Cover = {
	visible: true,
	showLogo: true,
	// logoPosition: {x: 0.05, y: 0.1},
	duration: 3,
	intersectionDuration: 0,
	logoSize: 75,
	text1: "Getting started with Desklamp",
	backgroundSrc: DEFAULT_BACKGROUND_SRC,
};

export const emptyIntroCover: Cover = {
	...defaultIntroCover,
	visible: false,
};

export const defaultOutroCover: Cover = {
	visible: true,
	showLogo: true,
	logoPosition: { x: 0.5, y: 0.1 },
	duration: 3,
	intersectionDuration: 0,
	text1: "For more help visit ...",
	text2: "help.myapp.com",
	logoSize: 65,
	backgroundSrc: DEFAULT_BACKGROUND_SRC,
};

export const emptyOutroCover: Cover = {
	...defaultOutroCover,
	visible: false,
};

export const defaultBackground: Background = {
	visible: true,
	padding: 8,
	borderRadius: 0,
	shadow: 30,
	src: DEFAULT_BACKGROUND_SRC,
};

export const emptyBackground: Background = {
	...defaultBackground,
	visible: false,
};
