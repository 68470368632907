const f = (color = "#000000") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 200 200"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="mask0_1073_28"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="200"
				height="200"
			>
				<circle cx="100" cy="100" r="100" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_1073_28)">
				<path d="M300 0H0V200H300V0Z" fill="#DE2910" />
				<path
					d="M49.9998 20L67.6338 74.27L21.4678 40.729H78.5318L32.3668 74.27L49.9998 20Z"
					fill="#FFDE00"
				/>
				<path
					d="M91.4248 25.1449L103.913 10.7969L102.243 29.7449L92.4568 13.4349L109.962 20.8779L91.4248 25.1449Z"
					fill="#FFDE00"
				/>
				<path
					d="M110.101 41.414L127.178 33.037L118.286 49.852L115.596 31.022L128.84 44.675L110.101 41.414Z"
					fill="#FFDE00"
				/>
				<path
					d="M110.385 67.2529L129.394 66.5709L114.416 78.2949L119.642 60.0059L126.164 77.8739L110.385 67.2529Z"
					fill="#FFDE00"
				/>
				<path
					d="M92.191 83.7531L109.989 90.4641L91.646 95.496L103.528 80.6431L102.646 99.6431L92.191 83.7531Z"
					fill="#FFDE00"
				/>
			</g>
		</svg>
	);
};
export default f;
