const dk = (color = "#000000") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 226 226"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="mask0_1072_9"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="226"
				height="226"
			>
				<circle cx="113" cy="113" r="113" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_1072_9)">
				<path d="M262.5 -0.5H-37.5V225.5H262.5V-0.5Z" fill="#C60C30" />
				<path d="M91.145 -0.5H58.791V225.5H91.145V-0.5Z" fill="white" />
				<path
					d="M262.5 128.677L262.5 96.3228L-37.5 96.3228L-37.5 128.677L262.5 128.677Z"
					fill="white"
				/>
			</g>
		</svg>
	);
};

export default dk;
