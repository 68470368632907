import type { GetStaticAndDynamic } from "@remirror/core";
import { BidiExtension, BidiOptions } from "@remirror/extension-bidi";
import { BlockquoteExtension } from "@remirror/extension-blockquote";
import { BoldExtension, BoldOptions } from "@remirror/extension-bold";
import { CodeExtension } from "@remirror/extension-code";
import {
	CodeBlockExtension,
	CodeBlockOptions,
} from "@remirror/extension-code-block";
import {
	DropCursorExtension,
	DropCursorOptions,
} from "@remirror/extension-drop-cursor";
import { IframeExtension } from "@remirror/extension-embed";
import { GapCursorExtension } from "@remirror/extension-gap-cursor";
import { HardBreakExtension } from "@remirror/extension-hard-break";
import { HeadingExtension, HeadingOptions } from "@remirror/extension-heading";
import { HorizontalRuleExtension } from "@remirror/extension-horizontal-rule";
import { ImageExtension } from "@remirror/extension-image";
import { ItalicExtension } from "@remirror/extension-italic";
import { MarkdownExtension } from "@remirror/extension-markdown";
import { LinkExtension, LinkOptions } from "@remirror/extension-link";
import {
	BulletListExtension,
	OrderedListExtension,
	TaskListExtension,
} from "@remirror/extension-list";
import { ShortcutsExtension } from "@remirror/extension-shortcuts";
import { StrikeExtension } from "@remirror/extension-strike";
import {
	TrailingNodeExtension,
	TrailingNodeOptions,
} from "@remirror/extension-trailing-node";
import { UnderlineExtension } from "@remirror/extension-underline";
import { SpecialImageExtension } from "../extensions/SpecialImageExtension";
import { SpecialBlockquoteExtension } from "../extensions/SpecialBlockQuoteExtension";
import { NodeFormattingExtension } from "remirror/extensions";
import css from "refractor/lang/css.js";
import javascript from "refractor/lang/javascript.js";
import json from "refractor/lang/json.js";
import markdown from "refractor/lang/markdown.js";
import typescript from "refractor/lang/typescript.js";
import xml from "refractor/lang/xml-doc";
import java from "refractor/lang/java";
import kotlin from "refractor/lang/kotlin";
import swift from "refractor/lang/swift";
import dart from "refractor/lang/dart";
export interface WysiwygOptions
	extends BidiOptions,
		BoldOptions,
		CodeBlockOptions,
		DropCursorOptions,
		HeadingOptions,
		LinkOptions,
		TrailingNodeOptions {}

const DEFAULT_OPTIONS = {
	...BidiExtension.defaultOptions,
	...BoldExtension.defaultOptions,
	...CodeBlockExtension.defaultOptions,
	...DropCursorExtension.defaultOptions,
	...TrailingNodeExtension.defaultOptions,
	...HeadingExtension.defaultOptions,
};

/**
 * Create the wysiwyg preset which includes all the more exotic extensions
 * provided by the `remirror` core library.
 */

//TODO: Might want to make a copy and use only some of these. They have keyboard shortcuts.
export function getExtensions(
	options: GetStaticAndDynamic<WysiwygOptions> = {},
): WysiwygPreset[] {
	options = { ...DEFAULT_OPTIONS, ...options };

	const gapCursorExtension = new GapCursorExtension();
	const hardBreakExtension = new HardBreakExtension();
	const horizontalRuleExtension = new HorizontalRuleExtension();
	const italicExtension = new ItalicExtension();
	const strikeExtension = new StrikeExtension();
	const underlineExtension = new UnderlineExtension();
	const blockquoteExtension = new SpecialBlockquoteExtension();
	const codeExtension = new CodeExtension();
	const iframeExtension = new IframeExtension();
	const bulletListExtension = new BulletListExtension();
	const orderedListExtension = new OrderedListExtension();
	const taskListExtension = new TaskListExtension();
	const shortcutsExtension = new ShortcutsExtension();
	const { selectTextOnClick } = options;

	const { autoUpdate, defaultDirection, excludeNodes } = options;
	const bidiExtension = new BidiExtension({
		autoUpdate,
		defaultDirection,
		excludeNodes,
	});

	const { weight } = options;
	const boldExtension = new BoldExtension({ weight });

	const { defaultLanguage, formatter, toggleName, syntaxTheme } = options;
	const codeBlockExtension = new CodeBlockExtension({
		defaultLanguage,
		formatter,
		toggleName,
		syntaxTheme: syntaxTheme, // "base16_ateliersulphurpool_light" @Akash play with strings here and pick the ones you like best.
		supportedLanguages: [
			css,
			javascript,
			json,
			markdown,
			typescript,
			xml,
			kotlin,
			swift,
			dart,
		],
	});

	const { color, width } = options;
	const dropCursorExtension = new DropCursorExtension({
		color,
		width,
	});

	const { defaultLevel, levels } = options;
	const headingExtension = new HeadingExtension({ defaultLevel, levels });

	const { disableTags, ignoredNodes, nodeName } = options;
	const trailingNodeExtension = new TrailingNodeExtension({
		disableTags,
		ignoredNodes,
		nodeName,
	});

	return [
		// Plain
		bidiExtension,
		dropCursorExtension,
		gapCursorExtension,
		shortcutsExtension,
		trailingNodeExtension,
		new NodeFormattingExtension(),
		// Nodes
		hardBreakExtension,
		horizontalRuleExtension,
		blockquoteExtension,
		codeBlockExtension,
		headingExtension,
		iframeExtension,
		bulletListExtension,
		orderedListExtension,
		taskListExtension,

		// Marks
		boldExtension,
		codeExtension,
		strikeExtension,
		italicExtension,
		underlineExtension,
		new MarkdownExtension(),
	];
}

/**
 * The union of types for all the extensions provided by the `wysiwygPreset`
 * function call.
 */
export type WysiwygPreset =
	| GapCursorExtension
	| HardBreakExtension
	| HorizontalRuleExtension
	| SpecialImageExtension
	| ItalicExtension
	| StrikeExtension
	| UnderlineExtension
	| SpecialBlockquoteExtension
	| CodeExtension
	| LinkExtension
	| BidiExtension
	| BoldExtension
	| CodeBlockExtension
	| DropCursorExtension
	| HeadingExtension
	| TrailingNodeExtension
	| IframeExtension
	| BulletListExtension
	| OrderedListExtension
	| TaskListExtension
	| ShortcutsExtension
	| NodeFormattingExtension
	| MarkdownExtension;
