export default (
	<svg
		width="100%"
		height="79%"
		viewBox="0 0 52 41"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M14.5164 11.337C12.8931 7.50885 15.7029 3.26547 19.8611 3.26547H41.8851C44.5849 3.26547 46.9284 5.1267 47.5397 7.75642V7.75642C48.3858 11.3965 45.6222 14.8761 41.8851 14.8761H19.8611C17.5306 14.8761 15.4261 13.4825 14.5164 11.337V11.337Z"
			fill="#BACAFF"
		/>
		<path
			d="M0.933655 10C0.933655 4.47715 5.41081 0 10.9337 0H18.5392C21.171 0 23.4202 1.8955 23.8662 4.48921V4.48921C24.3122 7.08293 26.5615 8.97842 29.1933 8.97842H41.7346C47.2575 8.97842 51.7346 13.4556 51.7346 18.9784V31C51.7346 36.5228 47.2575 41 41.7346 41H10.9337C5.41082 41 0.933655 36.5228 0.933655 31V10Z"
			fill="#E0E7FF"
		/>
	</svg>
);
