export type CanvasSetupData = {
	introFontColor?: string;
	outroFontColor?: string;
	font?: string;
	bookends_logo?: string;

	introSlide?: string;
	outroSlide?: string;
	mainVideoBgSlide?: string;

	introVideoClip?: string;
	outroVideoClip?: string;
	introDuration?: number;
	outroDuration?: number;
	titleStartTime?: number;
	titleEndTime?: number;
	maxCharsIntro?: number;

	standardizeDims?: boolean;
	hideLogoIntro?: boolean;
	hideLogoOutro?: boolean;
	introTextAlign?: "center" | "left" | "right";
};

type CanvasSetupDictionary = Record<string, CanvasSetupData>;

const orgSetupCanvas: CanvasSetupDictionary = {
	//Tennr
	"2a0d9fa6-23d5-4e0a-8177-50f584f2b6ca": {
		font: "League Spartan Light",
	},

	//Volopay
	"93eda5fb-6faa-4df2-aa91-e258c7a8b0cf": {
		font: "Manrope",
	},

	//Swipey
	"ef2eb276-4312-4e36-bcfd-ef7e8db2777a": {
		maxCharsIntro: 50,
	},
};

export const getCanvasSetupProperty = (
	orgId: string,
	property: keyof CanvasSetupData,
) => {
	const orgSetupData = orgSetupCanvas[orgId];
	if (orgSetupData) {
		const orgSetupValue = orgSetupData[property];

		if (orgSetupValue) {
			return orgSetupValue;
		} else {
			return null;
		}
	}
};

export default orgSetupCanvas;
