const circledPauseIcon = (color = "#ffffff") => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="1000%`"
			viewBox="0 0 40 40"
			fill="none"
		>
			<path
				d="M20 36.6667C29.2047 36.6667 36.6666 29.2048 36.6666 20C36.6666 10.7953 29.2047 3.33337 20 3.33337C10.7952 3.33337 3.33331 10.7953 3.33331 20C3.33331 29.2048 10.7952 36.6667 20 36.6667Z"
				stroke={color}
				stroke-width="3"
			/>
			<path
				d="M15 16.25C15 15.5511 15 15.2016 15.0761 14.926C15.1776 14.5584 15.3723 14.2664 15.6173 14.1142C15.8011 14 16.0341 14 16.5 14C16.9659 14 17.1989 14 17.3827 14.1142C17.6277 14.2664 17.8224 14.5584 17.9239 14.926C18 15.2016 18 15.5511 18 16.25V23.75C18 24.4489 18 24.7983 17.9239 25.074C17.8224 25.4415 17.6277 25.7336 17.3827 25.8858C17.1989 26 16.9659 26 16.5 26C16.0341 26 15.8011 26 15.6173 25.8858C15.3723 25.7336 15.1776 25.4415 15.0761 25.074C15 24.7983 15 24.4489 15 23.75V16.25Z"
				fill={color}
				stroke={color}
				stroke-width="3"
			/>
			<path
				d="M23 16.25C23 15.5511 23 15.2016 23.0761 14.926C23.1776 14.5584 23.3723 14.2664 23.6173 14.1142C23.8011 14 24.0341 14 24.5 14C24.9659 14 25.1989 14 25.3827 14.1142C25.6277 14.2664 25.8224 14.5584 25.9239 14.926C26 15.2016 26 15.5511 26 16.25V23.75C26 24.4489 26 24.7983 25.9239 25.074C25.8224 25.4415 25.6277 25.7336 25.3827 25.8858C25.1989 26 24.9659 26 24.5 26C24.0341 26 23.8011 26 23.6173 25.8858C23.3723 25.7336 23.1776 25.4415 23.0761 25.074C23 24.7983 23 24.4489 23 23.75V16.25Z"
				fill={color}
				stroke={color}
				stroke-width="3"
			/>
		</svg>
	);
};
export default circledPauseIcon;
