import {
	useActive,
	useChainedCommands,
	useCommands,
	useRemirrorContext,
} from "@remirror/react";
import React, { useEffect, useRef, useState, MutableRefObject } from "react";
import Icon from "../../../../../../ui/Icon/Icon";
// import { orderedList } from '../../../../../../assets/svgs/text-editor-toolbar/ol';
import leftAlign from "../../../../../../assets/svgs/text-editor-toolbar/left-align";
import centerAlign from "../../../../../../assets/svgs/text-editor-toolbar/center-align";
import rightAlign from "../../../../../../assets/svgs/text-editor-toolbar/right-align";
import ToolbarButton from "../ToolbarButton/ToolbarButton";
import downArrowheadIcon from "../../../../../../assets/svgs/downArrowheadIcon";

type ListOptionsProps = {
	disabled?: boolean;
};

const AlignmentDropdown: React.FC<ListOptionsProps> = (props) => {
	const active = useActive();
	const commands = useChainedCommands() as any;

	const alignmentTypes = [
		{
			active: () => active.paragraph({ nodeTextAlignment: "left" }),
			handler: () => {
				commands.setTextAlignment("left").focus().run();
			},
			hoverTip: {
				text: "Left Align",
			},
			source: leftAlign("white"),
		},
		{
			active: () => active.paragraph({ nodeTextAlignment: "center" }),
			handler: () => {
				commands.setTextAlignment("center").focus().run();
			},
			hoverTip: {
				text: "Center Align",
			},
			source: centerAlign("white"),
		},
		{
			active: () => active.paragraph({ nodeTextAlignment: "right" }),
			handler: () => {
				commands.setTextAlignment("right").focus().run();
			},
			hoverTip: {
				text: "Right Align",
			},
			source: rightAlign("white"),
		},
	];

	var activeTool = alignmentTypes[0];

	if (active.paragraph({ nodeTextAlignment: "center" })) {
		activeTool = alignmentTypes[1];
	} else if (active.paragraph({ nodeTextAlignment: "right" })) {
		activeTool = alignmentTypes[2];
	}

	return (
		<div className="AlignmentDropdown-container">
			<ToolbarButton
				active={() => false}
				disabled={props.disabled}
				handler={() => {}}
				hoverTip={"Align"}
				source={activeTool.source}
				dropdownMenu={alignmentTypes.map((x) => {
					return {
						active: x.active,
						handler: x.handler,
						hoverTip: x.hoverTip.text,
						source: x.source,
					};
				})}
			/>
		</div>
	);
};
export default AlignmentDropdown;
