import React from "react";
import "./Ring.css";

type RingProps = {
	height: number;
	width?: number;
	color: "white" | "brandColor";
};

const Ring: React.FC<RingProps> = (props) => {
	return (
		<div
			className={`gigauser-lds-ring-loader ${props.color}`}
			style={{
				height: `${props.height}px`,
				width: `${props.height || props.width}px`,
			}}
		>
			<div>.</div>
			<div>.</div>
			<div>.</div>
			<div>.</div>
		</div>
	);
};
export default Ring;
