const volmin = (color?: string) => {
	const fill = color || "#000000";

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M11.533 5.09901C11.7028 5.18056 11.846 5.30839 11.9464 5.46779C12.0467 5.62719 12.0999 5.81167 12.1 6.00001V18.6C12.0999 18.7884 12.0466 18.9728 11.9462 19.1322C11.8459 19.2916 11.7025 19.4194 11.5327 19.5009C11.3629 19.5824 11.1736 19.6143 10.9864 19.5929C10.7993 19.5715 10.622 19.4977 10.475 19.38L6.25 16H3C2.73478 16 2.48043 15.8947 2.29289 15.7071C2.10536 15.5196 2 15.2652 2 15V9.60001C2 9.33479 2.10536 9.08044 2.29289 8.8929C2.48043 8.70536 2.73478 8.60001 3 8.60001H6.25L10.475 5.22001C10.622 5.10222 10.7994 5.02837 10.9866 5.00697C11.1737 4.98556 11.3632 5.01746 11.533 5.09901ZM10.1 8.08001L7.225 10.38C7.04759 10.5219 6.82716 10.5991 6.6 10.599H4V14H6.6C6.82728 14.0002 7.04772 14.0778 7.225 14.22L10.1 16.52V8.08001ZM21.707 9.29301C21.8945 9.48054 21.9998 9.73484 21.9998 10C21.9998 10.2652 21.8945 10.5195 21.707 10.707L16.564 15.85C16.3754 16.0322 16.1228 16.133 15.8606 16.1307C15.5984 16.1284 15.3476 16.0232 15.1622 15.8378C14.9768 15.6524 14.8716 15.4016 14.8693 15.1394C14.867 14.8772 14.9678 14.6246 15.15 14.436L20.293 9.29301C20.4805 9.10554 20.7348 9.00022 21 9.00022C21.2652 9.00022 21.5195 9.10554 21.707 9.29301Z"
				fill={fill}
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M15.15 9.29303C15.3376 9.10556 15.5919 9.00024 15.857 9.00024C16.1222 9.00024 16.3765 9.10556 16.564 9.29303L21.707 14.436C21.8892 14.6246 21.99 14.8772 21.9877 15.1394C21.9854 15.4016 21.8803 15.6524 21.6948 15.8378C21.5094 16.0233 21.2586 16.1284 20.9964 16.1307C20.7342 16.133 20.4816 16.0322 20.293 15.85L15.15 10.707C14.9626 10.5195 14.8572 10.2652 14.8572 10C14.8572 9.73487 14.9626 9.48056 15.15 9.29303Z"
				fill={fill}
			/>
		</svg>
	);
};

export default volmin;
