const useScrollTop: () => () => void = () => {
	return () => {
		const viewerContainer = document.getElementById("HelpCenterViewer");
		if (viewerContainer) {
			viewerContainer.scrollTop = 0;
		}
	};
};

export default useScrollTop;
