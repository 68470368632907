const voiceIcon = (color = "#000000") => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="0 0 15 15"
			fill="none"
		>
			<g clip-path="url(#clip0_789_5111)">
				<path
					d="M10.029 3.07138C10.4519 3.59427 10.683 4.24625 10.6837 4.91877C10.6844 5.59129 10.4548 6.24376 10.0329 6.76756L10.7637 7.35408C11.3195 6.66379 11.6222 5.80395 11.6212 4.9177C11.6203 4.03144 11.3158 3.17225 10.7585 2.48315L10.029 3.07138Z"
					fill={color}
				/>
				<path
					d="M12.6606 0.949219L11.9285 1.53955C12.7014 2.4953 13.1237 3.68694 13.125 4.91611C13.1263 6.14528 12.7066 7.33783 11.9357 8.29523L12.669 8.8841C13.5724 7.75866 14.0641 6.35832 14.0625 4.91517C14.061 3.47202 13.5663 2.07273 12.6606 0.949219Z"
					fill={color}
				/>
				<path
					d="M10.1836 10.068L8.05014 8.68131L8.85449 7.20668C9.04198 6.86231 9.14035 6.47651 9.14063 6.0844V3.75C9.14063 2.87976 8.79493 2.04516 8.17957 1.42981C7.56422 0.814452 6.72962 0.46875 5.85938 0.46875C4.98914 0.46875 4.15454 0.814452 3.53918 1.42981C2.92383 2.04516 2.57813 2.87976 2.57813 3.75V6.0844C2.57842 6.47652 2.6768 6.86233 2.8643 7.20671L3.66865 8.68131L1.53516 10.068C1.20727 10.2803 0.937849 10.5714 0.751548 10.9147C0.565248 11.258 0.468017 11.6426 0.468754 12.0332V14.5312H11.25V12.0332C11.2507 11.6426 11.1535 11.258 10.9672 10.9147C10.7809 10.5714 10.5115 10.2803 10.1836 10.068ZM10.3125 13.5938H1.40625V12.0332C1.40582 11.7988 1.46416 11.5681 1.57594 11.3621C1.68773 11.1561 1.84939 10.9814 2.04613 10.8541L4.90729 8.99432L3.68734 6.75779C3.57484 6.55116 3.51581 6.31967 3.51563 6.0844V3.75C3.51563 3.1284 3.76256 2.53226 4.2021 2.09272C4.64164 1.65318 5.23778 1.40625 5.85938 1.40625C6.48098 1.40625 7.07712 1.65318 7.51666 2.09272C7.9562 2.53226 8.20313 3.1284 8.20313 3.75V6.0844C8.20295 6.31967 8.14392 6.55115 8.03142 6.75776L6.81153 8.99435L9.67272 10.8541C9.86944 10.9815 10.0311 11.1561 10.1428 11.3621C10.2546 11.5681 10.3129 11.7988 10.3125 12.0332V13.5938Z"
					fill={color}
				/>
			</g>
			<defs>
				<clipPath id="clip0_789_5111">
					<rect width="15" height="15" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
export default voiceIcon;
