import { captureEvent } from "@gigauser/common/src/core/analytics/analytics";
import { useContext, useRef } from "react";
import ExtHelpCenterContext from "../templates/v2/ExtHelpCenterContext";
import { GuidePreview } from "@gigauser/common/src/core/types/guide";

type debouncedSearchProps = {
	searchString: string;
	setLoading: (b: boolean) => void;
	setResults: (res: GuidePreview[]) => void;
};

const useRunDebouncedSearch: () => (
	props: debouncedSearchProps,
) => Promise<void> = () => {
	const helpCenterContext = useContext(ExtHelpCenterContext);
	const debounceSearchTimer = useRef<any>(null);
	const searchEventTimer = useRef<any>(null);
	const runSearch = async (props: debouncedSearchProps) => {
		if (!props.searchString) {
			props.setLoading(false);
			props.setResults([]);
			return;
		}

		props.setLoading(true);

		const res = await helpCenterContext.search(props.searchString);

		if (res.length == 0) {
			captureEvent({
				eventName: "SearchReturnsNoResults",
				value: { searchString: props.searchString },
			});
			props.setResults([]);
		} else {
			props.setResults(res);
		}

		props.setLoading(false);
	};

	return async (props: debouncedSearchProps) => {
		if (debounceSearchTimer.current !== undefined) {
			clearTimeout(debounceSearchTimer.current);
		}
		if (searchEventTimer.current !== undefined) {
			clearTimeout(searchEventTimer.current);
		}
		if (!props.searchString) {
			//empty search string clear up here
			props.setLoading(false);
			props.setResults([]);
		} else {
			searchEventTimer.current = setTimeout(() => {
				captureEvent({
					eventName: "SearchRunInHelpCenter",
					value: { searchString: props.searchString },
				});
			}, 2000);
			debounceSearchTimer.current = setTimeout(() => {
				runSearch(props);
			}, 300);
		}
	};
};

export default useRunDebouncedSearch;
