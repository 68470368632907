const penIcon = (color = "currentColor") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M18.433 10.0332L19.967 11.5672L4.867 26.6672H3.332V25.1332L18.433 10.0332ZM24.433 0.000172622C24.017 0.000172622 23.583 0.167172 23.267 0.483172L20.217 3.53317L26.467 9.78317L29.517 6.73317C30.167 6.08317 30.167 5.03317 29.517 4.38317L25.617 0.483172C25.462 0.327214 25.2772 0.20407 25.0737 0.121086C24.8701 0.0381023 24.6528 -0.00302001 24.433 0.000172622ZM18.433 5.31717L0 23.7502V30.0002H6.25L24.682 11.5672L18.433 5.31717Z"
				fill={color}
			/>
		</svg>
	);
};
export default penIcon;
