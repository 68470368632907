const f = (color = "#000000") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 180 180"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="mask0_1073_2"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="180"
				height="180"
			>
				<circle cx="90" cy="90" r="90" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_1073_2)">
				<path d="M240 0H-60V180H240V0Z" fill="black" />
				<path d="M240 60H-60V180H240V60Z" fill="#DD0000" />
				<path d="M240 120H-60V180H240V120Z" fill="#FFCE00" />
			</g>
		</svg>
	);
};
export default f;
