const circledPlayIcon = (color = "#ffffff") => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="0 0 40 40"
			fill="none"
		>
			<path
				d="M20 36.6667C29.2048 36.6667 36.6667 29.2048 36.6667 20C36.6667 10.7953 29.2048 3.33337 20 3.33337C10.7953 3.33337 3.33337 10.7953 3.33337 20C3.33337 29.2048 10.7953 36.6667 20 36.6667Z"
				stroke={color}
				stroke-width="3"
			/>
			<path
				d="M26.5 18.9999C27.8028 19.7693 27.8028 20.2306 26.5 20.9999L16.5 26.4999C15.2337 27.2476 15 27.0394 15 25.4999V14.4999C15 12.9605 15.7337 12.7523 17 13.4999L26.5 18.9999Z"
				fill={color}
				stroke={color}
				stroke-width="3"
			/>
		</svg>
	);
};
export default circledPlayIcon;
