const ulLight = (color = "#000000") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 259 231"
			version="1.1"
			fillRule="evenodd"
			clipRule={"evenodd"}
			strokeMiterlimit={10}
		>
			<g transform="matrix(1,0,0,1,-7652,-3261)">
				<g id="Page-9" transform="matrix(1,0,0,1,6750,2250)">
					<rect x="0" y="0" width="2000" height="2000" fill="none" />
					<g id="Layer-2">
						<g transform="matrix(1,0,0,1,639.84,1288.7)">
							<path
								d="M0,0L192.119,0"
								fill="none"
								fillRule="nonzero"
								stroke={color} //rgb(49,57,65)
								strokeWidth={"24px"}
							/>
						</g>
						<g>
							<g transform="matrix(1,0,0,1,964.106,1032.54)">
								<path
									d="M0,0L181.441,0"
									fill="none"
									fillRule="nonzero"
									stroke={color} //rgb(49,57,65)
									strokeWidth={"24px"}
								/>
							</g>
							<g transform="matrix(1,0,0,1,963.88,1119.99)">
								<path
									d="M0,0L181.441,0"
									fill="none"
									fillRule="nonzero"
									stroke={color} //rgb(49,57,65)
									strokeWidth={"24px"}
								/>
							</g>
							<g transform="matrix(1,0,0,1,964.633,1212.72)">
								<path
									d="M0,0L181.441,0"
									fill="none"
									fillRule="nonzero"
									stroke={color} //rgb(49,57,65)
									strokeWidth={"24px"}
								/>
							</g>
							<g transform="matrix(1,0,0,1,0,-49.6513)">
								<g transform="matrix(0,-1,-1,0,929.383,1067.08)">
									<path
										d="M-15.32,-15.32C-23.781,-15.32 -30.639,-8.462 -30.639,-0.001C-30.639,8.459 -23.781,15.32 -15.32,15.32C-6.859,15.32 -0.001,8.459 -0.001,-0.001C-0.001,-8.462 -6.859,-15.32 -15.32,-15.32"
										fill={color}
										fillRule="nonzero"
										stroke={color} //rgb(49,57,65)
									/>
								</g>
								<g transform="matrix(0,-1,-1,0,929.383,1067.08)">
									<ellipse
										cx="-15.32"
										cy="0"
										rx="15.319"
										ry="15.32"
										fill="none"
										stroke={color}
										strokeWidth={"1px"}
									/>
								</g>
							</g>
							<g transform="matrix(1,0,0,1,0,-23.6152)">
								<g transform="matrix(0,-1,-1,0,929.383,1128.36)">
									<path
										d="M-15.32,-15.32C-23.781,-15.32 -30.639,-8.462 -30.639,-0.001C-30.639,8.459 -23.781,15.32 -15.32,15.32C-6.86,15.32 -0.001,8.459 -0.001,-0.001C-0.001,-8.462 -6.86,-15.32 -15.32,-15.32"
										fill={color}
										fillRule="nonzero"
									/>
								</g>
								<g transform="matrix(0,-1,-1,0,929.383,1128.36)">
									<ellipse
										cx="-15.32"
										cy="0"
										rx="15.319"
										ry="15.32"
										fill={"none"}
										stroke={"rgb(49,57,65)"}
										strokeWidth={"1px"}
									/>
								</g>
							</g>
							<g transform="matrix(1,0,0,1,0,1.96174)">
								<g transform="matrix(0,-1,-1,0,929.383,1195.77)">
									<path
										d="M-15.32,-15.32C-23.781,-15.32 -30.639,-8.462 -30.639,-0.001C-30.639,8.459 -23.781,15.32 -15.32,15.32C-6.86,15.32 -0.001,8.459 -0.001,-0.001C-0.001,-8.462 -6.86,-15.32 -15.32,-15.32"
										fill={color}
										fillRule="nonzero"
									/>
								</g>
								<g transform="matrix(0,-1,-1,0,929.383,1195.77)">
									<ellipse
										cx="-15.32"
										cy="0"
										rx="15.319"
										ry="15.32"
										fill="none"
										stroke={color}
										strokeWidth={"1px"}
									/>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default ulLight;
