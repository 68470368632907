export const graduationHatIcon = (color?: string) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="0 0 16 17"
			fill="none"
		>
			<path
				opacity="0.2"
				d="M13.5 7.39258V10.7188C13.5003 10.841 13.4558 10.9591 13.375 11.0507C12.6688 11.8376 10.9438 13.3257 8 13.3257C5.05625 13.3257 3.3325 11.8376 2.625 11.0507C2.54418 10.9591 2.49972 10.841 2.5 10.7188V7.39258L8 10.3257L13.5 7.39258Z"
				fill={color ?? "currentColor"}
			/>
			<path
				d="M15.7348 5.88435L8.23484 1.88435C8.1625 1.84583 8.0818 1.82568 7.99984 1.82568C7.91789 1.82568 7.83718 1.84583 7.76484 1.88435L0.264844 5.88435C0.184846 5.92698 0.117944 5.99056 0.0712937 6.06829C0.0246433 6.14601 0 6.23495 0 6.3256C0 6.41625 0.0246433 6.50519 0.0712937 6.58292C0.117944 6.66064 0.184846 6.72422 0.264844 6.76685L1.99984 7.69248V10.7187C1.99932 10.9643 2.08971 11.2014 2.25359 11.3844C3.07234 12.2962 4.90672 13.8256 7.99984 13.8256C9.02544 13.8341 10.0434 13.6483 10.9998 13.2781V15.3256C10.9998 15.4582 11.0525 15.5854 11.1463 15.6792C11.2401 15.7729 11.3672 15.8256 11.4998 15.8256C11.6325 15.8256 11.7596 15.7729 11.8534 15.6792C11.9472 15.5854 11.9998 15.4582 11.9998 15.3256V12.795C12.6518 12.4186 13.241 11.9426 13.7461 11.3844C13.91 11.2014 14.0004 10.9643 13.9998 10.7187V7.69248L15.7348 6.76685C15.8148 6.72422 15.8817 6.66064 15.9284 6.58292C15.975 6.50519 15.9997 6.41625 15.9997 6.3256C15.9997 6.23495 15.975 6.14601 15.9284 6.06829C15.8817 5.99056 15.8148 5.92698 15.7348 5.88435ZM7.99984 12.8256C5.29547 12.8256 3.70484 11.5044 2.99984 10.7187V8.2256L7.76484 10.7669C7.83718 10.8054 7.91789 10.8255 7.99984 10.8255C8.0818 10.8255 8.1625 10.8054 8.23484 10.7669L10.9998 9.29248V12.1887C10.2123 12.5562 9.21984 12.8256 7.99984 12.8256ZM12.9998 10.7162C12.7001 11.0488 12.3648 11.3475 11.9998 11.6069V8.75873L12.9998 8.2256V10.7162ZM11.7498 7.75935L11.7361 7.75123L8.23609 5.88435C8.11932 5.82471 7.98379 5.81332 7.8587 5.85264C7.73361 5.89197 7.62898 5.97886 7.56734 6.09459C7.50571 6.21033 7.492 6.34564 7.52917 6.47139C7.56635 6.59713 7.65143 6.70324 7.76609 6.76685L10.6873 8.3256L7.99984 9.75873L1.56234 6.3256L7.99984 2.89248L14.4373 6.3256L11.7498 7.75935Z"
				fill={color ?? "currentColor"}
			/>
		</svg>
	);
};
