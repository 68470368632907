const f = (color = "#000000") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 64 64"
			xmlns="http://www.w3.org/2000/svg"
			aria-hidden="true"
			role="img"
			preserveAspectRatio="xMidYMid meet"
		>
			<circle cx="32" cy="32" r="30" fill="#f42f4c"></circle>
			<path
				fill="#ffe62e"
				d="M32 39l9.9 7l-3.7-11.4l9.8-7.4H35.8L32 16l-3.7 11.2H16l9.8 7.4L22.1 46z"
			></path>
		</svg>
	);
};

export default f;
