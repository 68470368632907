import {
	useActive,
	useChainedCommands,
	useCommands,
	useRemirrorContext,
} from "@remirror/react";
import React, { useEffect, useRef, useState, MutableRefObject } from "react";
// import "..BlockTypeDropdown/BlockTypeDropdown.css";

type CodeTypeDropdownProps = {
	disabled?: boolean;
};

const CodeTypeDropdown: React.FC<CodeTypeDropdownProps> = (props) => {
	const active = useActive();
	const languages = [
		"kotlin",
		"dart",
		"swift",
		"js",
		"ts",
		"html",
		"xml",
		"css",
		"json",
	];
	const activeLanguage = () => {
		let activeHeading = "code";
		languages.forEach((level) => {
			if (active.codeBlock({ language: level })) {
				activeHeading = level;
			}
		});
		return activeHeading;
	};
	const commands = useChainedCommands() as any;
	const handler = (language: string) => {
		commands.toggleCodeBlock({ language: language }).focus().run();
	};

	return (
		<div className="gigauser-texteditor-toolbar-blocktype-dropdown">
			<div
				className={`gigauser-texteditor-toolbar-blocktype-dropdown-heading ${props.disabled ? "textstyle-disabled" : ""}`}
			>
				{activeLanguage()}
			</div>

			<div
				className="gigauser-texteditor-toolbar-blocktype-dropdown-content"
				style={{
					display: props.disabled ? "none" : undefined,
				}}
			>
				{languages.map((language) => (
					<div
						className={`gigauser-texteditor-toolbar-blocktype-dropdown-content-item`}
						onMouseDown={(e) => {
							e.preventDefault();
						}}
						onClick={(e) => {
							e.preventDefault();
							handler(language);
						}}
					>
						{language}
					</div>
				))}
			</div>
		</div>
	);
};
export default CodeTypeDropdown;
