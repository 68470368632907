const f = (color = "#000000") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 800 800"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M135 135V400H775C775 192.5 607.5 25 400 25C296.25 25 202.5 67.5 135 135Z"
				fill="#103B9B"
			/>
			<path
				d="M135 400V665C202.5 732.5 296.25 775 400 775C607.5 775 775 607.5 775 400H135Z"
				fill="#ED1F34"
			/>
			<path
				d="M135 135C67.5 202.5 25 296.25 25 400C25 503.75 67.5 597.5 135 665L400 400L135 135Z"
				fill="white"
			/>
			<path
				d="M173.022 486.042C220.454 486.042 259.043 447.453 259.043 400.021C259.043 352.589 220.454 314 173.022 314C125.59 314 87 352.589 87 400.021C87 447.454 125.59 486.042 173.022 486.042ZM173.022 339.806C206.225 339.806 233.237 366.818 233.237 400.021C233.237 433.223 206.224 460.236 173.022 460.236C139.82 460.236 112.807 433.223 112.807 400.021C112.807 366.818 139.819 339.806 173.022 339.806Z"
				fill="#FFE000"
			/>
			<path
				d="M173.022 440.451C195.351 440.451 213.452 422.35 213.452 400.021C213.452 377.692 195.351 359.591 173.022 359.591C150.693 359.591 132.592 377.692 132.592 400.021C132.592 422.35 150.693 440.451 173.022 440.451Z"
				fill="#FFE000"
			/>
		</svg>
	);
};
export default f;
