const f = (color = "#000000") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 800 800"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M763.75 312.5C725 147.5 576.25 25 400 25H387.5V312.5H763.75Z"
				fill="#F9F9F9"
			/>
			<path
				d="M212.5 75C125 126.25 58.75 211.25 35 312.5H212.5V75Z"
				fill="#F9F9F9"
			/>
			<path
				d="M35 487.5C58.75 588.75 125 673.75 212.5 725V487.5H35Z"
				fill="#F9F9F9"
			/>
			<path
				d="M387.5 775H400C577.5 775 725 652.5 765 487.5H387.5V775Z"
				fill="#F9F9F9"
			/>
			<path
				d="M763.75 312.5H387.5V25C323.75 27.5 263.75 45 212.5 75V312.5H35C28.75 340 25 370 25 400C25 430 28.75 460 35 487.5H212.5V725C263.75 755 323.75 772.5 387.5 775V487.5H765C771.25 460 775 430 775 400C775 370 771.25 340 763.75 312.5Z"
				fill="#428BC1"
			/>
		</svg>
	);
};
export default f;
