import {
	useActive,
	useChainedCommands,
	useCommands,
	useRemirrorContext,
} from "@remirror/react";
import React, { useEffect, useRef, useState, MutableRefObject } from "react";
import Icon from "../../../../../../ui/Icon/Icon";
// import { orderedList } from '../../../../../../assets/svgs/text-editor-toolbar/ol';
import ol from "../../../../../../assets/svgs/text-editor-toolbar/ol_light";
import ul from "../../../../../../assets/svgs/text-editor-toolbar/ul_light";
import ToolbarButton from "../ToolbarButton/ToolbarButton";
type ListOptionsProps = {
	disabled?: boolean;
};

const ListOptions: React.FC<ListOptionsProps> = (props) => {
	const active = useActive();
	const commands = useChainedCommands() as any;
	const handler = (level: string | number) => {
		if (level !== "default") {
			commands.toggleHeading({ level }).focus().run();
		} else {
			let activeHeading: string | number = "default";
			[1, 2, 3, 4, 5].forEach((level) => {
				if (active.heading({ level: level })) {
					activeHeading = level;
				}
			});
			if (activeHeading === "default") return;
			commands.toggleHeading({ level: activeHeading }).focus().run();
		}
	};

	const listTypes = [
		{
			active: () => false,
			handler: () => {
				commands.toggleOrderedList().focus().run();
			},
			hoverTip: {
				text: "Numbered List",
			},
			source: ol("white"),
		},
		{
			label: "bulletList",
			active: () => false,
			handler: () => {
				commands.toggleBulletList().focus().run();
			},
			hoverTip: {
				text: "Bullet List",
			},
			source: ul("white"),
		},
	];
	// taskList: {
	//     label: "taskList",
	//     active: () => false,
	//     handler: () => {
	//         commands.toggleTaskList().focus().run();
	//     },
	//     hoverTip: {
	//         text: "Task List",
	//     },
	// }

	return (
		<div style={{ display: "flex" }}>
			{listTypes.map((x) => (
				<ToolbarButton
					disabled={props.disabled}
					active={x.active}
					handler={x.handler}
					hoverTip={x.hoverTip.text}
					source={x.source}
				/>
			))}
		</div>
	);
};
export default ListOptions;
