import React, { useState } from "react";
import TitledInput from "../../../../../../ui/inputs/TitledInput/TitledInput";
import Button from "../../../../../../ui/buttons/Button/Button";
import useWindowSize from "../../../../../../hooks/useWindowSize";
import { useAppDispatch } from "../../../../../../redux";
import { closeOverlay } from "../../../../../../layouts/Overlay";
import "./Link.css";
import Cutton from "../../../../../../ui/buttons/Cutton/Cutton";
type LinkPopupProps = {
	text: string;
	currentLink: string;
	save: (newText: string, newLink: string) => void;
	delete: () => void;
};

function LinkPopup(props: LinkPopupProps) {
	const [text, setText] = useState(props.text);
	const [link, setLink] = useState(props.currentLink);
	const { device } = useWindowSize();
	const dispatch = useAppDispatch();

	return (
		<div className="create-new-collection">
			<div className={`input-field ${device}`}>
				<TitledInput
					id=""
					label="Text"
					value={text}
					onChange={(e) => setText((e.target as any).value)}
					placeHolder="Text"
					// position="left half"
					styleSize="xl"
					display={true}
					background="white"
					edges="sharp"
					autoFocus
				></TitledInput>
			</div>

			<div className={`input-field ${device}`}>
				<TitledInput
					id=""
					label="Link"
					value={link}
					onChange={(e) => setLink((e.target as any).value)}
					placeHolder="Link"
					// position="left half"
					styleSize="xl"
					display={true}
					background="white"
					edges="sharp"
					autoFocus
				></TitledInput>
			</div>
			<div className={`motivation ${device}`}>
				Enter either a URL or a guide ID.
			</div>

			<div className={`create-linkpopup-buttons ${device}`}>
				<Cutton
					onClick={() => {
						props.save(text, link);
						dispatch(closeOverlay());
					}}
				>
					Save
				</Cutton>

				<Cutton
					rank="secondary"
					onClick={() => {
						props.delete();
						dispatch(closeOverlay());
					}}
				>
					Delete
				</Cutton>
			</div>
		</div>
	);
}

export default LinkPopup;
