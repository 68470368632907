const inIcon = (color = "#000000") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 800 800"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M768.75 335C738.75 158.75 585 25 400 25C215 25 61.25 158.75 31.25 335L400 150L768.75 335Z"
				fill="#009B3A"
			/>
			<path
				d="M31.25 465C61.25 641.25 215 775 400 775C585 775 738.75 641.25 768.75 465L400 650L31.25 465Z"
				fill="#009B3A"
			/>
			<path
				d="M400 150L31.25 335C27.5 356.25 25 377.5 25 400C25 422.5 27.5 443.75 31.25 465L400 650L768.75 465C772.5 443.75 775 422.5 775 400C775 377.5 772.5 356.25 768.75 335L400 150Z"
				fill="#FFE62E"
			/>
			<path
				d="M325 355C285 355 247.5 363.75 213.75 378.75C212.5 386.25 212.5 392.5 212.5 400C212.5 503.75 296.25 587.5 400 587.5C470 587.5 531.25 548.75 563.75 492.5C517.5 411.25 427.5 355 325 355Z"
				fill="#002776"
			/>
			<path
				d="M585 430C586.25 420 587.5 410 587.5 400C587.5 296.25 503.75 212.5 400 212.5C326.25 212.5 262.5 255 231.25 317.5C261.25 308.75 292.5 303.75 325 303.75C431.25 303.75 525 353.75 585 430Z"
				fill="#002776"
			/>
			<path
				d="M325 303.75C292.5 303.75 261.25 308.75 231.25 317.5C222.5 336.25 216.25 356.25 213.75 378.75C247.5 363.75 285 355 325 355C427.5 355 517.5 410 563.75 491.25C575 472.5 581.25 451.25 585 430C525 353.75 431.25 303.75 325 303.75Z"
				fill="white"
			/>
			<path
				d="M275 412.5C281.904 412.5 287.5 406.904 287.5 400C287.5 393.096 281.904 387.5 275 387.5C268.096 387.5 262.5 393.096 262.5 400C262.5 406.904 268.096 412.5 275 412.5Z"
				fill="white"
			/>
			<path
				d="M325 487.5C331.904 487.5 337.5 481.904 337.5 475C337.5 468.096 331.904 462.5 325 462.5C318.096 462.5 312.5 468.096 312.5 475C312.5 481.904 318.096 487.5 325 487.5Z"
				fill="white"
			/>
			<path
				d="M400 487.5C406.904 487.5 412.5 481.904 412.5 475C412.5 468.096 406.904 462.5 400 462.5C393.096 462.5 387.5 468.096 387.5 475C387.5 481.904 393.096 487.5 400 487.5Z"
				fill="white"
			/>
			<path
				d="M400 537.5C406.904 537.5 412.5 531.904 412.5 525C412.5 518.096 406.904 512.5 400 512.5C393.096 512.5 387.5 518.096 387.5 525C387.5 531.904 393.096 537.5 400 537.5Z"
				fill="white"
			/>
			<path
				d="M500 487.5C506.904 487.5 512.5 481.904 512.5 475C512.5 468.096 506.904 462.5 500 462.5C493.096 462.5 487.5 468.096 487.5 475C487.5 481.904 493.096 487.5 500 487.5Z"
				fill="white"
			/>
			<path
				d="M500 537.5C506.904 537.5 512.5 531.904 512.5 525C512.5 518.096 506.904 512.5 500 512.5C493.096 512.5 487.5 518.096 487.5 525C487.5 531.904 493.096 537.5 500 537.5Z"
				fill="white"
			/>
			<path
				d="M450 512.5C456.904 512.5 462.5 506.904 462.5 500C462.5 493.096 456.904 487.5 450 487.5C443.096 487.5 437.5 493.096 437.5 500C437.5 506.904 443.096 512.5 450 512.5Z"
				fill="white"
			/>
			<path
				d="M275 462.5C281.904 462.5 287.5 456.904 287.5 450C287.5 443.096 281.904 437.5 275 437.5C268.096 437.5 262.5 443.096 262.5 450C262.5 456.904 268.096 462.5 275 462.5Z"
				fill="white"
			/>
		</svg>
	);
};
export default inIcon;
