const playIcon = (color: string = "#000000") => {
	return (
		// <svg width="100%" height="100%" viewBox="0 0 225 225" fill="none" xmlns="http://www.w3.org/2000/svg">
		// <path d="M167.5 107.67C170.833 109.594 170.833 114.406 167.5 116.33L89.5 161.363C86.1667 163.288 82 160.882 82 157.033V66.9667C82 63.1177 86.1667 60.712 89.5 62.6366L167.5 107.67Z" fill={color}/>
		// <circle cx="112.5" cy="112.5" r="105.5" stroke={color} strokeWidth="14"/>
		// </svg>

		//good color: #5d647b

		<svg
			width="79%"
			height="100%"
			viewBox="0 0 22 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M20.7817 16.3607L12.723 21.5621C12.6595 21.5621 12.6595 21.6333 12.5961 21.6333L4.53735 26.8347C4.15662 27.1197 3.64898 27.3334 3.14135 27.3334C1.80881 27.3334 0.666626 26.1221 0.666626 24.5546V14.0094V3.39294C0.666626 2.89418 0.793535 2.46667 0.983898 2.03917C1.6819 0.75664 3.14135 0.257881 4.34698 1.04165L12.5326 6.31425L20.6548 11.5869C21.0355 11.8006 21.3528 12.1569 21.6066 12.5844C22.368 13.9382 21.9873 15.6482 20.7817 16.3607Z"
				fill={color}
			></path>
		</svg>
	);
};

export default playIcon;
