export default (
	<svg
		width="100%"
		height="83%"
		viewBox="0 0 234 195"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect y="53" width="200" height="46" rx="5" fill="#CED3E0" />
		<path
			d="M190 53H229C231.761 53 234 55.2386 234 58V94C234 96.7614 231.761 99 229 99H190V53Z"
			fill="#756F6F"
		/>
		<circle cx="100" cy="81" r="51" fill="#D4F2F6" />
		<rect
			x="142.087"
			y="115"
			width="24.5832"
			height="17.0933"
			transform="rotate(45 142.087 115)"
			fill="#1785F8"
		/>
		<path
			d="M159.021 132L203.946 176.925C207.266 180.245 207.266 185.627 203.946 188.946V188.946C200.627 192.266 195.245 192.266 191.925 188.946L147 144.021L159.021 132Z"
			fill="#CED3E0"
		/>
		<g filter="url(#filter0_d_249_186)">
			<circle cx="175.5" cy="14.5" r="14.5" fill="#64B9FC" />
		</g>
		<g filter="url(#filter1_d_249_186)">
			<circle cx="53.5" cy="158.5" r="14.5" fill="#A8E7EF" />
		</g>
		<circle cx="73" cy="71" r="5" fill="#2F9FF9" />
		<circle cx="111" cy="71" r="5" fill="#2F9FF9" />
		<path
			d="M84 94V94C89.08 89.757 96.2061 88.9661 102.093 91.9918L106 94"
			stroke="#31A7FB"
			strokeWidth="8"
			strokeLinecap="round"
		/>
		<path
			d="M131.5 80C130.231 52.1919 98 34 98 34C98 34 128.5 31 131.5 36.5C134.5 42 152 74.5 152 74.5C143.696 124.39 116.807 135.721 93.6705 126.69C92.9755 126.604 92.4103 126.377 92 126C92.5544 126.242 93.1114 126.472 93.6705 126.69C102.559 127.787 132.677 105.791 131.5 80Z"
			fill="#A8E7EF"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M100 142C133.689 142 161 114.689 161 81C161 47.3106 133.689 20 100 20C66.3106 20 39 47.3106 39 81C39 114.689 66.3106 142 100 142ZM99.5 127C125.181 127 146 106.181 146 80.5C146 54.8188 125.181 34 99.5 34C73.8188 34 53 54.8188 53 80.5C53 106.181 73.8188 127 99.5 127Z"
			fill="#31A7FB"
		/>
		<g filter="url(#filter2_d_249_186)">
			<circle cx="53.5" cy="29.5" r="14.5" fill="#E778B1" />
		</g>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M212.941 83.1176C217.944 83.1176 222 79.0619 222 74.0588C222 69.0558 217.944 65 212.941 65C207.938 65 203.882 69.0558 203.882 74.0588C203.882 79.0619 207.938 83.1176 212.941 83.1176ZM212.941 77.2941C214.728 77.2941 216.176 75.8457 216.176 74.0588C216.176 72.272 214.728 70.8236 212.941 70.8236C211.154 70.8236 209.706 72.272 209.706 74.0588C209.706 75.8457 211.154 77.2941 212.941 77.2941Z"
			fill="#CED3E0"
		/>
		<rect
			x="200"
			y="83.1311"
			width="11"
			height="5.82353"
			rx="2.91176"
			transform="rotate(-45 200 83.1311)"
			fill="#CED3E0"
		/>
		<defs>
			<filter
				id="filter0_d_249_186"
				x="157"
				y="0"
				width="37"
				height="37"
				filterUnits="userSpaceOnUse"
				color-interpolation-filters="sRGB"
			>
				<feFlood flood-opacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="4" />
				<feGaussianBlur stdDeviation="2" />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_249_186"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_249_186"
					result="shape"
				/>
			</filter>
			<filter
				id="filter1_d_249_186"
				x="35"
				y="144"
				width="37"
				height="37"
				filterUnits="userSpaceOnUse"
				color-interpolation-filters="sRGB"
			>
				<feFlood flood-opacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="4" />
				<feGaussianBlur stdDeviation="2" />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_249_186"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_249_186"
					result="shape"
				/>
			</filter>
			<filter
				id="filter2_d_249_186"
				x="35"
				y="15"
				width="37"
				height="37"
				filterUnits="userSpaceOnUse"
				color-interpolation-filters="sRGB"
			>
				<feFlood flood-opacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="4" />
				<feGaussianBlur stdDeviation="2" />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_249_186"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_249_186"
					result="shape"
				/>
			</filter>
		</defs>
	</svg>
);
