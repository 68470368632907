import folder_color from "@gigauser/common/src/assets/svgs/folder_color";
import {
	Collection,
	CollectionChildren,
} from "@gigauser/common/src/core/types/collections";
import { Guide, GuidePreview } from "@gigauser/common/src/core/types/guide";
import React, { useContext, useEffect, useRef, useState } from "react";
import "./CollectionViewer.css";

import ExtHelpCenterContext from "../ExtHelpCenterContext";
import useOpenGuide from "../../../hooks/useOpenGuide";
import useOpenCollection from "../../../hooks/useOpenCollection";
import { useAppSelector } from "../../../redux/useAppRedux";
import { rootCollection } from "@gigauser/common/src/types/files";
import { getBrandingColor } from "@gigauser/common/src/core/utils/styleUtils";
import documentIcon from "@gigauser/common/src/assets/svgs/documentIcon";
import Icon from "@gigauser/common/src/ui/Icon/Icon";
import { Viewer } from "@gigauser/common/src/components/Guide/Viewer/Viewer";

import useOpenGuideFromId from "../../../hooks/useOpenGuideFromId";
import orgSetupData, {
	getPageTitle,
} from "@gigauser/common/src/setup/orgSetupData";
import { captureEvent } from "@gigauser/common/src/core/analytics/analytics";
import { useEffectOnce } from "@gigauser/common/src/hooks/useEffectOnce";
import useScrollTop from "../../../hooks/useScrollTop";
import { helpSaver as saver } from "@gigauser/common/src/network/helpcenterSaver";
import { HelpCenterConfig } from "@giga-user-fern/api/types/api";

type CollectionViewerProps = {
	helpCenterConfig?: HelpCenterConfig;
};
const CollectionViewer = (props: CollectionViewerProps) => {
	const [loading, setLoading] = useState(true);
	const [guides, setGuides] = useState<GuidePreview[]>([]);
	const [collections, setCollections] = useState<Collection[]>([]);
	const collectionRef = useRef<Collection | null>(null);
	const collectionPath = useAppSelector((state) => state.hc.collectionPath);
	let collection = rootCollection;
	if (collectionPath) {
		collection = collectionPath[collectionPath.length - 1];
	}
	const [pinnedGuide, setPinnedGuide] = useState<Guide | null>(null);
	const appTheme = useAppSelector((state) => state.hcUi.theme);

	const helpCenterContext = useContext(ExtHelpCenterContext);
	// const openGuide = useOpenGuide()
	const onOpenGuideFromId = useOpenGuideFromId();

	const scrollTop = useScrollTop();

	var isPrivate = collection.private;
	collectionPath?.forEach((collection) => {
		if (collection.private) isPrivate = true;
	});

	const initHomeContent = async () => {
		setLoading(true);
		var parentId = collection ? collection.id : undefined;

		const res = await helpCenterContext.fetchAllChildren(parentId);

		const { guidePreviews } = res;

		const collections = res.collections.filter(
			(collection) => !collection.private,
		);

		const pinnedGuides = guidePreviews.filter(
			(guidePreview) => guidePreview.isPinned,
		);

		const org = helpCenterContext.getOrganization();

		if (pinnedGuides.length) {
			const pinnedGuide = pinnedGuides[0];
			if (org)
				document.title = `${pinnedGuide.header.name} | ${getPageTitle(org, props.helpCenterConfig)}`;

			const data = await helpCenterContext.fetchGuideData(pinnedGuide.id);
			captureEvent({
				eventName: "GuideOpenedInHelpCenter",
				value: { guide_id: pinnedGuide.id },
			});
			setPinnedGuide({
				id: pinnedGuide.id,
				guidePreview: pinnedGuide,
				guideData: data!,
			});

			// if(viewerComponent) viewerComponent.scrollTop = 0
			scrollTop();
		} else {
			setPinnedGuide(null);
			if (org)
				document.title = `${collection.name} | ${getPageTitle(org, props.helpCenterConfig)}`;
		}

		setGuides(guidePreviews);
		setCollections(collections);

		setLoading(false);
	};

	useEffect(() => {
		if (collection === collectionRef.current) {
			return;
		}
		collectionRef.current = collection;
		initHomeContent();
	}, [collection]);

	const org = helpCenterContext.getOrganization();

	const collectionName =
		collection?.name === rootCollection.name
			? `${getPageTitle(org, props.helpCenterConfig)}`
			: collection.name;

	const collectionDescription =
		collection?.description === rootCollection.description
			? props.helpCenterConfig?.homePageName || `Home`
			: collection.description;

	var component = null;

	var descriptionColorFlip = false;
	var descriptionSize = "normal";
	const orgId = org?.id.toString();
	if (orgId && orgSetupData[orgId]) {
		descriptionColorFlip =
			orgSetupData[orgId].descriptionColorInvert ?? false;
		descriptionSize = orgSetupData[orgId].descriptionSize ?? "normal";
	}

	if (loading) {
		component = (
			<div className="gigauser-collection-pinnedguide-container">
				<Viewer
					guide={undefined}
					// guide={undefined}
					onClose={() => {}}
					overrideDevice={"laptop"}
					size="l"
					appTheme={appTheme}
					contentOnly
					onOpenGuideFromId={onOpenGuideFromId}
					getOrganization={helpCenterContext.getOrganization}
					environment="ext-hc"
					// navigator={() => {}}
				/>
			</div>
		);
	} else if (isPrivate) {
		component = (
			<div className="gigauser-private-collection-preview">
				This collection is private.
			</div>
		);
	} else if (pinnedGuide) {
		component = (
			<div className="gigauser-collection-pinnedguide-container">
				<Viewer
					guide={pinnedGuide}
					// guide={undefined}
					onClose={() => {}}
					overrideDevice={"laptop"}
					size="l"
					appTheme={appTheme}
					contentOnly
					onOpenGuideFromId={onOpenGuideFromId}
					getOrganization={helpCenterContext.getOrganization}
					environment="ext-hc"
					// navigator={() => {}}
				/>
			</div>
		);
	} else {
		component = (
			<div className="gigauser-collectionpreview-container">
				{guides.length ? (
					<div className="collectionpreview-section">
						<div className="guides-title">Projects</div>
						<div className="browser-guides-container">
							{guides.map((guidePreview) => (
								<GuideBox
									guidePreview={guidePreview}
								></GuideBox>
							))}
						</div>
					</div>
				) : null}

				{collections.length ? (
					<div className="collectionpreview-section">
						<div className="guides-title">Collections</div>
						<div className="browser-guides-container">
							{collections.map((collection) => (
								<CollectionBox
									collection={collection}
									helpCenterConfig={props.helpCenterConfig}
								></CollectionBox>
							))}
						</div>
					</div>
				) : null}
			</div>
		);
	}

	const parts = collectionDescription?.split(/(#[\w]+)/);

	return (
		<div className="CollectionViewer">
			<div className="CollectionViewer-header">
				<div className="CollectionViewer-title">{collectionName}</div>

				<div
					className={`CollectionViewer-description ${descriptionColorFlip ? "color-flip" : ""}`}
				>
					{parts?.map((part, index) =>
						part.startsWith("#") ? (
							<span
								key={index}
								className={`CollectionViewer-description-tag ${descriptionColorFlip ? "color-flip" : ""}`}
							>
								{part}
							</span>
						) : (
							<span
								key={index}
								className={`CollectionViewer-description-non-tag ${descriptionColorFlip ? "color-flip" : ""}`}
							>
								{part}
							</span>
						),
					)}
				</div>

				<div className="CollectionViewer-subtitle">
					{guides.length
						? `${guides.length} ${props.helpCenterConfig?.guidesKeyword || "guides"} `
						: ""}
					{guides.length && collections.length ? <>&middot;</> : ""}
					{collections.length
						? ` ${collections.length}  ${props.helpCenterConfig?.subcollectionsKeyword}`
						: ""}
				</div>
			</div>

			{component}
		</div>
	);
};

type GuideBoxProps = {
	guidePreview: GuidePreview;
};

const GuideBox: React.FC<GuideBoxProps> = ({ guidePreview, ...props }) => {
	const onOpenGuide = useOpenGuide();
	const collectionPath = useAppSelector((state) => state.hc.collectionPath);
	const appTheme = useAppSelector((state) => state.hcUi.theme);
	const onClick = () => {
		if (collectionPath) onOpenGuide(guidePreview, collectionPath);
	};

	return (
		<div className="Browser-GuideBox-v2 BrowserBox" onClick={onClick}>
			<Icon className="Browser-GuideBox-icon">
				{documentIcon(getBrandingColor(appTheme))}
			</Icon>

			<div className="Broswer-GuideBox-v2-text">
				<div className="Browser-GuideBox-v2-title browserItem-title">
					{guidePreview.header.name}
				</div>
				<div className="Browser-GuideBox-v2-description browserItem-description">
					{guidePreview.header.description}
				</div>
			</div>
		</div>
	);
};

type CollectionBoxProps = {
	collection: Collection;
	helpCenterConfig?: HelpCenterConfig;
};

const CollectionBox: React.FC<CollectionBoxProps> = ({
	collection,
	...props
}) => {
	const onOpenCollection = useOpenCollection();
	const collectionPath = useAppSelector((state) => state.hc.collectionPath);

	const onClick = () => {
		if (collectionPath) onOpenCollection(collection, collectionPath);
	};

	const description = collection.description || "Click to learn more";
	const appTheme = useAppSelector((state) => state.hcUi.theme);

	if (saver.organization?.id && orgSetupData[saver.organization?.id]) {
		var exploreTitle = props.helpCenterConfig?.openDocKeyword;
	}

	return (
		<div className="Browser-CollectionBox-v2 BrowserBox" onClick={onClick}>
			<div className="Browser-CollectionBox-v2-folder">
				{folder_color}
			</div>
			<div className="Browser-CollectionBox-v2-title browserItem-title">
				<div className="Browser-CollectionBox-v2-title-text">
					{collection.name}
				</div>
			</div>
			<div className="Browser-CollectionBox-v2-description browserItem-description">
				{description}
			</div>

			<div className="Browser-CollectionBox-v2-explore">
				{exploreTitle || "Explore"} &rarr;{" "}
			</div>
		</div>
	);
};

export default CollectionViewer;
