import {
	Guide,
	GuidePreview,
} from "@giga-user-fern/api/types/api/resources/guides";
import Video from "@gigauser/common/src/components/formats/Video/Video";
import SimpleVideo from "@gigauser/common/src/components/formats/SimpleVideo/SimpleVideo";

import React, { useContext, useEffect, useState } from "react";
import { useAppSelector } from "../../../redux";
import ExtHelpCenterContext from "../ExtHelpCenterContext";
import { getPathFromRoute } from "@gigauser/common/src/help-center/utils/routeUtils";
import { GigaUserApi } from "@giga-user-fern/api";
import { GuideData } from "@gigauser/common/src/core/types/guide";
import GridLoader from "@gigauser/common/src/ui/loaders/GridLoader/GridLoader";
import useWindowSize from "@gigauser/common/src/hooks/useWindowSize";

type EmbedViewerProps = {
	// guide: Guide
};

const EmbedViewer: React.FC<EmbedViewerProps> = (props) => {
	const { device } = useWindowSize();

	const [guide, setGuide] = useState<Guide | null>(null);

	const helpCenterContext = useContext(ExtHelpCenterContext);

	var videoSrc = guide?.guideData?.video.publishedVideoSrc || "";

	if (guide?.guideData?.settings.publishedVideo !== "ai") {
		videoSrc = guide?.guideData?.video.originalSrc || "";
	}

	const initPage = async () => {
		const urlSegments = window.location.pathname
			.split("/")
			.filter((x) => x !== "");
		const guideId = urlSegments.at(-1);

		if (guideId) {
			const guidePreview = (await helpCenterContext.fetchGuidePreview(
				GigaUserApi.Id(guideId),
			)) as GuidePreview;
			const guideData = await helpCenterContext.fetchGuideData(
				guidePreview.id,
			);

			if (guidePreview && guideData) {
				setGuide({
					id: GigaUserApi.Id(guideId),
					guidePreview: guidePreview,
					guideData: guideData,
				});
			}
		}
	};

	useEffect(() => {
		initPage();
	}, []);

	if (!guide || !guide.guideData) {
		return <GridLoader center />;
	} else
		return (
			<div className="EmbedViewer">
				{/* <Video embed guidePreview={guide.guidePreview} video={guide.guideData.video} videoSrc = {videoSrc} overrideDevice={device} /> */}
				<SimpleVideo
					guidePreview={guide.guidePreview}
					video={guide.guideData.video}
					videoSrc={videoSrc}
					overrideDevice={device}
				/>
			</div>
		);
};
export default EmbedViewer;
