import React, { useRef } from "react";
import { Shape, UpdateShapeFunction } from "../../ScreenshotEditorContext";
import { Coordinates } from "@giga-user-fern/api/types/api";

type DragControllerProps = {
	shape: Shape;
	getRelativeCoords: (pos: Coordinates) => Coordinates;

	zoomFactor?: number;
	updateShape: UpdateShapeFunction;

	boundLimits?: boolean;
	className?: string;

	toggleGuideLines?: (newValue: {
		vertical: boolean;
		horizontal: boolean;
	}) => void;
};

const DragController: React.FC<DragControllerProps> = ({
	zoomFactor = 1,
	toggleGuideLines = null,
	...props
}) => {
	const startX = useRef(0);
	const startY = useRef(0);

	const isDraggable = React.useRef(false);

	const onMouseDown = (e: React.MouseEvent) => {
		startX.current = e.clientX;
		startY.current = e.clientY;
		isDraggable.current = false;
		initDragListeners();
	};

	const initDragListeners = () => {
		window.addEventListener("mousemove", onMouseMove);
		window.addEventListener("mouseup", onMouseUp);
	};

	const onMouseMove = (e: MouseEvent) => {
		if (!isDraggable) return;

		const x_0 = props.shape.position[0];
		const y_0 = props.shape.position[1];

		const dx = (e.clientX - startX.current) / zoomFactor;
		const dy = (e.clientY - startY.current) / zoomFactor;

		const { x, y } = props.getRelativeCoords({ x: dx, y: dy });
		var new_x = x_0 + x;
		var new_y = y_0 + y;

		if (props.boundLimits) {
			new_x = Math.max(new_x, 0);
			new_x = Math.min(new_x, 1 - props.shape.size[0]);

			new_y = Math.max(new_y, 0);
			new_y = Math.min(new_y, 1 - props.shape.size[1]);
		}

		let pos_x = new_x;
		let pos_y = new_y;

		if (props.shape.geo === "text") {
			const distanceToCenter = {
				x: 0.5 - props.shape.size[0] / 2 - new_x,
				y: 0.5 - props.shape.size[1] / 2 - new_y,
			};

			if (Math.abs(distanceToCenter.x) < 0.015) {
				pos_x = 0.5 - props.shape.size[0] / 2;
			}

			if (Math.abs(distanceToCenter.y) < 0.015) {
				pos_y = 0.5 - props.shape.size[1] / 2;
			}
		}

		props.updateShape(props.shape.id, { position: [pos_x, pos_y] });
	};

	const onMouseUp = (e: MouseEvent) => {
		isDraggable.current = false;
		if (toggleGuideLines) {
			toggleGuideLines({ vertical: false, horizontal: false });
		}
		cleanupDragListeners();
	};

	const cleanupDragListeners = () => {
		window.removeEventListener("mousemove", onMouseMove);
		window.removeEventListener("mouseup", onMouseUp);
	};

	return (
		<div
			className={`DragController ${props.className}`}
			onMouseDown={onMouseDown}
		></div>
	);
};

export default DragController;
