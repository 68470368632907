import { boolean } from "@giga-user-fern/api/types/core/schemas";
import React, { MutableRefObject, useRef } from "react";
import { initVideoEdits } from "./videoEditTypes/core";
import { Organization, VideoClip } from "../types/guide";
import { VideoEdits } from "@giga-user-fern/api/types/api/resources/video";
import {
	OriginalEdits,
	VideoSource,
} from "@giga-user-fern/api/types/api/resources/guides";
import { FrontendVideoSource } from "./CanvasProvider";
import { GigaUserApi } from "@giga-user-fern/api";

export type CustomizableFunction = "play" | "pause";

export type HardsetCurrentTimeOptions = {
	waitTillSeek?: boolean;
	skipSettingActiveClipIndex?: boolean;
};

type CanvasPlayerContextValue = {
	//edits
	videoEdits: VideoEdits;
	videoSrc: string;
	vidRef: React.MutableRefObject<HTMLVideoElement | null> | null;
	canvasRef: React.MutableRefObject<HTMLCanvasElement | null> | null;
	organization: Organization | null;

	introVidRef: React.MutableRefObject<HTMLVideoElement | null> | null;
	outroVidRef: React.MutableRefObject<HTMLVideoElement | null> | null;

	introImgRef: React.MutableRefObject<HTMLImageElement> | null;
	outroImgRef: React.MutableRefObject<HTMLImageElement> | null;
	introLogoRef: React.MutableRefObject<HTMLImageElement> | null;
	outroLogoRef: React.MutableRefObject<HTMLImageElement> | null;

	clips?: VideoClip[];
	sources?: FrontendVideoSource[];

	//state variables
	loading: boolean;
	currentTime: number; //Does not include video time.
	currentTimeRef: React.MutableRefObject<number> | null;
	activeClipIndex: number;
	activeClipIndexRef: React.MutableRefObject<number> | null;
	videoDuration: number;
	paused: boolean;
	pausedRef: MutableRefObject<boolean> | null;
	fullscreen: boolean;
	_hardUpdateTimelinePending: boolean;
	_timelineInitPending: boolean;

	//video controller functions
	hardsetCurrentTime: (
		time: number,
		hard?: boolean,
		options?: HardsetCurrentTimeOptions,
	) => void;
	play: () => void;
	pause: () => void;
	toggleFullscreen: () => void;
	_setHardUpdateTimelinePending: (b: boolean) => void;
	_setTimelineInitPending: (b: boolean) => void;
	setPlaybackRate: (rate: number) => void;
	setClips: (clips: VideoClip[]) => void;
	setSources: (sources: VideoSource[]) => void;
	addNewSource: (id: string, presignedGet: string) => FrontendVideoSource;
	setActiveClipIndex: (index: number) => void;

	//timings
	getScreenclipStartTime: () => number;
	getScreenclipEndTime: () => number;
	getVideoDuration: () => number;
	getAdjustedTime: (t: number) => number;
	getUnadjustedTime: (t: number) => number;
	timelineToVideoTime: (time: number) => {
		time: number;
		sourceId?: GigaUserApi.Id;
	};
	videoToTimelineTime: (
		t_v: number,
		srcId?: GigaUserApi.Id,
		overwriteClips?: VideoClip[],
	) => number;
	//customizers
	// appendCallback: (func: CustomizableFunction, callbackFunction: () => void) => void
};

const CanvasPlayerContext = React.createContext<CanvasPlayerContextValue>({
	//edits
	videoEdits: initVideoEdits,
	videoSrc: "",
	vidRef: null,
	canvasRef: null,
	organization: null,

	introVidRef: null,
	outroVidRef: null,
	introImgRef: null,
	outroImgRef: null,
	introLogoRef: null,
	outroLogoRef: null,

	//state variables
	loading: true,
	currentTime: 0, //Does not include video time.
	currentTimeRef: null,
	activeClipIndexRef: null,
	activeClipIndex: 0,
	videoDuration: 0,
	paused: true,
	pausedRef: null,
	fullscreen: false,
	_hardUpdateTimelinePending: false,
	_timelineInitPending: false,

	//video controller functions
	hardsetCurrentTime: (
		time: number,
		hard?: boolean,
		options?: HardsetCurrentTimeOptions,
	) => {},
	play: () => {},
	pause: () => {},
	toggleFullscreen: () => {},
	_setHardUpdateTimelinePending: (b: boolean) => {},
	_setTimelineInitPending: (b: boolean) => {},
	setPlaybackRate: (r: number) => {},
	setClips: (clips: VideoClip[]) => {},
	setSources: (sources: VideoSource[]) => {},
	addNewSource: (id: string, presignedGet: string) => {
		return {
			id: GigaUserApi.Id(`d`),
			loaded: false,
			ref: React.createRef<HTMLVideoElement | null>(),
			src: "",
		};
	},
	setActiveClipIndex: (index: number) => {},

	//timings
	getScreenclipStartTime: () => 0,
	getScreenclipEndTime: () => 0,
	getVideoDuration: () => 0,
	getAdjustedTime: (t: number) => 0,
	getUnadjustedTime: (t: number) => 0,
	timelineToVideoTime: (time: number) => {
		return { time: 0, sourceId: GigaUserApi.Id("dummy") };
	},
	videoToTimelineTime: (t_v: number, srcId?: GigaUserApi.Id) => 0,

	// appendCallback: (func, callbackFunction) => {}
});

export default CanvasPlayerContext;
