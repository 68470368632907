import { ElementEdit } from "@giga-user-fern/api/types/api/resources/video";
import { interpolate } from "../../video_effects/interpolations";
import { hexToRGBA } from "../../../../utils/colorUtils";
import { getFont } from "../../../../utils/fontsUtils";

//Animation
const INTERPOLATION_METHOD = "easeOutCubic";
const ANIMATION_TIME = 0.3;

//Formatting
const PADDING_W = 30;
const PADDING_H = 80;

export const drawFilledRoundedRect = (
	ctx: CanvasRenderingContext2D,
	x: number,
	y: number,
	width: number,
	height: number,
	radius: number,
	color: string,
) => {
	const XR = x + radius;
	const YR = y + radius;

	const XW = x + width;
	const YH = y + height;

	const XWR = XW - radius;
	const YHR = YH - radius;

	ctx.beginPath();
	ctx.moveTo(XR, y);

	ctx.lineTo(XWR, y);
	ctx.quadraticCurveTo(XW, y, XW, YR);

	ctx.lineTo(XW, YHR);
	ctx.quadraticCurveTo(XW, YH, XWR, YH);

	ctx.lineTo(XR, YH);
	ctx.quadraticCurveTo(x, YH, x, YHR);

	ctx.lineTo(x, YR);
	ctx.quadraticCurveTo(x, y, XR, y);

	ctx.fillStyle = color;
	ctx.fill();
};

export const canvasPrintText: (
	ctx: CanvasRenderingContext2D,
	position: {
		x: number;
		y: number;
		w: number;
		h: number;
		scalingFactor: number;
	},
	element: ElementEdit,
	currTime: number,
	animate: boolean,
) => void = (ctx, pos, ele, t, animate) => {
	var opacity = 1;
	var fillStyle: string;

	const { x, y, w, h } = pos;
	const { textdata } = ele;
	if (!textdata) return;

	if (animate) {
		//compute the opacity
		if (t > ele.startTime && t <= ele.startTime + ANIMATION_TIME) {
			const start = { y: 0, x: ele.startTime };
			const end = { y: 1, x: ele.startTime + ANIMATION_TIME };

			opacity = interpolate(start, end, t, INTERPOLATION_METHOD);
		} else if (t > ele.endTime - ANIMATION_TIME) {
			const start = { y: 1, x: ele.endTime - ANIMATION_TIME };
			const end = { y: 0, x: ele.endTime };

			opacity = interpolate(start, end, t, INTERPOLATION_METHOD);
		}
	}

	if (textdata.backgroundColor) {
		fillStyle = hexToRGBA(
			textdata.backgroundColor,
			opacity * (textdata.backgroundOpacity || 100),
		);

		ctx.fillStyle = fillStyle;
		drawFilledRoundedRect(ctx, x, y, w, h, h * 0.1, fillStyle);
		ctx.fill();
	}

	const fontSize = textdata.fontSize;
	const fontFamily = textdata.font;
	const textAlign = textdata.alignment;
	const lines = textdata.lines || [""];
	const color = textdata.textColor || "#000000";

	const write = async () => {
		// Get the font name dynamically
		const fontName = await getFont(false, fontFamily);
		if (!fontName || typeof fontName == "boolean")
			throw new Error("Font not found");

		ctx.font = `${fontSize}px ${fontName}`;
		ctx.fillStyle = hexToRGBA(color, opacity * 100);
		ctx.textBaseline = "middle";
		// let fontSize = h * 0.6; // Start with 80% of rectangle height
		// let {fontSize} = ele.textdata
		// ctx.font = `${fontSize}px League Spartan Medium`;

		drawText(ctx, x, y, w, h, lines, fontSize, fontName, textAlign);
	};

	write();
};

export function computeTextboxHeight(
	canvas: HTMLCanvasElement,
	lines: string[],
	fontSize: number,
): number {
	const newHeight = lines.length * fontSize + fontSize * 0.5;
	const newHeight_f = newHeight / canvas.height;

	return newHeight_f;
}

// Adjust the wrapText function to account for the scaling factor
export function wrapText(
	ctx: CanvasRenderingContext2D,
	text: string,
	maxWidth: number,
	fontSize: number,
	fontFamily: string = "Open Sans",
): string[] {
	const write = async () => {
		//Get the font name dynamically
		const fontName = await getFont(false, fontFamily);
		if (!fontName) throw new Error("Font not found");

		ctx.font = `${fontSize}px ${"Inter"}`;
	};

	write();

	const scalingFactor = 1;
	const scaledMaxWidth = maxWidth * scalingFactor - PADDING_W * 2; // Apply scaling factor to maxWidth for accurate measurement

	const paragraphs = text.split("\n");
	let lines: string[] = [];

	paragraphs.forEach((paragraph) => {
		const words = paragraph.split(" ");
		let currentLine = words.length > 0 ? words[0] : "";

		for (let i = 1; i < words.length; i++) {
			const word = words[i];
			// Measure text width with scaling applied
			const width =
				ctx.measureText(currentLine + " " + word).width / scalingFactor;

			if (width < scaledMaxWidth) {
				currentLine += " " + word;
			} else {
				lines.push(currentLine);
				currentLine = word;
			}
		}
		if (currentLine) {
			lines.push(currentLine);
		}
	});

	return lines;
}

function drawText(
	ctx: CanvasRenderingContext2D,
	x: number,
	y: number,
	w: number,
	h: number,
	lines: string[],
	fontSize: number,
	fontFamily: string,
	textAlign: "left" | "center" | "right",
) {
	const lineHeight = fontSize; // Adjust line height as needed
	const textHeight = lines.length * lineHeight;

	// Adjust rectangle height and y-position for padding
	const paddedHeight = h - PADDING_H * 2;
	const paddedY = y + PADDING_H;

	// Calculate the vertical middle of the padded rectangle and adjust to start text drawing from that point
	const rectMiddleY = paddedY + paddedHeight / 2;
	const textMiddleY = textHeight / 2;
	const startY = rectMiddleY - textMiddleY + fontSize / 2 - lineHeight / 2;

	// Set the font style
	ctx.font = `${fontSize}px ${fontFamily}`;
	ctx.textAlign = textAlign;

	// Determine horizontal position based on text alignment
	let posX: number;
	switch (textAlign) {
		case "center":
			posX = x + w / 2; // Center of the rectangle for center alignment
			break;
		case "right":
			posX = x + w - PADDING_W; // Adjust for padding for right alignment
			break;
		case "left":
		default:
			posX = x + PADDING_W; // Adjust for padding for left alignment
			break;
	}

	// Draw each line of text according to the specified alignment
	lines.forEach((line, i) => {
		ctx.fillText(line, posX, startY + i * lineHeight + lineHeight / 2);
	});
}

export const computeFontSize: (lines: string[], height: number) => number = (
	lines,
	height,
) => {
	if (!lines.length) return height * 0.6;
	else return (height / lines.length) * 0.6;
};
